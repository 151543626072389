// @flow

// Vendors
import * as React from 'react';

// Components
import { Trans } from '../../../../components';

const OnboardingPhoneStepWrapper = ({ children, optin }: Props) => {
  return (
    <div className="onboarding-phone">
      <h2 className="h1 title">
        <Trans
          file="Onboarding"
          id="PhoneTitle"
          fallback="Verify your phone number"
        />
      </h2>
      <p className="lead">
        <Trans
          file="Onboarding"
          id="PhoneDescription"
          fallback="We’ll use this to confirm your identity during sign in and if you need to recover your password."
        />
      </p>
      {optin === 'true' && (
        <>
          <p className="lead">
            <Trans
              file="Onboarding"
              id="PhoneDescriptionOptin"
              fallback="We’ll use this to confirm your identity during sign in and if you need to recover your password."
            />
          </p>
        </>
      )}
      <div className="onboarding-body">{children}</div>
    </div>
  );
};

type Props = {
  children?: React.Node,
  optin: Boolean,
};

export default OnboardingPhoneStepWrapper;
