//@flow

import { REQUEST_TYPES } from '.';

export const submitTDLRLRRenewalRequest = (
  body: submitTDLRLRRenewalRequestType
): RequestOptions => ({
  type: `[API] Submit TDLRLR Renewal Request`,
  url: '/agency/app/transact',
  APIMethod: REQUEST_TYPES.POST,
  body,
});
