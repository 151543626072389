// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import classnames from 'classnames';

// Helpers
import { triggerBrowserReflow } from '../../../../lib/helpers/';

// Styles
import './collapse-transition.scss';

const CollapseTransition = ({ className, children, ...props }) => {
  function handleExit(elem) {
    elem.style.height = `${elem.offsetHeight}px`;
    triggerBrowserReflow(elem);
  }

  function handleExiting(elem) {
    elem.style.height = '0';
  }

  return (
    <Transition
      {...props}
      timeout={200}
      onExit={handleExit}
      onExiting={handleExiting}
      unmountOnExit
    >
      {(status, innerProps) =>
        React.cloneElement(children, {
          ...innerProps,
          className: classnames(
            className,
            children.props.className,
            `collapse-transition collapse-transition-${status}`
          ),
        })
      }
    </Transition>
  );
};
CollapseTransition.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element.isRequired,
};

export default CollapseTransition;
