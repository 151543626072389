// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

// Styles
import './drawer-transition.scss';

const DrawerTransition = ({ className, ...props }) => (
  <CSSTransition
    {...props}
    classNames={classnames('drawer-transition', className)}
    timeout={300}
    unmountOnExit
  />
);

DrawerTransition.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default DrawerTransition;
