// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
import {
  InputCountry,
  InputText,
  InputState,
  InputZipCode,
  InputPhone,
  InputNumeric,
} from '../..';
import Label from '../../Label/Label';
import Trans from '../../../Trans/Trans';

// Helpers
import states from '../../../../../lib/data/states';
import { countries } from '../../../../../lib/data';

// Styles
import './input-group-emergency-address.scss';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Address
 */
const InputGroupEmergencyAddress = ({
  fieldGroupPath = '',
  disabled,
  labelPosition,
  formikProps,
}) => {
  const filterOption = (candidate, input, options) => {
    const filterValues = options.filter(option => {
      if (option.label.toUpperCase().startsWith(input.toUpperCase()))
        return option.label;
    });
    const filterFinal = filterValues.filter(
      value => value.label === candidate.label
    );
    if (input && filterFinal.length > 0) return true;
    else if (!input) return true;
  };

  const statesWithOther = [...states, { label: 'Other', value: 'OTHER' }];
  return (
    <>
      <InputText
        name={`${fieldGroupPath}FirstName`}
        maxLength="40"
        label={<Trans file="Labels" id="FirstName" />}
        labelPosition="above"
        formikProps={formikProps}
      />
      <InputText
        name={`${fieldGroupPath}LastName`}
        maxLength="40"
        label={<Trans file="Labels" id="LastName" />}
        labelPosition="above"
        formikProps={formikProps}
      />
      <div className="input-phone-ext">
        <InputPhone
          name={`${fieldGroupPath}PrimaryPhone`}
          labelPosition="above"
          label={<Trans file="Labels" id="PrimaryPhone" />}
          formikProps={formikProps}
        />
        <Label
          className="input-phone-ext label"
          label={<Trans file="Labels" id="Ext" />}
        />
        <InputNumeric
          className="input-phone-ext ext"
          name={`${fieldGroupPath}Ext1`}
          formikProps={formikProps}
          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        />
      </div>
      <div className="input-phone-ext">
        <InputPhone
          name={`${fieldGroupPath}SecondaryPhone`}
          labelPosition="above"
          label={<Trans file="Labels" id="SecondaryPhoneOptional" />}
          formikProps={formikProps}
        />
        <Label
          className="input-phone-ext label"
          label={<Trans file="Labels" id="Ext" />}
        />
        <InputNumeric
          className="input-phone-ext ext"
          name={`${fieldGroupPath}Ext2`}
          formikProps={formikProps}
          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        />
      </div>
      <InputText
        className="input-street"
        name={`${fieldGroupPath}streetAddress`}
        label={<Trans file="Labels" id="StreetAddressOptional" />}
        labelPosition={labelPosition}
        maxLength="32"
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputText
        className="input-city"
        name={`${fieldGroupPath}city`}
        label={<Trans file="Labels" id="CityOptional" />}
        labelPosition={labelPosition}
        maxLength="33"
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputState
        name={`${fieldGroupPath}state`}
        label={<Trans file="Labels" id="StateOptional" />}
        labelPosition={labelPosition}
        options={
          formikProps.values[`${fieldGroupPath}country`].code !== 'USA'
            ? statesWithOther
            : states
        }
        disabled={disabled}
        formikProps={formikProps}
        filterOption={(...props) =>
          filterOption(
            ...props,
            formikProps.values[`${fieldGroupPath}country`].code !== 'USA'
              ? statesWithOther
              : states
          )
        }
      />
      <InputZipCode
        name={`${fieldGroupPath}zipFive`}
        className="input-zip"
        label={<Trans file="Labels" id="ZipOptional" />}
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputCountry
        name={`${fieldGroupPath}country`}
        className="input-country"
        label={<Trans file="Labels" id="Country" />}
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
        filterOption={(...props) => filterOption(...props, countries)}
      />
    </>
  );
};

InputGroupEmergencyAddress.propTypes = {
  className: PropTypes.string,
  labelPosition: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  fieldGroupPath: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
  hasLine2: PropTypes.bool,
};

export default InputGroupEmergencyAddress;
