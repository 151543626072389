// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles
import './hamburger.scss';

const Hamburger = ({ isActive, className, ...props }) => (
  <span
    {...props}
    className={classnames('hamburger', className, isActive ? 'isActive' : '')}
  />
);

Hamburger.propTypes = {
  isActive: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};

export default Hamburger;
