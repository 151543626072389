// Helpers
import { to } from '../../../lib/helpers';
import { gaActions } from '../../gaActionTypes';

// Requests
import { userUpdateProfileInfoRequest } from '../../../backend/requests';

// Consts
export const USER_UPDATE_PROFILE_INFO = `[USER] ${gaActions.success} Update User Profile Info`;

export const updateProfileInfo = data => async (dispatch, getState) => {
  const { user } = getState();
  const [{ response, body }] = await to(
    dispatch(
      userUpdateProfileInfoRequest({ body: { ...user.profile, ...data } })
    )
  );

  if (!body) return Promise.reject();
  if (response.status !== 200) return Promise.reject({ response, body });

  dispatch({ type: USER_UPDATE_PROFILE_INFO, payload: body.data });

  return Promise.resolve({ response, body });
};
