// Helpers
import { to } from '../../../lib/helpers';
import {
  checkRequestFailure,
  handleRequestFailure,
  ERRORS_MAP,
} from '../../../lib/validation';
import { gaActions } from '../../gaActionTypes';

// Requests
import { userUpdatePasswordRequest } from '../../../backend/requests';
import { getUser } from '../getUser/getUserActions';

// Consts
export const USER_UPDATE_PASSWORD_SUCCESS_RECEIVED = `[USER] ${gaActions.submit} Update Password Success`;

export const updatePassword = ({
  values: { userEmail, currentPassword, newPassword },
  props: { setToggle },
  formikActions,
}) => async dispatch => {
  const [{ response, body }] = await to(
    dispatch(
      userUpdatePasswordRequest({
        userEmail,
        password: currentPassword,
        newPass: newPassword,
      })
    )
  );

  if (checkRequestFailure({ response, body }))
    return handleRequestFailure(
      body,
      formikActions,
      ERRORS_MAP.UPDATE_PASSWORD
    );

  setToggle();
  dispatch(getUser());
  return dispatch({
    type: USER_UPDATE_PASSWORD_SUCCESS_RECEIVED,
    payload: body,
  });
};


export const setAgencyStrongPassword = ({
  values: { userEmail, currentPassword, newPassword },
  props: { handleSuccess },
  formikActions
}) => async dispatch => {
  const [{ response, body }] = await to(
    dispatch(userUpdatePasswordRequest({ userEmail, password: currentPassword, newPass: newPassword }))
  );

  if (checkRequestFailure({ response, body })) 
    return handleRequestFailure(body, formikActions, ERRORS_MAP.UPDATE_PASSWORD);
  dispatch({ type: USER_UPDATE_PASSWORD_SUCCESS_RECEIVED, payload: body });
  handleSuccess();
};