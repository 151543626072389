// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import {
  Form,
  InputGroupCode,
  FormActions,
  FormActionsItem,
  OTPSentContent,
  Trans,
} from '../../..';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../../reducers/modal/modalActions';
import { updateContactSubmitRequest } from '../../../../../reducers/user/updateContact/updateContactActions';

// Helpers
import { verifyOTPValidationSchema } from '../../../../../lib/validation';
import { EMAIL_CHANGE_STEPS } from '../VerifyEmailChangeModal';
import { signOutWithoutRedirect } from '../../../../../reducers/auth/authActions';

class VerifyEmailChangeModal extends Component<Props> {
  get initialValues() {
    const { OTP } = this.props;
    const { channelCode } = OTP.data || {};

    return {
      code: '',
      channelCode: channelCode || '',
    };
  }

  handleSubmit(values, formikActions) {
    const step = this.props.steps.find(step => step.id === this.props.stepId);
    const { setActiveStep } = step ? step.data : {};

    this.props.updateContactSubmitRequest({
      values,
      formikActions,
      props: {
        ...this.props,
        onSuccess: () => {
          this.props.signOutWithoutRedirect();
          setActiveStep(EMAIL_CHANGE_STEPS.SUCCESS);
        },
      },
    });
  }

  render() {
    const { OTP, closeModal, data } = this.props;

    if (!OTP.data || !OTP.data.channelCode || !OTP.data.contactInfo)
      return null;
    const { channelCode, contactInfo } = OTP.data;

    return (
      <>
        <h2 className="h1 modal-title">
          {channelCode === 'SMS' ? (
            <Trans
              file="MFA"
              id="VerifyNewPhone"
              fallback="Please verify your new phone."
            />
          ) : (
            <Trans
              file="MFA"
              id="VerifyNewEmail"
              fallback="Verify your new email"
            />
          )}
        </h2>
        <div className="modal-content">
          <OTPSentContent channelCode={channelCode} contactInfo={contactInfo} />
          <Form
            className="modal-form"
            formikConfig={{
              validateOnBlur: false,
              enableReinitialize: true,
              onSubmit: this.handleSubmit.bind(this),
              initialValues: this.initialValues,
              validationSchema: verifyOTPValidationSchema,
            }}
            render={({ FormError, formikProps }) => (
              <>
                <InputGroupCode formikProps={formikProps} />
                <FormError />
                <FormActions align="center">
                  <FormActionsItem>
                    <ButtonPrimary
                      type="submit"
                      isLoading={formikProps.isSubmitting}
                      loadingContent={
                        <Trans file="Labels" id="Saving" fallback="Saving" />
                      }
                    >
                      <Trans file="Labels" id="Submit" fallback="Submit" />
                    </ButtonPrimary>
                  </FormActionsItem>
                  <FormActionsItem>
                    <Button
                      onClick={() => closeModal(data.modalId)}
                      disabled={formikProps.isSubmitting}
                    >
                      <Trans file="Labels" id="Cancel" fallback="Cancel" />
                    </Button>
                  </FormActionsItem>
                </FormActions>
              </>
            )}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ OTP, user: { verifyContact } }) => ({
  OTP,
  verifyContact,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { closeModal, updateContactSubmitRequest, signOutWithoutRedirect },
    dispatch
  );

VerifyEmailChangeModal.propTypes = {
  channelCode: PropTypes.string.isRequired,
  contactInfo: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  OTP: PropTypes.object.isRequired,
  modalName: PropTypes.string.isRequired,
  updateContactSubmitRequest: PropTypes.func.isRequired,
  signOutWithoutRedirect: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  stepId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyEmailChangeModal);
