/* eslint-disable flowtype/no-types-missing-file-annotation */
// Vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';

// Actions
import {
  linkAppLink,
  linkServiceSuccess,
} from '../../../reducers/agency/linkService/linkServiceActions';

// Components
import { FormActions, FormActionsItem, Trans } from '../../../components';
import SummaryCard from 'texkit/dist/components/Cards/SummaryCard/SummaryCard';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';

// Helpers
import { scrollToTop } from '../../../lib/helpers';

const DMVLinkServiceConfirm = ({
  vin,
  vehicleMake,
  vehicleModelYear,
  registrationExpiry,
}) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);

  const handleConfirmClick = () => {
    setSubmitting(true);
    dispatch(linkAppLink())
      .then(() => dispatch(linkServiceSuccess()))
      .catch(() => {
        scrollToTop();
        setSubmitting(false);
      });
  };

  return (
    <>
      <h1 className="h2">
        <Trans file="dmv" id="dmvrr_LinkService_ConfirmTitleDefault" />
      </h1>
      <p>
        <Trans file="dmv" id="dmvrr_LinkService_ConfirmDescriptionDefault" />
      </p>

      {vin && (
        <>
          <SummaryCard>
            <SummaryCard.List
              items={[
                {
                  className: 'word-break',
                  label: <Trans file="Labels" id="vehicleMake" />,
                  value: vehicleMake.toUpperCase(),
                },
                {
                  label: <Trans file="Labels" id="modelYear" />,
                  value: vehicleModelYear,
                },
                {
                  label: <Trans file="Labels" id="vin" />,
                  value: vin,
                },
                {
                  label: <Trans file="Labels" id="registrationExpiry" />,
                  value: registrationExpiry,
                },
              ]}
            />
          </SummaryCard>
        </>
      )}

      <FormActions>
        <FormActionsItem>
          <ButtonPrimary
            onClick={handleConfirmClick}
            isLoading={submitting}
            loadingContent={
              <Trans file="Labels" id="Linking" fallback="Linking" />
            }
          >
            <Trans
              file="Labels"
              id="LinkToMyAccount"
              fallback="Link To My Account"
            />
          </ButtonPrimary>
        </FormActionsItem>
        <FormActionsItem>
          <Button onClick={() => dispatch(goBack())} disabled={submitting}>
            <Trans file="Labels" id="Back" fallback="Back" />
          </Button>
        </FormActionsItem>
      </FormActions>
    </>
  );
};

DMVLinkServiceConfirm.propTypes = {
  vin: PropTypes.string.isRequired,
  vehicleMake: PropTypes.string.isRequired,
  vehicleModelYear: PropTypes.string.isRequired,
  registrationExpiry: PropTypes.string.isRequired,
};

export default DMVLinkServiceConfirm;
