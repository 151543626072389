// Vendors
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
// Components
import { Modal } from '..';
import Trans from '../../Trans/Trans';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './cancelUpdates.scss';

const CancelUpdates = () => {
  const dispatch = useDispatch();
  return (
    <Modal name="CancelUpdates">
      <h2 className="h1 modal-dlr-title">
        <Trans file="dlr" id="dlr_Cancel_Updates" />
      </h2>
      <p className="modal-dlr-description">
        <Trans file="dlr" id="dlr_Cancel_Updates_Desc" />
      </p>
      <Button
        className="modal-button"
        onClick={() => {
          dispatch(closeModal('CancelUpdates'));
          dispatch(goBack());
        }}
      >
        <Trans file="Labels" id="yesCancel" />
      </Button>
      <span className="modal-button" />
      <ButtonPrimary
        className="modal-button"
        onClick={() => dispatch(closeModal('CancelUpdates'))}
      >
        <Trans file="Labels" id="keepUpdating" />
      </ButtonPrimary>
    </Modal>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators({ goBack }, dispatch);

export default connect(null, mapDispatchToProps)(CancelUpdates);
