// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import SITE_DATA from '../../../lib/data/siteData';

/**
 * @visibleName
 */
const Trans = ({ file, id, fallback, args }) => {
  // let fallbackText = 'missingLabel_' + file + '_' + id;
  let fallbackText = fallback || id;

  const { t } = useTranslation(file);

  let params = {};
  if (args && args.length) {
    for (let i = 0; i < args.length; i++) {
      params[i + 1] = args[i];
    }
  }
  let data = {
    SITE_DATA,
    currentYear: new Date().getFullYear(),
    ...params,
  };
  return <>{ReactHtmlParser(t(id, fallbackText, data))}</>;
};

Trans.propTypes = {
  file: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  fallback: PropTypes.string,
  args: PropTypes.array,
};

export default Trans;
