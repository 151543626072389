// Vendors
import React from 'react';

import { formikInjectedPropsTypes } from '../../../lib/validation/propTypes/formikPropTypes';

import { useSelector, useDispatch } from 'react-redux';

// Components
import { Trans, InputRadioGroup } from '../..';
import { LoadingDots } from '../../common';
import { PaymentCard, PaymentCardListAddButton } from '..';

// Selectors
import { selectPaymentAccounts } from '../../../reducers/payment/paymentSelectors';
import { selectPathname } from '../../../reducers/route/routeSelectors';

// Actions
import { push } from 'connected-react-router';
import { getPaymentRoute } from '../../../lib/helpers';

const PaymentSelectCardList = ({ formikProps }) => {
  const dispatch = useDispatch();
  const currentPath = useSelector(selectPathname);
  const paymentAccounts = useSelector(selectPaymentAccounts);
  const setFieldValue = formikProps.setFieldValue;

  if (!paymentAccounts) return <LoadingDots />;
  const inputOptions = paymentAccounts.map((paymentAccount, index) => {
    let isCardSelected =
      formikProps.values.selectPayment &&
      formikProps.values.selectPayment.paymentAccountId ===
        paymentAccount.paymentAccountId;
    return {
      className: 'payment-select-list-item',
      id: `selectPayment${index}`,
      value: paymentAccount,
      label: (
        <PaymentCard
          paymentAccount={paymentAccount}
          formikProps={formikProps}
          showCvv={currentPath.includes('dmvrr') && isCardSelected}
        />
      ),
    };
  });

  const handleUseDifferentCardClick = () =>
    dispatch(push(getPaymentRoute('/form', currentPath)));

  const onChangeCallback = () => {
    if (setFieldValue) {
      if (formikProps.values.cvv) setFieldValue('cvv', '');
    }
  };

  return (
    <div className="payment-select">
      <div className="payment-select-list">
        {inputOptions && inputOptions.length > 0 && (
          <InputRadioGroup
            name="selectPayment"
            optionValueKey="paymentAccountId"
            formikProps={formikProps}
            options={inputOptions}
            onChange={onChangeCallback}
          />
        )}
        <PaymentCardListAddButton
          onClick={handleUseDifferentCardClick}
          content={
            <Trans
              file="Payment"
              id="UseDifferentCard"
              fallback="Use a different card"
            />
          }
        />
      </div>
    </div>
  );
};

PaymentSelectCardList.propTypes = {
  formikProps: formikInjectedPropsTypes.isRequired,
};

export default PaymentSelectCardList;
