import React from 'react';
import { Trans } from '../../components';

export function translateStateAlert(alert) {
  if (alert.info) {
    // Destructure the parts we know we need
    const {
      type,
      info,
      info: { title, message },
    } = alert;

    // Provide translations by using the Content component
    const translated = {
      title: (
        <Trans
          file={title.file || title.type || 'Alerts' || type}
          id={title.id}
          fallback="Something went wrong. Please contact your administrator"
        />
      ),
      message: (
        <Trans
          file={message.file || title.type || 'Alerts' || type}
          id={message.id}
          fallback="Something went wrong. Please contact your administrator"
        />
      ),
    };

    // Return the new state with the translations
    return {
      ...alert,
      info: {
        ...info,
        ...translated,
      },
    };
  }

  // If no info, just return itself
  return alert;
}
