// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputWrapper, Trans } from '../../..';

// Styles
import './input-code.scss';

/**
 * @visibleName Code
 */
const InputCode = ({ className, ...props }) => (
  <InputWrapper
    {...props}
    mask={[...Array(6)].map(() => /\d/)}
    className={classnames('input-code', className)}
    type="tel"
    maxLength="6"
    autoComplete="off"
  />
);

InputCode.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  autoFocus: PropTypes.bool.isRequired,
};

InputCode.defaultProps = {
  label: <Trans file="Labels" id="EnterCode" fallback="Enter Code" />,
  name: 'code',
  autoFocus: true,
};

export default InputCode;
