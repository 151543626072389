// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Helpers
import { formatPhoneNumber } from '../../../lib/helpers';

// Components
import { Trans } from '../..';

const OTPSentContent = ({
  className,
  channelCode,
  contactInfo,
  checkSpam = true,
}) =>
  channelCode === 'SMS' ? (
    <p className={classnames(className)} id="digit">
      <Trans
        file="MFA"
        id="CodeSentTo"
        fallback="A six-digit code has been sent to "
      />
      <strong>{formatPhoneNumber(contactInfo)}</strong>.
    </p>
  ) : (
    <p className={classnames(className)}>
      <Trans
        file="MFA"
        id="CodeSentTo"
        fallback="A six-digit code has been sent to "
      />
      <strong className="nobr">{contactInfo}</strong>.
      {checkSpam && (
        <Trans
          file="MFA"
          id="CodeSentSpam"
          fallback="If you don't see it, please check your spam folder."
        />
      )}
    </p>
  );

OTPSentContent.propTypes = {
  className: PropTypes.string,
  channelCode: PropTypes.string.isRequired,
  contactInfo: PropTypes.string.isRequired,
  checkSpam: PropTypes.string,
};

export default OTPSentContent;
