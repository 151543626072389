// @flow

// Vendors
import React from 'react';
import { goBack, push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';

// Components
import {
  Trans,
  Form,
  InputGroupConfirmEmail,
} from '../../../../components/common';
import {
  Button,
  ButtonPrimary,
  TextButton,
} from 'texkit/dist/components/Buttons';
import { ONBOARDING_SAVE_POINT_NAMES, ONBOARDING_STEP_ROUTES } from '..';

// Helpers
import {
  onboardingGovEmailValidationSchema,
  isGovDomainValidationSchema,
} from '../../../../lib/validation';
import { useProtectOnboardingEmail } from '../../useProtectOnboardingEmail';

// Actions
import {
  captureGovEmailSubmitRequested,
  onboardingSaveProgress,
} from '../../../../reducers/onboarding/onboardingActions';
import { useRedirect } from '../../../../hooks';

const OnboardingGovEmailCapture = () => {
  const dispatch = useDispatch();
  const {
    user: { userEmail, contacts },
    onboarding: { isGovernmentEmployee, governmentEmail },
  } = useSelector(state => state);

  useProtectOnboardingEmail();
  useRedirect(!isGovernmentEmployee, `/getting-started/`);

  const initialValues = {
    email: governmentEmail,
    confirmEmail: governmentEmail,
    locale: 'es',
    userEmail,
  };

  const govEmailHint = {
    yupCondition: isGovDomainValidationSchema,
    content: { file: 'Onboarding', id: 'Field_GovernmentDomain' },
  };

  return (
    <>
      <h2 className="h1">
        <Trans
          file="Onboarding"
          id="GovEmailTitle"
          fallback="As a public-sector employee, please verify your work email."
        />
      </h2>
      <p className="lead">
        <Trans
          file="Onboarding"
          id="GovEmailDescription"
          fallback="This will be used to verify your status and eligibility for certain functionality. Enter your work email below to receive a verification code."
        />
      </p>
      <div className="onboarding-body">
        <Form
          formikConfig={{
            initialValues,
            onSubmit: (values, formikActions: FormikActions) => {
              // if the values have not changed and match the work email we have for the user, skip to the security questions
              if (
                values.confirmEmail === governmentEmail &&
                contacts &&
                governmentEmail === contacts.workEmail.contactInfo
              ) {
                dispatch(
                  push(
                    `/getting-started/${ONBOARDING_STEP_ROUTES.SECURITY_QUESTIONS}`
                  )
                );
              } else {
                dispatch(
                  captureGovEmailSubmitRequested({
                    values,
                    formikActions,
                    props: {
                      workEmail: values.confirmEmail,
                      flow: 'onboarding',
                    },
                  })
                );
              }
            },
            validationSchema: onboardingGovEmailValidationSchema(userEmail),
          }}
          render={({
            FormError,
            FormActions,
            FormActionsItem,
            formikProps,
          }) => (
            <>
              <InputGroupConfirmEmail
                formikProps={formikProps}
                hint={govEmailHint}
              />
              <FormError />
              <FormActions align="center">
                <FormActionsItem>
                  <ButtonPrimary
                    type="submit"
                    isLoading={formikProps.isSubmitting}
                    loadingContent={
                      <Trans file="Labels" id="Loading" fallback="Loading" />
                    }
                  >
                    <Trans file="Labels" id="SendCode" fallback="Send Code" />
                  </ButtonPrimary>
                </FormActionsItem>
                <FormActionsItem>
                  <Button
                    onClick={() => dispatch(goBack())}
                    disabled={formikProps.isSubmitting}
                  >
                    <Trans file="Labels" id="Back" fallback="Back" />
                  </Button>
                </FormActionsItem>
              </FormActions>
              <div className="page-links">
                <TextButton
                  onClick={async () => {
                    await dispatch(
                      onboardingSaveProgress(
                        ONBOARDING_SAVE_POINT_NAMES.GOVERNMENT_STATUS
                      )
                    );
                    dispatch(
                      push(
                        `/getting-started/${ONBOARDING_STEP_ROUTES.SECURITY_QUESTIONS}`
                      )
                    );
                  }}
                >
                  <Trans
                    file="Onboarding"
                    id="LaterAccountSettings"
                    fallback="I'll do this later via Account Settings"
                  />
                </TextButton>
              </div>
            </>
          )}
        />
      </div>
    </>
  );
};

export default OnboardingGovEmailCapture;
