export const gaActions = {
  global: '(global)',
  click: '(click)',
  back: '(back)',
  submit: '(submit)',
  session: '(session)',
  server: '(server)',
  success: '(success)',
  failure: '(failure)',
};
