export const bon = {
  APP_ERRORS: {
    bnlr: {
      linkService: {
        licenseNumber: 'params.licenseNumber',
        ssn: 'params.ssn',
      },
    },
  },
};
