// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputGroup, InputText, InputState, InputZipCode } from '../..';
import Trans from '../../../Trans/Trans';

// Helpers
import states from '../../../../../lib/data/states';

// Styles
import './input-group-address.scss';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Address
 */
const InputGroupAddress = ({
  fieldGroupPath,
  className,
  disabled,
  labelPosition = '',
  formikProps,
  hasLine2 = true,
  statesOptions,
  placeholders = {},
  ...props
}) => {
  fieldGroupPath = fieldGroupPath ? `${fieldGroupPath}.` : '';

  return (
    <InputGroup
      {...props}
      className={classnames('input-group-address', className)}
    >
      <InputText
        className="input-street"
        name={`${fieldGroupPath}streetAddress`}
        label={
          <Trans file="Labels" id="StreetAddress" fallback="Street Address" />
        }
        labelPosition={labelPosition}
        maxLength="280"
        disabled={disabled}
        formikProps={formikProps}
        placeholder={placeholders.streetAddress}
      />
      {hasLine2 && (
        <InputText
          className="input-unit"
          name={`${fieldGroupPath}addressLine2`}
          label={
            labelPosition && labelPosition.includes('above') ? (
              ''
            ) : (
              <Trans
                file="Labels"
                id="Unit"
                fallback="Apartment, suite, etc."
              />
            )
          }
          labelPosition={labelPosition}
          maxLength="280"
          disabled={disabled}
          formikProps={formikProps}
          placeholder={placeholders.addressLine2}
        />
      )}
      <InputText
        className="input-city"
        name={`${fieldGroupPath}city`}
        label={<Trans file="Labels" id="City" fallback="City" />}
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
        placeholder={placeholders.city}
      />
      <InputState
        name={`${fieldGroupPath}state`}
        label={<Trans file="Labels" id="State" fallback="State" />}
        labelPosition={labelPosition}
        options={statesOptions || states}
        disabled={disabled}
        formikProps={formikProps}
        placeholder={placeholders.state}
      />
      <InputZipCode
        name={`${fieldGroupPath}zipFive`}
        className="input-zip"
        label={<Trans file="Labels" id="Zip" fallback="Zip Code" />}
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
        placeholder={placeholders.zipFive}
      />
    </InputGroup>
  );
};

InputGroupAddress.propTypes = {
  className: PropTypes.string,
  labelPosition: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  fieldGroupPath: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
  hasLine2: PropTypes.bool,
  statesOptions: PropTypes.object,
  placeholders: PropTypes.object,
};

export default InputGroupAddress;
