// Agency specific counties should not be created in the front end.
// Rather the backend should map the UI county codes into agency specific correct county codes before storing.
// This needs to be fixed in M&O. The change involves both frontend and backend.

export default [
  { label: 'OTHER', value: '999' },
  { label: 'ANDERSON', value: '001' },
  { label: 'ANDREWS', value: '003' },
  { label: 'ANGELINA', value: '005' },
  { label: 'ARANSAS', value: '007' },
  { label: 'ARCHER', value: '009' },
  { label: 'ARMSTRONG', value: '011' },
  { label: 'ATASCOSA', value: '013' },
  { label: 'AUSTIN', value: '015' },
  { label: 'BAILEY', value: '017' },
  { label: 'BANDERA', value: '019' },
  { label: 'BASTROP', value: '021' },
  { label: 'BAYLOR', value: '023' },
  { label: 'BEE', value: '025' },
  { label: 'BELL', value: '027' },
  { label: 'BEXAR', value: '029' },
  { label: 'BLANCO', value: '031' },
  { label: 'BORDEN', value: '033' },
  { label: 'BOSQUE', value: '035' },
  { label: 'BOWIE', value: '037' },
  { label: 'BRAZORIA', value: '039' },
  { label: 'BRAZOS', value: '041' },
  { label: 'BREWSTER', value: '043' },
  { label: 'BRISCOE', value: '045' },
  { label: 'BROOKS', value: '047' },
  { label: 'BROWN', value: '049' },
  { label: 'BURLESON', value: '051' },
  { label: 'BURNET', value: '053' },
  { label: 'CALDWELL', value: '055' },
  { label: 'CALHOUN', value: '057' },
  { label: 'CALLAHAN', value: '059' },
  { label: 'CAMERON', value: '061' },
  { label: 'CAMP', value: '063' },
  { label: 'CARSON', value: '065' },
  { label: 'CASS', value: '067' },
  { label: 'CASTRO', value: '069' },
  { label: 'CHAMBERS', value: '071' },
  { label: 'CHEROKEE', value: '073' },
  { label: 'CHILDRESS', value: '075' },
  { label: 'CLAY', value: '077' },
  { label: 'COCHRAN', value: '079' },
  { label: 'COKE', value: '081' },
  { label: 'COLEMAN', value: '083' },
  { label: 'COLLIN', value: '085' },
  { label: 'COLLINGSWORTH', value: '087' },
  { label: 'COLORADO', value: '089' },
  { label: 'COMAL', value: '091' },
  { label: 'COMANCHE', value: '093' },
  { label: 'CONCHO', value: '095' },
  { label: 'COOKE', value: '097' },
  { label: 'CORYELL', value: '099' },
  { label: 'COTTLE', value: '101' },
  { label: 'CRANE', value: '103' },
  { label: 'CROCKETT', value: '105' },
  { label: 'CROSBY', value: '107' },
  { label: 'CULBERSON', value: '109' },
  { label: 'DALLAM', value: '111' },
  { label: 'DALLAS', value: '113' },
  { label: 'DAWSON', value: '115' },
  { label: 'DEAF SMITH', value: '117' },
  { label: 'DELTA', value: '119' },
  { label: 'DENTON', value: '121' },
  { label: 'DEWITT', value: '123' },
  { label: 'DICKENS', value: '125' },
  { label: 'DIMMIT', value: '127' },
  { label: 'DONLEY', value: '129' },
  { label: 'DUVAL', value: '131' },
  { label: 'EASTLAND', value: '133' },
  { label: 'ECTOR', value: '135' },
  { label: 'EDWARDS', value: '137' },
  { label: 'ELLIS', value: '139' },
  { label: 'EL PASO', value: '141' },
  { label: 'ERATH', value: '143' },
  { label: 'FALLS', value: '145' },
  { label: 'FANNIN', value: '147' },
  { label: 'FAYETTE', value: '149' },
  { label: 'FISHER', value: '151' },
  { label: 'FLOYD', value: '153' },
  { label: 'FOARD', value: '155' },
  { label: 'FORT BEND', value: '157' },
  { label: 'FRANKLIN', value: '159' },
  { label: 'FREESTONE', value: '161' },
  { label: 'FRIO', value: '163' },
  { label: 'GAINES', value: '165' },
  { label: 'GALVESTON', value: '167' },
  { label: 'GARZA', value: '169' },
  { label: 'GILLESPIE', value: '171' },
  { label: 'GLASSCOCK', value: '173' },
  { label: 'GOLIAD', value: '175' },
  { label: 'GONZALES', value: '177' },
  { label: 'GRAY', value: '179' },
  { label: 'GRAYSON', value: '181' },
  { label: 'GREGG', value: '183' },
  { label: 'GRIMES', value: '185' },
  { label: 'GUADALUPE', value: '187' },
  { label: 'HALE', value: '189' },
  { label: 'HALL', value: '191' },
  { label: 'HAMILTON', value: '193' },
  { label: 'HANSFORD', value: '195' },
  { label: 'HARDEMAN', value: '197' },
  { label: 'HARDIN', value: '199' },
  { label: 'HARRIS', value: '201' },
  { label: 'HARRISON', value: '203' },
  { label: 'HARTLEY', value: '205' },
  { label: 'HASKELL', value: '207' },
  { label: 'HAYS', value: '209' },
  { label: 'HEMPHILL', value: '211' },
  { label: 'HENDERSON', value: '213' },
  { label: 'HIDALGO', value: '215' },
  { label: 'HILL', value: '217' },
  { label: 'HOCKLEY', value: '219' },
  { label: 'HOOD', value: '221' },
  { label: 'HOPKINS', value: '223' },
  { label: 'HOUSTON', value: '225' },
  { label: 'HOWARD', value: '227' },
  { label: 'HUDSPETH', value: '229' },
  { label: 'HUNT', value: '231' },
  { label: 'HUTCHINSON', value: '233' },
  { label: 'IRION', value: '235' },
  { label: 'JACK', value: '237' },
  { label: 'JACKSON', value: '239' },
  { label: 'JASPER', value: '241' },
  { label: 'JEFF DAVIS', value: '243' },
  { label: 'JEFFERSON', value: '245' },
  { label: 'JIM HOGG', value: '247' },
  { label: 'JIM WELLS', value: '249' },
  { label: 'JOHNSON', value: '251' },
  { label: 'JONES', value: '253' },
  { label: 'KARNES', value: '255' },
  { label: 'KAUFMAN', value: '257' },
  { label: 'KENDALL', value: '259' },
  { label: 'KENEDY', value: '261' },
  { label: 'KENT', value: '263' },
  { label: 'KERR', value: '265' },
  { label: 'KIMBLE', value: '267' },
  { label: 'KING', value: '269' },
  { label: 'KINNEY', value: '271' },
  { label: 'KLEBERG', value: '273' },
  { label: 'KNOX', value: '275' },
  { label: 'LAMAR', value: '277' },
  { label: 'LAMB', value: '279' },
  { label: 'LAMPASAS', value: '281' },
  { label: 'LASALLE', value: '283' },
  { label: 'LAVACA', value: '285' },
  { label: 'LEE', value: '287' },
  { label: 'LEON', value: '289' },
  { label: 'LIBERTY', value: '291' },
  { label: 'LIMESTONE', value: '293' },
  { label: 'LIPSCOMB', value: '295' },
  { label: 'LIVE OAK', value: '297' },
  { label: 'LLANO', value: '299' },
  { label: 'LOVING', value: '301' },
  { label: 'LUBBOCK', value: '303' },
  { label: 'LYNN', value: '305' },
  { label: 'MCCULLOCH', value: '307' },
  { label: 'MCLENNAN', value: '309' },
  { label: 'MCMULLEN', value: '311' },
  { label: 'MADISON', value: '313' },
  { label: 'MARION', value: '315' },
  { label: 'MARTIN', value: '317' },
  { label: 'MASON', value: '319' },
  { label: 'MATAGORDA', value: '321' },
  { label: 'MAVERICK', value: '323' },
  { label: 'MEDINA', value: '325' },
  { label: 'MENARD', value: '327' },
  { label: 'MIDLAND', value: '329' },
  { label: 'MILAM', value: '331' },
  { label: 'MILLS', value: '333' },
  { label: 'MITCHELL', value: '335' },
  { label: 'MONTAGUE', value: '337' },
  { label: 'MONTGOMERY', value: '339' },
  { label: 'MOORE', value: '341' },
  { label: 'MORRIS', value: '343' },
  { label: 'MOTLEY', value: '345' },
  { label: 'NACOGDOCHES', value: '347' },
  { label: 'NAVARRO', value: '349' },
  { label: 'NEWTON', value: '351' },
  { label: 'NOLAN', value: '353' },
  { label: 'NUECES', value: '355' },
  { label: 'OCHILTREE', value: '357' },
  { label: 'OLDHAM', value: '359' },
  { label: 'ORANGE', value: '361' },
  { label: 'PALO PINTO', value: '363' },
  { label: 'PANOLA', value: '365' },
  { label: 'PARKER', value: '367' },
  { label: 'PARMER', value: '369' },
  { label: 'PECOS', value: '371' },
  { label: 'POLK', value: '373' },
  { label: 'POTTER', value: '375' },
  { label: 'PRESIDIO', value: '377' },
  { label: 'RAINS', value: '379' },
  { label: 'RANDALL', value: '381' },
  { label: 'REAGAN', value: '383' },
  { label: 'REAL', value: '385' },
  { label: 'RED RIVER', value: '387' },
  { label: 'REEVES', value: '389' },
  { label: 'REFUGIO', value: '391' },
  { label: 'ROBERTS', value: '393' },
  { label: 'ROBERTSON', value: '395' },
  { label: 'ROCKWALL', value: '397' },
  { label: 'RUNNELS', value: '399' },
  { label: 'RUSK', value: '401' },
  { label: 'SABINE', value: '403' },
  { label: 'SAN AUGUSTINE', value: '405' },
  { label: 'SAN JACINTO', value: '407' },
  { label: 'SAN PATRICIO', value: '409' },
  { label: 'SAN SABA', value: '411' },
  { label: 'SCHLEICHER', value: '413' },
  { label: 'SCURRY', value: '415' },
  { label: 'SHACKELFORD', value: '417' },
  { label: 'SHELBY', value: '419' },
  { label: 'SHERMAN', value: '421' },
  { label: 'SMITH', value: '423' },
  { label: 'SOMERVELL', value: '425' },
  { label: 'STARR', value: '427' },
  { label: 'STEPHENS', value: '429' },
  { label: 'STERLING', value: '431' },
  { label: 'STONEWALL', value: '433' },
  { label: 'SUTTON', value: '435' },
  { label: 'SWISHER', value: '437' },
  { label: 'TARRANT', value: '439' },
  { label: 'TAYLOR', value: '441' },
  { label: 'TERRELL', value: '443' },
  { label: 'TERRY', value: '445' },
  { label: 'THROCKMORTON', value: '447' },
  { label: 'TITUS', value: '449' },
  { label: 'TOM GREEN', value: '451' },
  { label: 'TRAVIS', value: '453' },
  { label: 'TRINITY', value: '455' },
  { label: 'TYLER', value: '457' },
  { label: 'UPSHUR', value: '459' },
  { label: 'UPTON', value: '461' },
  { label: 'UVALDE', value: '463' },
  { label: 'VAL VERDE', value: '465' },
  { label: 'VAN ZANDT', value: '467' },
  { label: 'VICTORIA', value: '469' },
  { label: 'WALKER', value: '471' },
  { label: 'WALLER', value: '473' },
  { label: 'WARD', value: '475' },
  { label: 'WASHINGTON', value: '477' },
  { label: 'WEBB', value: '479' },
  { label: 'WHARTON', value: '481' },
  { label: 'WHEELER', value: '483' },
  { label: 'WICHITA', value: '485' },
  { label: 'WILBARGER', value: '487' },
  { label: 'WILLACY', value: '489' },
  { label: 'WILLIAMSON', value: '491' },
  { label: 'WILSON', value: '493' },
  { label: 'WINKLER', value: '495' },
  { label: 'WISE', value: '497' },
  { label: 'WOOD', value: '499' },
  { label: 'YOAKUM', value: '501' },
  { label: 'YOUNG', value: '503' },
  { label: 'ZAPATA', value: '505' },
  { label: 'ZAVALA', value: '507' },
];
