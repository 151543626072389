//@flow

import { REQUEST_TYPES, REQUEST_LOCATIONS } from '.';

export const getPaymentAccountsRequest = (): RequestOptions => ({
  type: `[API] Payment Request`,
  url: `/user/ui/wallet`,
  APIMethod: REQUEST_TYPES.GET,
});

export const getAddPaymentAccountFrameUrlRequest = (): RequestOptions => ({
  type: `[API] Get iFrame URL for Adding Payment Method`,
  url: `/user/ui/wallet/paymentAccount/authorize`,
  APIMethod: REQUEST_TYPES.GET,
});

export const getEditPaymentAccountFrameUrlRequest = (body: {
  paymentAccountId: string,
}): RequestOptions => ({
  type: `[API] Get iFrame URL for Editing Payment Method`,
  url: `/user/ui/wallet/paymentAccount/edit`,
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const getPaymentFormFrameUrlRequest = (): RequestOptions => ({
  type: `[API] Get iFrame URL for the Payment Form`,
  url: `/user/ui/wallet/paymentForm`,
  APIMethod: REQUEST_TYPES.GET,
});

// TODO: Remove IP calls and environment variables from the project since it is no longer used
export const clientIPRequest = () => ({
  type: `[API] Client IP Request`,
  location: REQUEST_LOCATIONS.IP,
  APIMethod: REQUEST_TYPES.GET,
});

export const deletePaymentAccountRequest = (
  paymentAccount: PaymentAccount
): RequestOptions => ({
  type: `[API] Delete Payment Account`,
  url: `/user/ui/wallet/paymentAccount/delete`,
  APIMethod: REQUEST_TYPES.POST,
  body: paymentAccount,
});
