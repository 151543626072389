import { REQUEST_TYPES } from '.';

export const getBNLREligibiltyRequest = body => ({
  type: `[API] Get BNLR Online Renewal Eligibility`,
  url: '/agency/bnlr/eligibility/criteria',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const submitBNLRRequest = body => ({
  type: `[API] Submit BNLR`,
  url: '/agency/bnlr/renewal/submit',
  APIMethod: REQUEST_TYPES.POST,
  body,
});
