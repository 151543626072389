//@flow

// Vendors
import React from 'react';
import { components } from 'react-select';

const InputSelectControl = (props: Object) =>
  components.Control && (
    <components.Control
      {...props}
      className={`${
        props.selectProps.menuIsOpen
          ? 'input-select__control--menu-is-open'
          : ''
      }`}
    />
  );

export default InputSelectControl;
