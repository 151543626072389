//@flow

// Vendors
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import {
  linkAppLink,
  linkServiceSuccess,
} from '../../../reducers/agency/linkService/linkServiceActions';
import { clearAlertInfo } from '../../../reducers/alert/alertActions';

// Components
import { Trans, Loading } from '../../../components';

// Helpers
import { scrollToTop } from '../../../lib/helpers';
import { selectAgencyLink } from '../../../reducers/agency/agencySelectors';
import useRedirect from '../../../hooks/useRedirect';
import LinkServiceSetStrongPasswordForm from './LinkServiceSetStrongPasswordForm';

const LinkServiceStrongPw = () => {
  const link = useSelector(selectAgencyLink);
  useEffect(() => {
    return () => dispatch(clearAlertInfo());
  }, []);

  useRedirect(!link || !link.linkData, '/link');
  if (!link) return <Loading />;
  //use to check slug and send to success for tdlr

  const dispatch = useDispatch();

  const handleSuccess = () => {
    dispatch(linkAppLink())
      .then(() => dispatch(linkServiceSuccess()))
      .catch(() => {
        scrollToTop();
      });
  };

  return (
    <>
      <h1 className="h2">
        Please enter a stronger password for linking your employee account
      </h1>
      <dl className="account-group account-group-password">
        <dt>
          <Trans file="Account" id="Password" fallback="Password" />
        </dt>
        <LinkServiceSetStrongPasswordForm handleSuccess={handleSuccess} />
      </dl>
    </>
  );
};

export default LinkServiceStrongPw;
