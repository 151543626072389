// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { ProgressBar } from '..';

// Styles
import './takeover-page-header.scss';
import { Trans } from '../../../components';

const TakeoverPageHeader = ({
  preTitle,
  title,
  steps,
  activeStepId,
  hasProgressBar = false,
  hasStepCount = false,
  ignoreSteps = [],
}) => {
  if (ignoreSteps.includes(activeStepId)) return null;

  //The code below will check if "step" is present on the
  //active step object,if not, index of step will be step number

  const filteredSteps = steps.filter(step => !ignoreSteps.includes(step.id));

  const totalSteps = filteredSteps
    .map(item => item.step)
    .filter((value, index, self) => self.indexOf(value) === index || !value);

  const activeStepIndex = filteredSteps.findIndex(
    step => step.id === activeStepId
  );

  const activeStep = filteredSteps[activeStepIndex].step
    ? filteredSteps[activeStepIndex].step
    : activeStepIndex + 1;

  return (
    <>
      <div className="takeover-page-header">
        <div>{preTitle}</div>
        <div>
          <strong>{title}</strong>
        </div>
        {hasProgressBar && (
          <ProgressBar fraction={activeStep / totalSteps.length} />
        )}
      </div>

      {hasStepCount && (
        <p className="takeover-page-header-step-count">
          <strong>
            <Trans file="Labels" id="Step" fallback="Step"></Trans> {activeStep}{' '}
          </strong>
          <Trans file="Labels" id="of"></Trans> {totalSteps.length}
        </p>
      )}
    </>
  );
};

TakeoverPageHeader.propTypes = {
  preTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.node.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
  activeStepId: PropTypes.string.isRequired,
  hasProgressBar: PropTypes.bool.isRequired,
  hasStepCount: PropTypes.bool.isRequired,
  ignoreSteps: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.node.isRequired,
      id: PropTypes.string.isRequired,
    })
  ),
};

export default TakeoverPageHeader;
