// Vendors
import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

// Components
import { Navbar, NavbarAccountDropdown, NavbarBranding } from '../..';

// Styles
import './navbar-auth.scss';

const NavbarAuth = ({ mobileNav }) => (
  <CSSTransition
    in={!mobileNav.isActive}
    classNames="navbar"
    timeout={!mobileNav.isActive ? 0 : 500}
    unmountOnExit
  >
    <Navbar className="navbar-auth">
      <div className="navbar-left">
        <NavbarBranding to="/dashboard" aria-label="View My Dashboard" />
      </div>
      <div className="navbar-right">
        <NavbarAccountDropdown />
      </div>
    </Navbar>
  </CSSTransition>
);

const mapStateToProps = ({ mobileNav }) => ({ mobileNav });

NavbarAuth.propTypes = {
  mobileNav: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(NavbarAuth);
