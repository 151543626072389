// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles
import './form-actions.scss';

const FormActions = ({ className, align = '', direction = '', ...props }) => (
  <div className="form-actions-wrapper">
    <div
      {...props}
      className={classnames(
        'form-actions',
        { [`form-actions-align-${align}`]: align },
        { [`form-actions-direction-${direction}`]: direction },
        className
      )}
    />
  </div>
);

FormActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  align: PropTypes.string,
  direction: PropTypes.string,
};

export default FormActions;
