// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles
import './credit-card-icon.scss';

import { ReactComponent as discover } from '../../../assets/icons/discover.svg';
import { ReactComponent as visa } from '../../../assets/icons/visa.svg';
import { ReactComponent as amex } from '../../../assets/icons/amex.svg';
import { ReactComponent as mastercard } from '../../../assets/icons/mastercard.svg';
import { ReactComponent as blank } from '../../../assets/icons/blankcard.svg';

export const creditCardIcons = {
  discover,
  visa,
  amex,
  mastercard,
  blank,
};

/**
 * @visibleName Default
 */
const CreditCardIcon = ({ name, className, ...props }) => {
  if (!name || !creditCardIcons[name]) return null;

  const Icon = creditCardIcons[name];

  return (
    <span
      {...props}
      className={classnames(`credit-card-icon icon-${name}`, className)}
    >
      <Icon role="presentation" />
    </span>
  );
};

CreditCardIcon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CreditCardIcon;
