import * as yup from 'yup';
import ERRORS from '../errorCopy';
import { commonRules } from '../rules';

export const confirmPasswordValidationSchema = yup.object().shape({
  password: commonRules.password,
  confirmPassword: commonRules.passwordMatching('password'),
});

export const confirmPasswordStrongValidationSchema = yup.object().shape({
  password: commonRules.strongPassword,
  confirmPassword: commonRules.passwordMatching('password'),
});

export const verifyOTPValidationSchema = yup.object().shape({
  code: commonRules.contactCode,
});

export const phoneValidationSchema = yup.object().shape({
  phone: commonRules.phone(),
  phoneType: commonRules.phoneType,
});

export const emailValidationSchema = yup.object().shape({
  email: commonRules.email(),
});

export const createGovEmailValidationSchema = values => () =>
  values.isGovernmentEmployee === 'true'
    ? yup.object().shape({
        email: commonRules.email(),
      })
    : true;

export const isGovDomainValidationSchema = yup
  .string()
  .nullable()
  .test(val => val.substr(val.length - 4) === '.gov');

export const addressValidationSchema = yup.object().shape({
  streetAddress: commonRules.streetAddress,
  city: commonRules.city,
  state: commonRules.state,
  zipFive: commonRules.zipFive(),
});

export const addressLine2ValidationSchema = yup.object().shape({
  addressLine2: commonRules.addressLine2_a,
});

export const securityQuestionsValidationSchema = yup.object().shape({
  question1: commonRules.securityQuestion,
  answer1: commonRules.securityAnswer,
  question2: commonRules.securityQuestion,
  answer2: commonRules.securityAnswer,
  question3: commonRules.securityQuestion,
  answer3: commonRules.securityAnswer,
});

export const answerSecurityQuestionsValidationSchema = yup.object().shape({
  answer1: commonRules.securityAnswer,
  answer2: commonRules.securityAnswer,
});

export const selectMFAOptionValidationSchema = yup.object().shape({
  MFAOption: commonRules.MFAOption,
});

export const creditcardSchema = yup.object().shape({
  name: commonRules.creditcard.name,
  number: commonRules.creditcard.number,
  expiration: commonRules.creditcard.expiration(),
  cvv: commonRules.creditcard.cvv,
});

export const achSchema = yup.object().shape({
  name: commonRules.ach.name,
  routing: commonRules.ach.routing,
  confirmRouting: commonRules.ach.confirmRouting('routing'),
  account: commonRules.ach.account,
  confirmAccount: commonRules.ach.confirmAccount('account'),
});

export const domesticForeignAddressSchema = yup.object().shape({
  streetAddress: yup.string().when('addressLocation', {
    is: 'domestic',
    then: commonRules.streetAddress
      .required(ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.REQUIRED)
      .max(30, ERRORS.FIELDS.MAX_LENGTH(30)),
  }),
  addressLine2: commonRules.addressLine2.when('addressLocation', {
    is: 'domestic',
    then: commonRules.max(30),
  }),
  city: yup.string().when('addressLocation', {
    is: 'domestic',
    then: commonRules.city.max(23, ERRORS.FIELDS.MAX_LENGTH(23)),
  }),
  state: yup
    .object()
    .when('addressLocation', { is: 'domestic', then: commonRules.state }),
  zipFive: yup
    .string()
    .when('addressLocation', { is: 'domestic', then: commonRules.zipFive() }),
  foreignAddress: yup.object().when('addressLocation', {
    is: 'foreign',
    then: yup.object().shape({
      streetAddress: commonRules.streetAddress
        .required(ERRORS.FIELDS.ADDRESS.STREET_ADDRESS.REQUIRED)
        .max(30, ERRORS.FIELDS.MAX_LENGTH(30)),
      addressLine2: commonRules.addressLine2,
      addressLine3: commonRules.addressLine2,
      city: commonRules.city.max(23, ERRORS.FIELDS.MAX_LENGTH(23)),
      state: yup.string(),
      postalCode: yup.string(),
      country: commonRules.country,
    }),
  }),
});

export const verifyIdentityTOASSchema = yup.object().shape({
  fName: commonRules.firstNameMin2Chars,
  lName: commonRules.lastNameMin2Chars,
  dlNumber: commonRules.driverLicense,
  auditNum: commonRules.auditNumber,
  dob: commonRules.dob,
  lastFourSSN: commonRules.lastFourSSN,
});
