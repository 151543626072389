// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

// Components
import { InputGroup, InputSelect, InputPassword } from '../..';

// Styles
import './input-group-security-questions.scss';
import { Trans } from '../../../..';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Security Questions
 */
const InputGroupSecurityQuestions = ({
  questions,
  className,
  formikProps,
  ...props
}) => {
  if (!questions) return null;

  const { t } = useTranslation(['SecurityQuestions']);

  const questionValuesAsArray = Object.entries(formikProps.values)
    .filter(entry => entry[0].startsWith('question'))
    .sort((a, b) => (a[0] === b[0] ? 0 : a[0] < b[0] ? -1 : 1))
    .map(entry => entry[1]);

  const remainingQuestions = questions.filter(
    question =>
      !questionValuesAsArray.find(
        userSecurityQuestion => userSecurityQuestion.quid === question.quid
      )
  );

  return (
    <InputGroup
      {...props}
      className={classnames('input-group-security-questions', className)}
    >
      {questionValuesAsArray.map((userSecurityQuestion, index) => {
        const questionName = `question${index + 1}`;
        const answerName = `answer${index + 1}`;

        let options = [];

        if (userSecurityQuestion && userSecurityQuestion.quid)
          options[0] = userSecurityQuestion;

        options = [...options, ...remainingQuestions].map(question => {
          const questionObject = {
            ...question,
            question: t(
              `SecurityQuestion_${question.quid}`,
              'SecurityQuestions',
              [index + 1]
            ),
          };
          return questionObject;
        });

        return (
          <InputGroup key={questionName}>
            <InputSelect
              name={questionName}
              options={options}
              label={
                <Trans
                  file="SecurityQuestions"
                  id="SecurityQuestionLabel"
                  args={[index + 1]}
                />
              }
              labelPosition="above"
              optionLabelKey="question"
              optionValueKey="quid"
              emptyOptionLabel={
                <Trans file="SecurityQuestions" id="SecurityQuestion_empty" />
              }
              formikProps={formikProps}
            />
            <InputPassword
              className="input-security-answer"
              label={<Trans file="Labels" id="Answer" fallback="Answer" />}
              name={answerName}
              formikProps={formikProps}
            />
          </InputGroup>
        );
      })}
    </InputGroup>
  );
};

InputGroupSecurityQuestions.propTypes = {
  className: PropTypes.string,
  questions: PropTypes.object.isRequired,
  formikProps: formikInjectedPropsTypes.isRequired,
};

export default InputGroupSecurityQuestions;
