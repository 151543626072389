// Vendors
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DateTime, Duration } from 'luxon';

// Actions
import { openModal, closeModal } from '../../reducers/modal/modalActions';
import {
  endSession,
  updateSession,
} from '../../reducers/session/sessionActions';
import { keepAliveRequest } from '../../backend';

// Selectors
import { selectSessionData } from '../../reducers/session/sessionSelectors';
import { selectIsAuthenticated } from '../../reducers/auth/authSelectors';

// Components
import SessionWarningModal from '../common/Modals/SessionWarningModal/SessionWarningModal';

/***
 * Basically, refreshToken (formally keepAlive) will be call in 3 scenarions
 * 1-The user has been active and the BE idle time has been reached (with a 30 sec buffer)
 * 2-User has been inactive for 13 min, modal opens, user clicks continue session
 * 3-Coner Case: upon a page refresh, in which case BE idle time has passed, we will get a new token
 *
 * Upon login/page refresh, userActionFlag is set to true (login and page refresh are user actions)
 * to call refreshToken when idle time is reached...
 * If user doesn't do anything, modal window will invalidate the session
 *
 * For easy debugger set the following values
 * mock-server/api/login/index.js -> success to 3 min
 * mock-server/api/session.js  -> to 3 min
 * on this file ->initializeMeaningfulTimeout, set timeout to 60000 = 1 min
 *
 * Futher debugging uncommented the console logs:
 *
 */

class SessionManager extends PureComponent {
  duration = 2;

  state = {
    userActionFlag: false,
    latestAccessTime: null,
    warningDuration: Duration.fromObject({ minutes: this.duration }),
  };

  meaningfulTime = this.state.warningDuration.as('milliseconds');
  meaningfulTimeout;
  warningCountdown;
  warningTimer;
  sessionTimer;

  //check if user action should be flag for next BE call and re-initialized timer
  meaninfulActionTriggered = event => {
    if (!this.isMeaningfulEvent(event) || !this.props.isAuthenticated) {
      return false;
    }
    //console.log('User Action');
    this.removeEventListeners();
    this.setState({ userActionFlag: true });
    //Restart meaningfull timeout - 15 min, same as BE.... not in sync
    this.initializeMeaningfulTimeout();
  };

  constructor() {
    super();
    // This is needed to help clear event listeners within a class.
    this.meaninfulActionTriggered = this.meaninfulActionTriggered.bind(this);
  }

  initializeCountdown() {
    this.setState({
      warningDuration: Duration.fromObject({ minutes: this.duration }),
    });

    this.warningCountdown = setInterval(
      this.decrementWarningDuration.bind(this),
      1000
    );
    this.warningTimer = setTimeout(
      this.endSession.bind(this),
      this.meaningfulTime
    );
  }

  decrementWarningDuration() {
    this.setState({
      warningDuration: this.state.warningDuration.minus({ second: 1 }),
    });
  }

  stopCountdown() {
    clearInterval(this.warningCountdown);
    clearTimeout(this.warningTimer);
  }

  //13 min ( + 2 min from modal) timer set at the start and after each user action
  initializeMeaningfulTimeout() {
    //console.log('Initializing Timeout');
    this.addEventListeners();
    clearTimeout(this.meaningfulTimeout);
    this.meaningfulTimeout = setTimeout(
      this.beginSessionWarning.bind(this),
      780000
    );
  }

  beginSessionWarning() {
    // console.log('Beginning Session Warning');
    this.removeEventListeners();
    this.props.openModal('SessionWarningModal');
  }

  hasUserTakenAction() {
    this.removeEventListeners();
    //console.log('Checking for user action: ' + this.state.userActionFlag);
    if (this.state.userActionFlag) {
      this.keepAlive();
    }
  }

  userClickModal() {
    this.keepAlive();
    this.props.closeModal('SessionWarningModal');
  }

  beginMeaningfulWatch() {
    this.removeEventListeners();
    this.addEventListeners();
    this.initializeMeaningfulTimeout();
  }

  addEventListeners() {
    window.addEventListener('scroll', this.meaninfulActionTriggered, true);
    window.addEventListener('click', this.meaninfulActionTriggered, true);
    window.addEventListener('focusin', this.meaninfulActionTriggered, true);
  }

  removeEventListeners() {
    window.removeEventListener('scroll', this.meaninfulActionTriggered, true);
    window.removeEventListener('click', this.meaninfulActionTriggered, true);
    window.removeEventListener('focusin', this.meaninfulActionTriggered, true);
  }

  isRecent(ISO) {
    return (
      DateTime.local().toMillis() - DateTime.fromISO(ISO).toMillis() <
      this.meaningfulTime
    );
  }

  isMeaningfulEvent(event) {
    if (!event) return false;
    return (
      event.type === 'scroll' ||
      (event.type === 'click' &&
        (event.target.nodeName === 'BUTTON' ||
          event.target.nodeName === 'A')) ||
      (event.type === 'focusin' && event.target.nodeName === 'INPUT')
    );
  }

  keepAlive() {
    //console.log('Calling Keep Alive');
    //const { sessionData } = this.props;
    this.props
      .keepAliveRequest(process.env.REACT_APP_FORGEROCK_URL + '/refreshToken')
      .then(({ body }) => {
        if (body.expires_in) {
          this.props.updateSession(body.expires_in);
          localStorage.setItem('access_token', body.access_token);
          this.UNSAFE_componentWillReceiveProps();
          //Start listening for user action
          // console.log(
          //   '-------------Setting UserActionFlag: false---------------'
          // );
          this.setState({ userActionFlag: false });
          this.beginMeaningfulWatch();
          this.resetSessionTimer(body.expires_in);
        }
      })
      .catch(() => {
        this.endSession();
      });
  }

  endSession() {
    this.removeEventListeners();
    clearTimeout(this.meaningfulTimeout);
    clearInterval(this.warningCountdown);
    clearTimeout(this.warningTimer);
    clearTimeout(this.sessionTimer);
    this.props.endSession();
  }

  resetSessionTimer(expires) {
    const interval =
      DateTime.fromISO(expires)
        .minus({ seconds: 30 })
        .toMillis() - DateTime.local().toMillis();
    //console.log('Session Timer to: ' + interval);
    clearTimeout(this.sessionTimer);
    this.sessionTimer = setTimeout(
      //Call keepAlive if user has taken action after 15 min (from BE)... giving 30sec of buffer
      this.hasUserTakenAction.bind(this),
      interval
    );
  }

  componentDidMount() {
    const { maxIdleExpirationTime } = this.props.sessionData;
    this.resetSessionTimer(maxIdleExpirationTime);
    this.initializeMeaningfulTimeout();

    //Checking to see of timestamp on load is on the past to get a new one after a page refresh -  a page refresh is consider a user action
    if (Date.parse(maxIdleExpirationTime) - Date.parse(new Date()) < 0)
      this.keepAlive();
  }

  UNSAFE_componentWillReceiveProps() {
    const { sessionData } = this.props;
    if (!sessionData || !sessionData.latestAccessTime) return false;
    if (
      sessionData.latestAccessTime !== this.state.latestAccessTime &&
      this.isRecent(sessionData.latestAccessTime)
    )
      this.resetSessionTimer(sessionData.maxIdleExpirationTime);
  }

  componentWillUnmount() {
    clearTimeout(this.meaningfulTimeout);
    clearTimeout(this.sessionTimer);
  }

  render() {
    return (
      <>
        {this.props.children}
        <SessionWarningModal
          duration={this.state.warningDuration}
          onOpen={this.initializeCountdown.bind(this)}
          onClose={this.stopCountdown.bind(this)}
          keepAlive={this.userClickModal.bind(this)}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  sessionData: selectSessionData(state),
  isAuthenticated: selectIsAuthenticated(state),
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      openModal,
      closeModal,
      keepAliveRequest,
      endSession,
      updateSession,
    },
    dispatch
  );

SessionManager.propTypes = {
  sessionData: PropTypes.shape({
    latestAccessTime: PropTypes.string.isRequired,
    maxIdleExpirationTime: PropTypes.string.isRequired,
    maxSessionExpirationTime: PropTypes.string.isRequired,
  }).isRequired,
  isAuthenticated: PropTypes.string.isRequired,
  redirectLocation: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  keepAliveRequest: PropTypes.func.isRequired,
  updateSession: PropTypes.func.isRequired,
  endSession: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionManager);
