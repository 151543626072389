// Vendor
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

// Hooks
import { useOnClickOutside } from '../../../hooks';

import { useSelector } from 'react-redux';

// Components
import { Icon } from 'texkit/dist/components/Icon/Icon';
import { Hamburger } from '../../common/';
import { Trans } from '../..';
import { Link } from 'react-router-dom';

import { selectPathname } from '../../../reducers/route/routeSelectors';

// Styles
import './nav-dropdown.scss';

import LanguageSwitcher from '../NavbarLanguageSwitcher/LanguageSwitcher';

// Make Accessible
// https://www.smashingmagazine.com/2017/11/building-accessible-menu-systems/
// See the section on True Menus as the bottom of that article
// So the main goal - be able to mouse through the entire dropdown list with just tabs
// need aria-haspopup="true" and aria-expanded={/*boolean*/}
// need role="menu" and role="menuitem"
// Open through space or enter
// when the menu is opened (clicking on the toggle element), we need to focus on the first menu item
// when the menu is opened, we should keep track of the element that toggled the menu open - in state, as ref, or as attr
// when the menu is tabbed through [using tabs] the last item, we should close menu and return focus to the toggle element

const DropdownMenuItem = ({
  label: Label,
  className,
  children,
  showCheck,
  toggle,
  onClick,
  path,
  ...props
}) => {
  if (!Label) return null;

  const onClickHandler = event => {
    if (props.as === 'a') event.preventDefault();
    if (typeof onClick === 'function') onClick(event);
    toggle();
  };

  const currentPath = useSelector(selectPathname);

  return (
    <>
      {Label.props.id !== 'Language' && (
        <li className={classnames('nav-dropdown-menu-item', className)}>
          <Link
            {...props}
            to={path}
            onClick={onClick ? onClickHandler : toggle}
            className={
              'nav-dropdown-menu-item-action' +
              (path && path === currentPath
                ? ' nav-dropdown-current-selected'
                : '')
            }
          >
            {path && path === currentPath && (
              <Icon className="current-star" name="lone-star" />
            )}
            {typeof Label === 'string' ? (
              <span dangerouslySetInnerHTML={{ __html: Label }} />
            ) : (
              Label
            )}
          </Link>
        </li>
      )}
      {Label.props.id === 'Language' && (
        <>
          <span className="nav-dropdown-menu-item-label">
            <Trans file="Labels" id="Language" fallback="Language" />
          </span>
          <LanguageSwitcher />
        </>
      )}
    </>
  );
};

DropdownMenuItem.propTypes = {
  as: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.array,
  path: PropTypes.string,
  className: PropTypes.string,
  showCheck: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

/**
 * @visibleName Default
 */
const NavDropdown = ({
  id,
  items,
  itemsServices,
  showCheck,
  menuPosition = 'bottom-right',
  toggleComponent: DropdownToggle,
  className,
}) => {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  useOnClickOutside({
    ref,
    onClickOutside: () => toggle(false),
  });

  if (!items.length) return null;

  const toggle = open =>
    typeof open === 'boolean' ? setOpen(open) : setOpen(!isOpen);

  const onDropdownOpen = () =>
    ref.current.querySelector('.nav-dropdown-menu-item-action').focus();

  const dropdownClasses = classnames('dropdown', className, {
    isOpen,
    hasCheck: showCheck,
    [`nav-dropdown-menu-position-${menuPosition}`]: menuPosition,
  });
  const currentPath = useSelector(selectPathname);

  return (
    <div id={id} ref={ref} className={dropdownClasses}>
      {DropdownToggle && (
        <DropdownToggle
          onClick={toggle}
          id={`${id}-toggle`}
          className="dropdown-toggle"
          aria-haspopup="true"
          aria-expanded={isOpen}
          aria-label="open dropdown menu"
        >
          <Hamburger isActive={isOpen} />
        </DropdownToggle>
      )}
      <CSSTransition
        in={isOpen}
        onEntered={onDropdownOpen}
        timeout={200}
        classNames="nav-dropdown-menu"
        unmountOnExit
      >
        <div className={'nav-dropdown-menu-nav'}>
          <div className="menu-list">
            <ul
              key="dropdown"
              role="menu"
              aria-hidden={!isOpen}
              className="nav-dropdown-menu"
              aria-labelledby={`${id}-toggle`}
            >
              {items.map((item, i) => (
                <DropdownMenuItem
                  as="a"
                  role="menuitem"
                  key={`nav-dropdown-menu-item-${i}`}
                  toggle={toggle}
                  showCheck={showCheck}
                  {...item}
                />
              ))}
            </ul>
          </div>
          <div className="menu-list nav-dropdown-menu-services">
            {itemsServices && (
              <ul className="nav-dropdown-menu-services-list">
                {itemsServices.map((item, i) => (
                  <div
                    className={
                      'nav-dropdown-menu-service-item' +
                      (item.path && item.path === currentPath
                        ? ' nav-dropdown-current-selected'
                        : '')
                    }
                    key={`nav-dropdown-menu-item2-${i}`}
                  >
                    {item.path && item.path === currentPath && (
                      <Icon className="current-star" name="lone-star" />
                    )}
                    <Link
                      className="nav-dropdown-menu-service-title"
                      to={item.path}
                      onClick={toggle}
                    >
                      {item.service}
                      <p>{item.agency}</p>
                    </Link>
                  </div>
                ))}
              </ul>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

NavDropdown.propTypes = {
  items: PropTypes.arrayOf(DropdownMenuItem.propTypes).isRequired,
  itemsServices: PropTypes.arrayOf(DropdownMenuItem.propTypes),
  id: PropTypes.string.isRequired,
  toggleComponent: PropTypes.func.isRequired,
  className: PropTypes.string,
  showCheck: PropTypes.bool,
  disableOnBlur: PropTypes.bool.isRequired,
  menuPosition: PropTypes.oneOf([
    'bottom-right',
    'bottom-left',
    'top-right',
    'top-left',
  ]).isRequired,
};

export default NavDropdown;
