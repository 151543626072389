// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles
import './stepper-dots.scss';

const StepperDots = ({ steps = [], activeStep, className, ...props }) => {
  if (!steps.length) return null;

  return (
    <div {...props} className={classnames('stepper-dots', className)}>
      {steps.map(({ id: stepId }) => {
        return (
          <div
            key={stepId}
            className={`stepper-dots-step ${
              activeStep === stepId ? 'isActive' : ''
            }`}
          />
        );
      })}
    </div>
  );
};

StepperDots.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeStep: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default StepperDots;
