// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputGroup, InputPassword, Trans } from '../../..';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Answer Security Questions
 */
const InputGroupAnswerSecurityQuestions = ({
  questions,
  className,
  formikProps,
  ...props
}) => {
  if (!questions) return null;

  return (
    <InputGroup
      {...props}
      className={classnames('input-group-answer-security-questions', className)}
    >
      {questions.map((question, index) => {
        const answerName = `answer${index + 1}`;

        return (
          <InputPassword
            key={question.quid}
            className="input-borderless"
            label={
              <Trans
                file="SecurityQuestions"
                id={
                  question.quid
                    ? `SecurityQuestion_${question.quid}`
                    : `SecurityQuestion_empty`
                }
              />
            }
            labelPosition="above"
            placeholder={`Answer ${index + 1}`}
            name={answerName}
            formikProps={formikProps}
          />
        );
      })}
    </InputGroup>
  );
};

InputGroupAnswerSecurityQuestions.propTypes = {
  className: PropTypes.string,
  questions: PropTypes.object.isRequired,
  formikProps: formikInjectedPropsTypes.isRequired,
};

export default InputGroupAnswerSecurityQuestions;
