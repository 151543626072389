import { getMFAOptionsFromCallbacks } from '../../lib/helpers';

export const selectLoginMFACallbacks = ({ login }) => login.body.callbacks;
export const selectLoginMFABody = ({ login }) => login.body;
export const selectMFAOptions = ({ login }) =>
  getMFAOptionsFromCallbacks(login.body.callbacks);
export const selectDefaultMFAOption = ({ login }) =>
  getMFAOptionsFromCallbacks(login.body.callbacks)[0].value;
export const selectSelectedMFAOption = ({ login }) => login.selectedMFAOption;
export const selectRememberThisDevice = ({ login }) => login.rememberThisDevice;
export const selectLoginRedirect = ({ login }) => login.loginRedirect;
