// Vendor
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import { LoadingDots, Trans } from '../../../components';
import Card from 'texkit/dist/components/Cards/Card';
import AgencyCard from 'texkit/dist/components/Cards/AgencyCard/AgencyCard';

import Interstitial from '../../../components/Interstitial/Interstitial';

// Actions
import { getMyApps } from '../../../reducers/agency/agencyActions';
import { selectAgencyToLink } from '../../../reducers/agency/linkService/linkServiceActions';
import { selectAllAgenciesAsArray } from '../../../reducers/agency/agencySelectors';

//helpers
import { getAgencyDataBySlug } from '../../../lib/helpers';

const LinkServiceSelectAgency = ({
  agencies,
  interstitialAgencyCode,
  selectAgencyToLink,
  getMyApps,
  isGovernmentEmployee,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!agencies.length) {
      getMyApps().then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleAgencySelect = async agency => {
    setIsLoading(true);

    await selectAgencyToLink(agency);
    setIsLoading(false);
  };

  if (isLoading) return <LoadingDots />;

  return (
    <>
      {!interstitialAgencyCode && (
        <>
          <h1 className="h2">
            <Trans
              file="LinkService"
              id="SelectAgencyTitle"
              fallback="Please select the agency that issued your license, employee-id, or registration."
            />
          </h1>
          <ul className="link-service-select-agency-list">
            {agencies
              .filter(agency => {
                if (!isGovernmentEmployee) {
                  return agency.agencyId !== '7';
                }
                return true;
              })
              .map(agency => {
                const { agencyId, agencySlug, apps } = agency;
                const { name, logo } = getAgencyDataBySlug(agencySlug);
                return (
                  <li key={agencyId}>
                    <a className="text-decoration-none">
                      <AgencyCard
                        outlined={true}
                        onClick={() => handleAgencySelect(agency)}
                        agencySlug={agencySlug}
                        agency={{ name, logo }}
                        numLicenses={apps.length.toString()}
                        moreInfoText={
                          apps.length === 1 ? (
                            <Trans
                              file={agencySlug}
                              id={`${apps[0].appSlug}_AppName`}
                            />
                          ) : (
                            <>
                              {apps.length}{' '}
                              <Trans
                                file="Labels"
                                id="ServicesAvailable"
                                fallback="Available Services"
                              />
                            </>
                          )
                        }
                      />
                    </a>
                  </li>
                );
              })}
            <li>
              <Card outlined={true}>
                <p className="lead text-center">
                  <Trans
                    file="Common"
                    id="MoreAgenciesComingSoon"
                    fallback="More agencies coming soon!"
                  />
                </p>
              </Card>
            </li>
          </ul>
        </>
      )}

      {interstitialAgencyCode && (
        <Interstitial interstitialAgencyCode={interstitialAgencyCode} />
      )}
    </>
  );
  //   }
};

const mapStateToProps = state => ({
  agencies: selectAllAgenciesAsArray(state),
  isGovernmentEmployee: state.user.profile.isGovernmentEmployee,
  interstitialAgencyCode: state.router.location.appSlug,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ getMyApps, selectAgencyToLink }, dispatch);

LinkServiceSelectAgency.propTypes = {
  getMyApps: PropTypes.func,
  agencies: PropTypes.arrayOf(PropTypes.object),
  selectAgencyToLink: PropTypes.func,
  isGovernmentEmployee: PropTypes.bool,
  interstitialAgencyCode: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkServiceSelectAgency);
