/**
 * Component to format a passed in date based on the users selected language.
 */
import PropTypes from 'prop-types';

import { selectSelectedLanguage } from '../../../reducers/language/languageSelectors';
import { useSelector } from 'react-redux';
import { makeUTC, getCentralTime } from '../../../lib/datetime';

const dateOnlyOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const dateTimeOptions = {
  ...dateOnlyOptions,
  ...{
    hour: '2-digit',
    minute: '2-digit',
  },
};

const DateFormatter = function({ date, dateOnly = true, fallback = '----' }) {
  const language = useSelector(selectSelectedLanguage);
  const centralTime = getCentralTime(makeUTC(date));

  return centralTime !== 'Invalid Date'
    ? centralTime.toLocaleString(
        language === 'es' ? 'es-US' : 'en-US',
        dateOnly ? dateOnlyOptions : dateTimeOptions
      )
    : fallback;
};

DateFormatter.propTypes = {
  date: PropTypes.string.isRequired,
  dateOnly: PropTypes.bool,
  fallback: PropTypes.string,
};

export default DateFormatter;
