// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import { FormActions, FormActionsItem, Trans } from '../../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { signOutWithoutAPICall } from '../../../../../reducers/auth/authActions';

const EmailChangeSuccess = () => {
  const dispatch = useDispatch();

  return (
    <>
      <h2 className="h1 modal-title">
        <Trans
          file="MFA"
          id="EmailChangedTitle"
          fallback="Your email has been updated."
        />
      </h2>
      <p>
        <Trans
          file="MFA"
          id="EmailChangedInfo"
          fallback="You'll need to sign out for security purposes. Next time, sign in using your new email."
        />
      </p>
      <FormActions align="center">
        <FormActionsItem>
          <ButtonPrimary onClick={() => dispatch(signOutWithoutAPICall())}>
            <Trans file="Labels" id="SignOut" fallback="Sign Out" />
          </ButtonPrimary>
        </FormActionsItem>
      </FormActions>
    </>
  );
};

export default EmailChangeSuccess;
