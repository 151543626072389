// @flow

import { REQUEST_TYPES } from '.';

export const getCybersecurityOrgs = (search: string): RequestOptions => ({
  type: `[API] Get Cybersecurity Orgs`,
  url: `/cybersecurity/v1/orgs?search=${search}`,
  APIMethod: REQUEST_TYPES.GET,
});

export const sendCybersecurityAttestation = (body: any): RequestOptions => ({
  type: `[API] Cybersecurity Send Attestation`,
  url: `/cybersecurity/v1/certification`,
  APIMethod: REQUEST_TYPES.POST,
  body,
});
