import getMessages from '../../backend/messagebannerRequests';

// Consts
export const FETCH_MESSAGE_BANNERS_SUCCESS = `[MESSAGE BANNNERS] FETCH SUCCESS`;
export const PUSH_MESSAGE_BANNERS_SUCCESS = `[MESSAGE BANNNERS] PUSH SUCCESS`;
export const FETCH_MESSAGE_BANNERS_ERROR = `[MESSAGE BANNNERS] FETCH ERROR`;
export const MESSAGE_BANNER_DISMISS = `[MESSAGE BANNERS] DISMISS`;

export const AUTHOR_TYPES = {
  USER: 'USER',
  SYSTEM: 'SYSTEM',
};

export function fetchMessageBanners() {
  return async function(dispatch) {
    try {
      const {
        body: { data },
      } = await dispatch(getMessages);
      dispatch({
        type: FETCH_MESSAGE_BANNERS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: FETCH_MESSAGE_BANNERS_ERROR,
        payload: err,
      });
    }
  };
}

export function pushMessageBanner(message) {
  return async function(dispatch) {
    dispatch({
      type: PUSH_MESSAGE_BANNERS_SUCCESS,
      payload: message,
    });
  };
}

export function dismissMessageBanner(messageId) {
  return {
    type: MESSAGE_BANNER_DISMISS,
    payload: messageId,
  };
}
