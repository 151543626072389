export default [
  { label: 'Blue', value: 'BLU' },
  { label: 'Maroon', value: 'MAR' },
  { label: 'Yellow', value: 'YEL' },
  { label: 'Silver', value: 'SIL' },
  { label: 'Purple', value: 'PLE' },
  { label: 'Orange', value: 'ONG' },
  { label: 'Green', value: 'GRN' },
  { label: 'Gray', value: 'GRY' },
  { label: 'Pink', value: 'PNK' },
  { label: 'Tan', value: 'TAN' },
  { label: 'Brown', value: 'BRO' },
  { label: 'Black', value: 'BLK' },
  { label: 'Red', value: 'RED' },
  { label: 'Gold', value: 'GLD' },
  { label: 'Beige', value: 'BGE' },
  { label: 'White', value: 'WHI' },
];
