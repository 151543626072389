// Vendors
import React from 'react';
import { useSelector } from 'react-redux';

// Selectors
import { selectSignInParamValue } from '../../../reducers/auth/authSelectors';

// Components
import { Navbar, NavbarBranding, LanguageSwitcher } from '../..';

// Styles
import './navbar-public.scss';

const NavbarPublic = () => {
  const signInParam = useSelector(selectSignInParamValue);

  return (
    <Navbar className="navbar-public">
      <div className="navbar-left">
        <NavbarBranding
          to={`/sign-in?welcome=${signInParam}`}
          aria-label="View Sign-In Page"
        />
      </div>
      <div className="navbar-right">
        <LanguageSwitcher />
      </div>
    </Navbar>
  );
};

export default NavbarPublic;
