// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import HeroEmployee from './HeroEmployee';

const HeroEmployeeDefault = ({ className, title, ...props }) => {
  return (
    <HeroEmployee
      {...props}
      className={classnames('hero-employee-default', className)}
    >
      <h1 className="hero-employee-title">{title}</h1>
    </HeroEmployee>
  );
};

HeroEmployeeDefault.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string | PropTypes.node]).isRequired,
};

export default HeroEmployeeDefault;
