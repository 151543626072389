//@flow

// Vendors
import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Pages
import getPages from '../pages';
import PAGE_TYPES from '../pages/pageTypes';

const {
  CreateAccountPage,
  NoMatchPage,
  ForgotPasswordPage,
  ForgotUsernamePage,
} = getPages(PAGE_TYPES.PUBLIC);

// TODO: this could be an array of objects that is mapped through to create our routes below
export const publicRoutes = [
  '/',
  '/es',
  '/sign-in',
  '/dmv/vehicle-registration-renewal',
  '/es/dmv/renovacion-registro-vehiculo',
  '/tdlr/massage-therapy-license-renewals',
  '/es/tdlr/renovacion-licencia-de-terapia-masaje',
  '/create-account',
  '/forgot-password',
  //'/dps/driver-license-id-renewal-replacement',
  //'/es/dps/renovacion-licencia-de-conducir',
  '/forgot-username',
];

const RoutesPublic = () => (
  <Switch>
    <Route path="/create-account" component={CreateAccountPage} />
    <Route path="/forgot-password" component={ForgotPasswordPage} />
    <Route path="/forgot-username" component={ForgotUsernamePage} />
    <Route component={NoMatchPage} />
  </Switch>
);

export default RoutesPublic;
