// Helpers
import { to } from '../../lib/helpers';
import { setServerErrors, ERRORS_MAP } from '../../lib/validation';

// Actions
import { loginOTPTimeout } from '../login/loginActions';

// Consts
export const OTP_SEND_CODE_REQUESTED = '[OTP] Send Code Requested';
export const OTP_SEND_CODE_SUCCESS = '[OTP] Send Code Success';
export const OTP_VERIFY_CODE_REQUESTED = '[OTP] Verify Code Requested';
export const OTP_VERIFY_CODE_SUCCESS = '[OTP] Verify Code Success';
export const OTP_SET_DATA = '[OTP] Set Data';
export const OTP_CLEAR_STATE = '[OTP] Clear State';
export const OTP_MESSAGE_TYPES = {
  RESET_USER_PASSWORD: 'RESET_USER_PASSWORD',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  UPDATE_USER_NAME: 'UPDATE_USER_NAME',
  UPDATE_CONTACT: 'UPDATE_CONTACT',
  UPDATE_WORK_EMAIL: 'UPDATE_WORK_EMAIL',
};

export const sendOTP = payload => dispatch =>
  dispatch({ type: OTP_SEND_CODE_REQUESTED, payload });

export const verifyOTP = payload => dispatch =>
  dispatch({ type: OTP_VERIFY_CODE_REQUESTED, payload });

export const clearOTPState = () => dispatch =>
  dispatch({ type: OTP_CLEAR_STATE });

export const resendOTP = ({
  OTP,
  resendCodeActionCreator,
  onResendCodeSuccess,
  setResendCodeStatus,
  formikProps: { setFormikState },
}) => async dispatch => {
  setResendCodeStatus('sending');

  const action = resendCodeActionCreator
    ? ({ data }) => resendCodeActionCreator(data)
    : ({ data }) => sendOTP(data);
  const [{ body, response }] = await to(dispatch(action(OTP)));

  if (response.status !== 200) {
    if (body.failureUrl && body.failureUrl === 'OTP entered has expired')
      return dispatch(loginOTPTimeout());

    setResendCodeStatus('error');
    if (body.errorMessage && body.errorMessage.includes('timeout'))
      body.errors = {
        ERROR: [
          'Your authentication session timed out. Please refresh and try signing in again.',
        ],
      };
    if (!body.errors)
      body.errors = {
        ERROR: [
          'There was a problem sending your code. Please refresh and try signing in again.',
        ],
      };
    setServerErrors({ body, setFormikState, errorsMap: ERRORS_MAP.SEND_OTP });
  }

  if (response.status === 200) {
    setResendCodeStatus('success');
    if (typeof onResendCodeSuccess === 'function')
      onResendCodeSuccess({ body, response });
  }

  setTimeout(() => setResendCodeStatus('default'), 2000);
};
