import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Trans, FormActions, FormActionsItem } from '../../../components';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';
import SummaryCard from 'texkit/dist/components/Cards/SummaryCard/SummaryCard';

const DMVAlreadyLinkedSummary = ({
  vin,
  vehicleMake,
  vehicleModelYear,
  registrationExpiry,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <h1 className="h2">
        <Trans file="dmv" id="dmvrr_LinkService_ConfirmTitleLinked" />
      </h1>
      <p>
        <Trans file="dmv" id="dmvrr_LinkService_ConfirmDescriptionLinked" />
      </p>
      {/* DMV */}
      <SummaryCard>
        <SummaryCard.List
          items={[
            {
              className: 'word-break',
              label: <Trans file="Labels" id="vehicleMake" />,
              value: vehicleMake.toUpperCase() + ' TEST',
            },
            {
              label: <Trans file="Labels" id="modelYear" />,
              value: vehicleModelYear,
            },
            {
              label: <Trans file="Labels" id="vin" />,
              value: vin,
            },
            {
              label: <Trans file="Labels" id="registrationExpiry" />,
              value: registrationExpiry,
            },
          ]}
        />
      </SummaryCard>
      <FormActions>
        <FormActionsItem>
          <ButtonPrimary onClick={() => dispatch(push('/agency/dmv'))}>
            <Trans file="dmv" id="dmvrr_ViewMyVehicle" />
          </ButtonPrimary>
        </FormActionsItem>
        <FormActionsItem>
          <Button onClick={() => dispatch(push(`/link/lookup/dmvrr`))}>
            <Trans file="dmv" id={`dmvrr_LinkDifferent`} />
          </Button>
        </FormActionsItem>
      </FormActions>
    </>
  );
};

DMVAlreadyLinkedSummary.propTypes = {
  vin: PropTypes.string.isRequired,
  vehicleMake: PropTypes.string.isRequired,
  vehicleModelYear: PropTypes.string.isRequired,
  registrationExpiry: PropTypes.string.isRequired,
};

export default DMVAlreadyLinkedSummary;
