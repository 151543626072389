import * as yup from 'yup';
import { commonRules } from '../../rules';
import { domesticForeignAddressSchema } from '../common';
import ERRORS from '../../errorCopy';

export const TDLRLRReviewProfileFormSchema = yup.object().shape({
  contactInfo: yup.object().shape({
    email: commonRules.email({ required: false }),
    phoneNumber: commonRules.phone({
      required: false,
      excludeEmptyString: true,
    }),
  }),
  address: domesticForeignAddressSchema,
  militaryService: commonRules.yesNoRadioGroup,
  militaryModifiers: yup
    .object()
    .shape({
      id: yup.number(),
      code: yup.string(),
      description: yup.string(),
    })
    .nullable()
    .when('militaryService', {
      is: true,
      then: yup
        .object()
        .shape({
          id: yup.number(),
          code: yup.string().required(ERRORS.FIELDS.REQUIRED),
          description: yup.string(),
        })
        .required(ERRORS.FIELDS.REQUIRED),
    }),
});

export const createTDLRLREligibiliyQuestionsFormSchema = license => {
  const schema = {
    felonyMisdemeanor: commonRules.yesNoRadioGroup,
    criminalOffense: commonRules.yesNoRadioGroup,
    attestation: commonRules.requiredCheckbox,
  };

  if (license.licenseData.licenseCode === '5801') {
    schema.continuingEducation = commonRules.yesNoRadioGroup;
    schema.humantraffickingcourse = commonRules.requiredCheckbox;
  }
  return yup.object().shape(schema);
};
