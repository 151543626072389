// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import { Modal } from '../../..';
import Trans from '../../../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
// Actions
import { closeModal } from '../../../../../../reducers/modal/modalActions';
// Styles
import '../dlr-FAQ-modal.scss';

const DLR_Order_In_Process_Modal = () => {
  const dispatch = useDispatch();
  return (
    <Modal name="DLR_Order_In_Process_Modal">
      <h2 className="h1 modal-title-general">
        <Trans file="dlr" id="dlr_Order_In_Process" />
      </h2>
      <p className="modal-description-general">
        <Trans file="dlr" id="dlr_Order_In_Process_Desc" />
      </p>

      <ButtonPrimary
        className="modal-close-button-general"
        onClick={() => dispatch(closeModal('DLR_Order_In_Process_Modal'))}
      >
        <Trans file="Labels" id="okGotIt" />
      </ButtonPrimary>
    </Modal>
  );
};

export default DLR_Order_In_Process_Modal;
