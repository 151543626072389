// Vendor
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { replace, push } from 'connected-react-router';
import { TxButton, TxButtonMode, TxLoadingText } from 'texkit-ui/components';

// Actions
import { getMyApps } from '../../../reducers/agency/agencyActions';
import {
  verifyAppLink,
  getPreLookupForLink,
  storeLinkServiceData,
} from '../../../reducers/agency/linkService/linkServiceActions';
import {
  selectAgencyByAppRouteParam,
  selectAppByRouteParam,
} from '../../../reducers/agency/agencySelectors';

// Components
import { LINK_SERVICES_STEPS } from '.';
import {
  LoadingDots,
  Trans,
  Form,
  InputSelect,
  InputGroup,
} from '../../../components';

// Helpers
import { getAppDataBySlug } from '../../../lib/helpers';
import { preLookupValidationSchema } from '../../../lib/validation';

class LinkServicePreLookup extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    this.checkPreLookup();
  }

  async checkPreLookup() {
    const appExists = !!getAppDataBySlug(this.props.match.params.appSlug);
    if (!appExists) return this.props.replace('/link');
    if (!this.props.agency.apps || !this.props.app)
      await this.props.getMyApps();

    const { app, replace, getPreLookupForLink } = this.props;

    getPreLookupForLink(app.appId).then(([{ body }]) => {
      if (body && body.status === 'NOT_APPLICABLE')
        return replace(`/link/${LINK_SERVICES_STEPS.LOOKUP}/${app.appSlug}`);
      if (!body || !body.status === 'SUCCESS') return replace('/link');

      this.setState({ loading: false });
    });
  }

  handleSubmit(values) {
    const { app, storeLinkServiceData } = this.props;
    storeLinkServiceData({ selected: values });
    this.props.push(`/link/${LINK_SERVICES_STEPS.LOOKUP}/${app.appSlug}`);
  }

  render() {
    const { link, agency, app, replace } = this.props;

    if (this.state.loading || !link || !link.preLookup || !agency || !app)
      return <LoadingDots />;

    return (
      <>
        <h1 className="h2">
          <Trans
            file={agency.agencySlug === 'dlrlr' ? 'dlr' : agency.agencySlug}
            id={`${app.appSlug}_LinkService_LookupTitle`}
          />
        </h1>
        <Form
          className="prelookup-form"
          formikConfig={{
            onSubmit: this.handleSubmit.bind(this),
            initialValues: {
              program: { programId: '' },
              license: { licenseCode: '' },
            },
            validationSchema: preLookupValidationSchema,
          }}
          render={({
            FormError,
            FormActions,
            FormActionsItem,
            formikProps,
          }) => (
            <>
              <InputGroup>
                <InputSelect
                  name="program"
                  optionLabelKey="programName"
                  optionValueKey="programId"
                  aria-label="select a program"
                  emptyOptionLabel={
                    <Trans
                      file="Labels"
                      id="PreLookup_ProgramEmptySelectOption"
                      aria-label="select a program"
                    />
                  }
                  formikProps={formikProps}
                  options={link.preLookup.programs}
                  label={<Trans file="Labels" id="Board" aria-label="Board" />}
                  labelPosition="above"
                />
                <InputSelect
                  disabled={
                    !formikProps.values.program ||
                    !formikProps.values.program.programId
                  }
                  name="license"
                  optionLabelKey="licenseType"
                  optionValueKey="licenseCode"
                  aria-label="select a license"
                  emptyOptionLabel={
                    <Trans
                      file="Labels"
                      id="PreLookup_LicenseEmptySelectOption"
                      aria-label="select a license"
                    />
                  }
                  formikProps={formikProps}
                  options={
                    formikProps.values.program &&
                    formikProps.values.program.licenses
                      ? formikProps.values.program.licenses
                      : []
                  }
                  label={
                    <Trans
                      file="Labels"
                      id="LicenseType"
                      aria-label="License Type"
                    />
                  }
                  labelPosition="above"
                />
              </InputGroup>
              <FormError />

              <FormActions>
                <FormActionsItem>
                  <TxButton type="submit" disabled={!formikProps.isValid}>
                    {formikProps.isSubmitting ? (
                      <TxLoadingText>
                        <Trans file="Labels" id="Loading" fallback="Loading" />
                      </TxLoadingText>
                    ) : (
                      <Trans file="Labels" id="Next" fallback="Next" />
                    )}
                  </TxButton>
                </FormActionsItem>

                <FormActionsItem>
                  <TxButton
                    mode={TxButtonMode.Secondary}
                    onClick={() => replace('/link')}
                    disabled={formikProps.isSubmitting}
                  >
                    <Trans file="Labels" id="Back" fallback="Back" />
                  </TxButton>
                </FormActionsItem>
              </FormActions>
            </>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = (state, props) => ({
  link: state.agency.link,
  agency: selectAgencyByAppRouteParam(state, props),
  app: selectAppByRouteParam(state, props),
});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      verifyAppLink,
      replace,
      push,
      getMyApps,
      getPreLookupForLink,
      storeLinkServiceData,
    },
    dispatch
  );

const ConnectedLinkServicePreLookup = connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkServicePreLookup);

const RouteForConnectedLinkServicePreLookup = () => (
  <Route
    path="/link/prelookup/:appSlug"
    render={props => <ConnectedLinkServicePreLookup {...props} />}
  />
);

LinkServicePreLookup.propTypes = {
  link: PropTypes.object.isRequired,
  agency: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  replace: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  verifyAppLink: PropTypes.func.isRequired,
  getMyApps: () =>
    // Promise
    PropTypes.objectOf({
      then: PropTypes.object,
      catch: PropTypes.object,
    }).isRequired,
  getPreLookupForLink: PropTypes.func.isRequired,
  storeLinkServiceData: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default RouteForConnectedLinkServicePreLookup;
