import { replace } from 'connected-react-router';
// Consts
export const LANGUAGE_SELECTED = '[LANGUAGE] Selected';

export const switchLanguage = language => dispatch => {
  if (
    window.location.pathname === '/es/dmv/renovacion-registro-vehiculo' &&
    language === 'en'
  ) {
    dispatch(replace('/dmv/vehicle-registration-renewal'));
  } else if (
    window.location.pathname === '/dmv/vehicle-registration-renewal' &&
    language === 'es'
  ) {
    dispatch(replace('/es/dmv/renovacion-registro-vehiculo'));
  } else if (
    window.location.pathname === '/es/dps/renovacion-licencia-de-conducir' &&
    language === 'en'
  ) {
    dispatch(replace('/dps/driver-license-id-renewal-replacement'));
  } else if (
    window.location.pathname === '/dps/driver-license-id-renewal-replacement' &&
    language === 'es'
  ) {
    dispatch(replace('/es/dps/renovacion-licencia-de-conducir'));
  } else if (
    window.location.pathname ===
      '/es/tdlr/renovacion-licencia-de-terapia-masaje' &&
    language === 'en'
  ) {
    dispatch(replace('/tdlr/massage-therapy-license-renewals'));
  } else if (
    window.location.pathname === '/tdlr/massage-therapy-license-renewals' &&
    language === 'es'
  ) {
    dispatch(replace('/es/tdlr/renovacion-licencia-de-terapia-masaje'));
  } else if (window.location.pathname === '/es' && language === 'en') {
    dispatch(replace('/'));
  } else if (window.location.pathname === '/' && language === 'es') {
    dispatch(replace('/es'));
  }

  document.documentElement.setAttribute('lang', language);
  dispatch({ type: LANGUAGE_SELECTED, language });
};
