//@flow

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

const useRedirect = (check: boolean, redirectLocation: string) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (check) dispatch(replace(redirectLocation));
  }, []);
};

export default useRedirect;
