// Vendors
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Hooks
import { useOnClickOutside } from '../../../hooks';

const ModalContent = ({
  className,
  disableOnClickOutside,
  onClickOutside,
  children,
}) => {
  const ref = useRef();
  useOnClickOutside({ ref, onClickOutside, disableOnClickOutside });

  return (
    <div ref={ref} className={classnames('modal', className)} role="dialog">
      {children}
    </div>
  );
};

ModalContent.propTypes = {
  className: PropTypes.string.isRequired,
  disableOnClickOutside: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default ModalContent;
