// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';

// Components
import { Modal } from '..';
import Trans from '../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './real-id-page-modal.scss';

const RealIdPageModal = ({
  handleRenewClick,
  hanldeReplaceCAClick,
  handleEmergencyClick,
  modal: { data: { isReplaceCheck, isEmerContactsCheck } = {} } = {},
}) => {
  const dispatch = useDispatch();

  return (
    <Modal name="RealIdPageModal" className="real-id-page-modal">
      <div>
        <h2 className="h1 modal-header">
          <Trans file="dlr" id="Real_ID_Page_Title" />
        </h2>
        <p className="modal-description">
          <Trans file="dlr" id="Real_ID_Page_Desc" />
        </p>
      </div>
      <ButtonPrimary
        className="modal-close-button "
        onClick={() => {
          if (isReplaceCheck) {
            hanldeReplaceCAClick();
          } else if (isEmerContactsCheck) {
            handleEmergencyClick();
          } else {
            handleRenewClick();
          }
          dispatch(closeModal('RealIdPageModal'));
        }}
      >
        <Trans file="Labels" id="Continue" />
      </ButtonPrimary>
    </Modal>
  );
};

const mapStateToProps = state => ({
  modal: state.modal,
});

RealIdPageModal.propTypes = {
  handleRenewClick: PropTypes.func.isRequired,
  hanldeReplaceCAClick: PropTypes.object.isRequired,
  handleEmergencyClick: PropTypes.object.isRequired,
  isReplaceCheck: PropTypes.bool.isRequired,
  isEmerContactsCheck: PropTypes.bool.isRequired,
  modal: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(RealIdPageModal);
