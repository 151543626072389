// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import InputWrapper from '../InputWrapper';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Date
 */
const InputDate = ({ className, ...props }) => (
  <InputWrapper
    {...props}
    type="tel"
    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    className={classnames('input-date', className)}
  />
);

InputDate.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
};

InputDate.defaultProps = {
  label: 'Date',
};

export default InputDate;
