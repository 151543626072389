// Vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputWrapper, Trans } from '../../..';
import { ButtonIcon } from 'texkit/dist/components/Buttons';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Password
 */
const InputPassword = ({ className, ...props }) => {
  const [inputType, setInputType] = useState('password');

  function toggleInputType() {
    setInputType(inputType === 'password' ? 'text' : 'password');
  }

  function handleChange(event) {
    const { onChange, formikProps } = props;
    if (event.target.value === '') setInputType('password');
    if (onChange) return onChange(event);
    if (formikProps) return formikProps.handleChange(event);
  }

  const InputIcons =
    props.formikProps && props.formikProps.getFieldValue(props.name) ? (
      <ButtonIcon
        className="input-icon input-icon-type-toggle"
        icon={inputType === 'password' ? 'eye' : 'eye-closed'}
        onClick={toggleInputType}
        tabIndex="-1"
        aria-hidden={true}
        aria-label="password-eye-icon"
      />
    ) : null;

  return (
    <InputWrapper
      {...props}
      type={inputType}
      className={classnames('input-password', className)}
      icons={InputIcons}
      onChange={handleChange}
    />
  );
};

InputPassword.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  formikProps: formikInjectedPropsTypes,
};

InputPassword.defaultProps = {
  label: <Trans file="Labels" id="Password" fallback="Password" />,
  autoComplete: 'off',
};

export default InputPassword;
