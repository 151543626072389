// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import Hero from './Hero';
import HeroActions from './HeroActions';

// Types
import { HeroActionPropType } from '../../../lib/validation/propTypes/heroPropTypes';

const HeroDefault = ({
  className,
  title,
  actions,
  showHeroActions,
  ...props
}) => {
  const hasAction = actions ? 'hasAction' : null;

  return (
    <Hero
      {...props}
      className={classnames('hero-default', hasAction, className)}
    >
      <h1 className="hero-title">{title}</h1>
      {/* {hasAction && <HeroActions actions={actions} />} */}
      {hasAction && showHeroActions && <HeroActions actions={actions} />}
    </Hero>
  );
};

HeroDefault.propTypes = {
  className: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string | PropTypes.node]).isRequired,
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(HeroActionPropType),
    HeroActionPropType,
  ]),
  showHeroActions: PropTypes.bool.isRequired,
};

export default HeroDefault;
