//@flow

// $FlowIgnore
import { type PaymentState } from '../../../types/app.types';

export const selectPaymentState = ({ payment }: { payment: PaymentState }) =>
  payment;
export const selectPaymentAccounts = ({ payment }: { payment: PaymentState }) =>
  payment.paymentAccounts;
export const selectPaymentResponse = ({ payment }: { payment: PaymentState }) =>
  payment.paymentResponse;
export function selectWalletStatus({ payment }: { payment: PaymentState }) {
  return payment.walletStatus;
}
