import * as yup from 'yup';
import { commonRules } from '../../rules';
import ERRORS from '../../errorCopy';
import { DateTime } from 'luxon';
import { requiredCheckbox } from '../../rules/common';

export const CybersecurityAttesationFormSchema = yup.object().shape({
  cybersecurityOrganization: yup
    .object()
    .shape({ id: yup.string().required(ERRORS.FIELDS.REQUIRED) }),
  trainingCompletionDate: commonRules.date({
    required: ERRORS.FIELDS.REQUIRED,
    max: { date: DateTime.local(), contentId: 'Field_DatePast' },
  }),
  attestation: requiredCheckbox,
});
