/* eslint-disable react/display-name */

// Vendors
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Formik } from 'formik';

// Components
import { FormError, FormActions, FormActionsItem } from '.';

// Helpers
import { getObjectValueByPath } from '../../../lib/helpers';

// Styles
import './form.scss';

// Types
import { formikInjectedPropsTypes } from '../../../lib/validation/propTypes/formikPropTypes';

const Form = ({ className, render, formikConfig = {}, ...props }) => {
  const useValidateOnBlur = validateOnBlurConfig => {
    const [validateOnBlur, setValidateOnBlur] = useState(validateOnBlurConfig);

    useEffect(() => {
      function shouldValidateOnBlur(event) {
        if (event.type === 'mouseup' && !validateOnBlur)
          return setValidateOnBlur(true);
        if (event.type === 'mousedown' && event.target.closest('a, button'))
          return setValidateOnBlur(false);
      }

      if (validateOnBlur) {
        window.addEventListener('mousedown', shouldValidateOnBlur);
        window.addEventListener('mouseup', shouldValidateOnBlur);
      }
      return () => {
        window.removeEventListener('mousedown', shouldValidateOnBlur);
        window.removeEventListener('mouseup', shouldValidateOnBlur);
      };
    }, [validateOnBlur]);

    return validateOnBlur;
  };

  const formRef = useRef();
  const validateOnBlur = useValidateOnBlur(formikConfig.validateOnBlur || true);

  const resetServerErrors = (eventOrName, { status, setStatus }) => {
    const fieldName = eventOrName.target
      ? eventOrName.target.name
      : eventOrName;

    if (status && status.serverErrors) {
      delete status.serverErrors.formError;
      delete status.serverErrors[fieldName];
    }
    setStatus(status);
  };

  const focusTopError = form => {
    setTimeout(errorFocus, 50); // Local error check
    const serverErrorCheck = setTimeout(errorFocus, 500);
    const slowServerErrorCheck = setTimeout(errorFocus, 1000);

    function errorFocus() {
      const topError = form.querySelector(
        '.hasError input, .hasError textarea, .input-radio-group-error input'
      );
      if (topError) {
        clearTimeout(serverErrorCheck);
        clearTimeout(slowServerErrorCheck);
        topError.focus();
      }
    }
  };

  focusTopError.propTypes = {
    form: PropTypes.HTMLFormElement,
  };

  const handleSubmit = (event, form, formikProps) => {
    window.lastSubmittedForm = event.target;
    if (formRef.current) focusTopError(formRef.current);
    formikProps.setStatus({});
    formikProps.handleSubmit(event);
  };

  handleSubmit.propTypes = {
    event: PropTypes.event,
    form: PropTypes.HTMLFormElement,
    formikProps: formikInjectedPropsTypes,
  };

  const handleChange = (eventOrName, formikProps) => {
    if (eventOrName instanceof Object) formikProps.handleChange(eventOrName);
    resetServerErrors(eventOrName, formikProps);
  };

  handleChange.propTypes = {
    eventOrName: PropTypes.any.isRequired,
    formikProps: formikInjectedPropsTypes.isRequired,
  };

  return (
    <Formik
      {...formikConfig}
      validateOnBlur={validateOnBlur}
      render={formikProps => (
        <form
          {...props}
          onSubmit={event =>
            formRef.current && handleSubmit(event, formRef.current, formikProps)
          }
          className={classnames('form', className)}
          ref={formRef}
          noValidate
        >
          {render({
            FormError: formErrorProps => (
              <FormError {...formErrorProps} formikProps={formikProps} />
            ),
            FormActions,
            FormActionsItem,
            formikProps: {
              ...formikProps,
              handleChange: eventOrName =>
                handleChange(eventOrName, formikProps),
              getFieldValue: path =>
                getObjectValueByPath(formikProps.values, path),
              getFieldTouched: path =>
                getObjectValueByPath(formikProps.touched, path),
              getFieldError: path =>
                getObjectValueByPath(formikProps.errors, path),
              getFieldStatus: path =>
                getObjectValueByPath(formikProps.status, path),
            },
          })}
        </form>
      )}
    />
  );
};

Form.propTypes = {
  className: PropTypes.string,
  render: PropTypes.func.isRequired,
  formikConfig: formikInjectedPropsTypes.isRequired,
};

export default Form;
