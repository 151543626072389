// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import InputSelect from '../InputSelect/InputSelect';

// Data
import { countries } from '../../../../../lib/data';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Select Country
 */
const InputCountry = ({ className, ...props }) => (
  <InputSelect
    {...props}
    optionLabelKey="label"
    optionValueKey="code"
    emptyOptionLabel="Select a country..."
    isSearchable={true}
    className={classnames('input-country', className)}
  />
);

InputCountry.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
};

InputCountry.defaultProps = {
  label: 'Country',
  name: 'country',
  options: countries,
};

export default InputCountry;
