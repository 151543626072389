// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputGroup, InputPassword, Trans } from '../../..';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Confirm Password
 */
const InputGroupConfirmPassword = ({ className, formikProps, ...props }) => (
  <InputGroup
    {...props}
    className={classnames('input-group-confirm-password', className)}
  >
    <InputPassword
      name="password"
      label={<Trans file="Labels" id="Password" fallback="Password" />}
      formikProps={formikProps}
    />
    <InputPassword
      name="confirmPassword"
      label={
        <Trans file="Labels" id="ConfirmPassword" fallback="Confirm Password" />
      }
      className="input-password-confirm"
      formikProps={formikProps}
    />
  </InputGroup>
);

InputGroupConfirmPassword.propTypes = {
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
};

export default InputGroupConfirmPassword;
