// @flow

// Vendors
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncEffect } from '../../../../hooks';
import { push } from 'connected-react-router';

// Components
import {
  Form,
  InputGroupSecurityQuestions,
  Trans,
  LoadingDots,
} from '../../../../components/common';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';
import { OnboardingSecurityQuestionsStepWrapper } from './index';

// Actions
import {
  onboardingSaveSecurityQuestions,
  onboardingSaveProgress,
} from '../../../../reducers/onboarding/onboardingActions';
import { getAllSecurityQuestions } from '../../../../reducers/user/getAllSecurityQuestions/getAllSecurityQuestionsActions';

// Helpers
import { securityQuestionsValidationSchema } from '../../../../lib/validation';
import { ONBOARDING_STEP_ROUTES, ONBOARDING_SAVE_POINT_NAMES } from '..';

const OnboardingSetupSecurityQuestionsForm = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { allSecurityQuestions } = useSelector(state => state.user);
  const { questions } = useSelector(state => state.onboarding);

  useAsyncEffect(() => {
    return dispatch(getAllSecurityQuestions()).then(() => {
      setLoading(false);
    });
  });

  function getInitialValues(questions = [null, null, null]) {
    return questions.reduce((acc, cur, index) => {
      if (cur !== null) {
        acc[`question${index + 1}`] = cur;
        acc[`answer${index + 1}`] = cur.answer;
      } else {
        acc[`question${index + 1}`] = '';
        acc[`answer${index + 1}`] = '';
      }
      return acc;
    }, {});
  }

  function handleBackClick() {
    dispatch(
      push(`/getting-started/${ONBOARDING_STEP_ROUTES.SECURITY_QUESTIONS}`)
    );
  }

  function hasChanged(formValues, stateValues) {
    const parsedState = getInitialValues(stateValues);
    return (
      parsedState.answer1 !== formValues.answer1 ||
      parsedState.answer2 !== formValues.answer2 ||
      parsedState.answer3 !== formValues.answer3 ||
      parsedState.question1.quid !== formValues.question1.quid ||
      parsedState.question2.quid !== formValues.question2.quid ||
      parsedState.question3.quid !== formValues.question3.quid
    );
  }

  async function handleSubmit(values, formikActions: FormikActions) {
    if (hasChanged(values, questions)) {
      dispatch(onboardingSaveSecurityQuestions({ values, formikActions }));
      await dispatch(
        onboardingSaveProgress(ONBOARDING_SAVE_POINT_NAMES.SECURITY_QUESTIONS)
      );
    }
    dispatch(push(`/getting-started/${ONBOARDING_STEP_ROUTES.PHONE}`));
  }
  return (
    <OnboardingSecurityQuestionsStepWrapper>
      {loading ? (
        <LoadingDots />
      ) : (
        <Form
          className="onboarding-modal-form"
          formikConfig={{
            onSubmit: handleSubmit,
            initialValues: getInitialValues(questions),
            validationSchema: securityQuestionsValidationSchema,
          }}
          render={({
            FormError,
            FormActions,
            FormActionsItem,
            formikProps,
          }) => (
            <>
              <InputGroupSecurityQuestions
                formikProps={formikProps}
                questions={allSecurityQuestions}
              />
              <FormError />
              <FormActions align="center">
                <FormActionsItem>
                  <ButtonPrimary
                    type="submit"
                    isLoading={formikProps.isSubmitting}
                    loadingContent={
                      <Trans file="Labels" id="Saving" fallback="Saving" />
                    }
                  >
                    <Trans file="Labels" id="Next" fallback="Next" />
                  </ButtonPrimary>
                </FormActionsItem>
                <FormActionsItem>
                  <Button
                    onClick={handleBackClick}
                    disabled={formikProps.isSubmitting}
                  >
                    <Trans file="Labels" id="Back" fallback="Back" />
                  </Button>
                </FormActionsItem>
              </FormActions>
            </>
          )}
        />
      )}
    </OnboardingSecurityQuestionsStepWrapper>
  );
};

export default OnboardingSetupSecurityQuestionsForm;
