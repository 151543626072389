import * as React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

class ModalWrapper extends React.Component<Props> {
  modalRoot = document.createElement('section');
  modal = document.createElement('div');
  lastFocus;
  focusCapture;
  closeModalButton;

  captureFocus(event) {
    if (this.modal && !this.modal.contains(event.target)) {
      event.preventDefault();
      event.stopPropagation();
      this.focusModal();
    }
  }

  focusModal() {
    if (this.closeModalButton) this.closeModalButton.focus();
  }

  determineLastFocus() {
    if (document.activeElement !== document.body) {
      if (document.activeElement) this.lastFocus = document.activeElement;
    } else {
      if (window.lastSubmittedForm)
        this.lastFocus = window.lastSubmittedForm.querySelector(
          '[type="submit"]'
        );
    }
  }

  componentDidMount() {
    this.determineLastFocus();
    if (this.props.onOpen) this.props.onOpen();
    this.modal.classList.add('modal-wrapper');
    this.modalRoot.setAttribute('id', 'modal-root');
    if (document.body) document.body.appendChild(this.modalRoot);
    this.modalRoot.appendChild(this.modal);
    this.closeModalButton = this.modal.querySelector('.modal-close');
    this.focusModal();
    document.addEventListener('focus', this.captureFocus.bind(this), true);
  }

  componentWillUnmount() {
    if (document.body) document.body.removeChild(this.modalRoot);
    if (this.lastFocus) this.lastFocus.focus();
    document.removeEventListener('focus', this.captureFocus);
    if (this.props.onClose) this.props.onClose();
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.modal);
  }
}

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export default ModalWrapper;
