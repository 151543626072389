import * as yup from 'yup';
import { commonRules } from '../rules';

export const onboardingPhoneValidationSchema = yup.object().shape({
  phone: commonRules.phone(),
});

export const onboardingGovEmailValidationSchema = () =>
  yup.object().shape({
    email: commonRules.email(),
    confirmEmail: commonRules.emailMatching('email'),
  });
