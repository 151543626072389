// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { Container } from '..';

// Styles
import './hero-employee.scss';

const HeroEmployee = ({ className, children }) => (
  <header className={classnames('hero-employee', className)} role="banner">
    <Container className="hero-employee-container">
      <div className="hero-employee-body">{children}</div>
    </Container>
  </header>
);

HeroEmployee.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default HeroEmployee;
