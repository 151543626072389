// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Assets
import { ReactComponent as LoadingSvg } from '../../../assets/loading.svg';

// Styles
import './loading.scss';

/**
 * @visibleName Default
 */
const Loading = ({ className, ...props }) => (
  <div {...props} className={classnames('loading-container', className)}>
    <LoadingSvg className="loading" aria-label="content loading" />
  </div>
);

Loading.propTypes = {
  className: PropTypes.string,
};

export default Loading;
