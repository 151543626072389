import * as yup from 'yup';
import ERRORS from '../../errorCopy';
import { commonRules } from '../../rules';
import {
  domesticForeignAddressSchema,
  creditcardSchema,
  achSchema,
} from '../common';

export const BNLREligibilityFormSchema = yup.object().shape({
  aprnRequirements: commonRules.yesNoRadioGroup,
  criminalOffense: commonRules.yesNoRadioGroup,
  juryInvestigation: commonRules.yesNoRadioGroup,
  licenseSuspension: commonRules.yesNoRadioGroup,
  substanceAbuse: commonRules.yesNoRadioGroup,
  mentalDisability: commonRules.yesNoRadioGroup,
});

export const BNLRReviewProfileFormSchema = yup.object().shape({
  profileInfo: yup.object().shape({
    address: domesticForeignAddressSchema,
    contactInformation: yup.object().shape({
      email: commonRules.email().max(65, ERRORS.FIELDS.MAX_LENGTH(65)),
      fax: commonRules.phone({ excludeEmptyString: true, required: false }),
    }),
    primaryPractice: yup.object().shape({
      employmentInformation: yup.object().shape({
        status: commonRules.selectInput(),
        practiceName: commonRules.max(30),
        practiceHours: commonRules.max(3),
      }),
      address: yup.object().shape({
        streetAddress: commonRules.max(30),
        city: commonRules.max(20),
        zipFive: commonRules.zipFive({
          excludeEmptyString: true,
          required: false,
        }),
      }),
    }),
    secondaryPractice: yup.object().shape({
      employmentInformation: yup.object().shape({
        zipFive: commonRules.zipFive({
          excludeEmptyString: true,
          required: false,
        }),
      }),
    }),
    nursingEducation: yup.object().shape({
      basicEducation: yup.object().shape({
        degree: commonRules.selectInput(),
        graduationDate: commonRules.date({
          required: 'Please enter your date of graduation.',
          max: { years: 0, message: ERRORS.FIELDS.DATE.PAST },
        }),
        nursingProgramName: yup
          .string()
          .max(40, ERRORS.FIELDS.MAX_LENGTH(40))
          .required('Please enter the nursing program name.'),
        state: commonRules.max(30),
      }),
      highestEducation: yup.object().shape({
        degree: commonRules.selectInput(),
        graduationDate: commonRules.date({
          max: { years: 0, message: ERRORS.FIELDS.DATE.PAST },
        }),
        nursingProgramName: commonRules.max(40),
        state: commonRules.max(30),
      }),
    }),
    nurseDemographics: yup.object().shape({
      gender: yup.string().required(ERRORS.FIELDS.REQUIRED),
      hispanicOrigin: yup.string().required(ERRORS.FIELDS.REQUIRED),
      ethnicity: commonRules.selectInput(),
    }),
  }),
});

export const createBNLRPaymentFormSchema = values => {
  const validation =
    values.paymentMethod === 'CREDIT_CARD'
      ? yup.object().shape({
          creditcard: creditcardSchema,
          streetAddress: commonRules.streetAddress,
          city: commonRules.city,
          state: commonRules.state,
          zipFive: commonRules.zipFive(),
        })
      : yup.object().shape({ ach: achSchema });
  return function() {
    return validation;
  };
};
