// Vendors
import * as yup from 'yup';

export const bon = {
  APP_RULES: {
    bnlr: {
      linkService: {
        licenseNumber: yup
          .string()
          .trim()
          .required('Please enter your Board of Nursing license number.'),
      },
    },
  },
};
