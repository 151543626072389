import * as yup from 'yup';
import { commonRules } from '../rules';

export const passwordUpdateValidationSchema = yup.object().shape({
  currentPassword: commonRules.password,
  newPassword: commonRules.newPassword('currentPassword'),
  confirmNewPassword: commonRules.passwordMatching('newPassword'),
});

export const strongPasswordUpdateValidationSchema = yup.object().shape({
  currentPassword: commonRules.password,
  newPassword: commonRules.newStrongPassword('currentPassword'),
  confirmNewPassword: commonRules.passwordMatching('newPassword'),
});

export const displayNameValidationSchema = yup.object().shape({
  displayName: commonRules.displayName,
});
