// @flow

// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';

// Helpers
import { verifyOTPValidationSchema } from '../../../../lib/validation';
import { useRedirect } from '../../../../hooks';
import { ONBOARDING_SAVE_POINT_NAMES, ONBOARDING_STEP_ROUTES } from '..';

// Components
import {
  InputGroupCode,
  Form,
  Trans,
  OTPSentContent,
} from '../../../../components/common';
import { Button, ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { onboardingSaveProgress } from '../../../../reducers/onboarding/onboardingActions';
import { clearOTPState } from '../../../../reducers/OTP/OTPActions';
import { verifyContact } from '../../../../reducers/user/updateContact/updateContactActions';

const OnboardingPhoneVerify = () => {
  const dispatch = useDispatch();
  const {
    OTP,
    user,
    user: {
      profile: { cacheExist },
    },
    onboarding: { isGovernmentEmployee },
  } = useSelector(state => state);

  const onPhoneVerify = async () => {
    await dispatch(
      onboardingSaveProgress(ONBOARDING_SAVE_POINT_NAMES.PHONE_NUM_VALIDATION)
    );
    dispatch(clearOTPState());

    if (cacheExist || !isGovernmentEmployee) {
      dispatch(
        push(`/getting-started/${ONBOARDING_STEP_ROUTES.START_LINKING}`)
      );
    } else {
      await dispatch(
        onboardingSaveProgress(ONBOARDING_SAVE_POINT_NAMES.COMPLETE)
      );
    }
  };

  useRedirect(!OTP.data, `/getting-started/${ONBOARDING_STEP_ROUTES.PHONE}`);

  function handleSubmit(values, formikActions: FormikActions) {
    dispatch(
      verifyContact({
        values,
        formikActions,
        props: {
          verifyContact: user.verifyContact,
          onSuccess: onPhoneVerify,
          OTP,
        },
      })
    );
  }

  return (
    <>
      <h2 className="h1 title">
        <Trans
          file="MFA"
          id="VerifyPhone"
          fallback="Verify your phone number"
        />
      </h2>
      {OTP.data && (
        <OTPSentContent
          className="lead"
          channelCode={OTP.data.channelCode}
          contactInfo={OTP.data.contactInfo}
        />
      )}
      <div className="onboarding-body">
        <Form
          className="onboarding-phone-verify"
          formikConfig={{
            validateOnBlur: false,
            onSubmit: handleSubmit,
            initialValues: { code: '' },
            validationSchema: verifyOTPValidationSchema,
          }}
          render={({
            FormError,
            FormActions,
            FormActionsItem,
            formikProps,
          }) => (
            <>
              <InputGroupCode formikProps={formikProps} />
              <FormError />
              <FormActions align="center">
                <FormActionsItem>
                  <ButtonPrimary
                    type="submit"
                    isLoading={formikProps.isSubmitting}
                    loadingContent={
                      <Trans
                        file="Labels"
                        id="Verifying"
                        fallback="Verifying"
                      />
                    }
                  >
                    <Trans file="Labels" id="Next" fallback="Next" />
                  </ButtonPrimary>
                </FormActionsItem>
                <FormActionsItem>
                  <Button
                    onClick={() => dispatch(goBack())}
                    disabled={formikProps.isSubmitting}
                  >
                    <Trans file="Labels" id="Back" fallback="Back" />
                  </Button>
                </FormActionsItem>
              </FormActions>
            </>
          )}
        />
      </div>
    </>
  );
};

export default OnboardingPhoneVerify;
