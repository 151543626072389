//@flow

// Vendors
import React from 'react';
import { components } from 'react-select';

// Texkit Components
import { Icon } from 'texkit/dist/components/Icon/Icon';

const InputSelectOption = (props: Object) =>
  components.Option && (
    <components.Option {...props}>
      {props.children}{' '}
      {props.isSelected && (
        <Icon name="check" className="input-select__option-icon" />
      )}
    </components.Option>
  );

export default InputSelectOption;
