// Vendors
import React from 'react';

// Components
import {
  ServiceDownModal,
  ServerErrorModal,
  TermsAndConditionsModal,
  VehicleNotFoundModal,
  LinkDLIDModal,
  LockoutModal,
  DLRFAQModal,
  DLR_EMERGENCY_FAQModal,
  DLR_Renewal_FAQModal,
  DLR_REPLACEMENT_FAQModal,
  RenewalSpeedbumpModal,
} from '..';

const GlobalModals = () => (
  <>
    <ServiceDownModal />
    <ServerErrorModal />
    <TermsAndConditionsModal />
    <VehicleNotFoundModal />
    <LinkDLIDModal />
    <LockoutModal />

    <DLRFAQModal />
    <DLR_EMERGENCY_FAQModal />
    <DLR_Renewal_FAQModal />
    <DLR_REPLACEMENT_FAQModal />
    <RenewalSpeedbumpModal />
    {/* Add more global modals here */}
  </>
);

export default GlobalModals;
