// Vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import { Modal, FormActions, FormActionsItem, Trans } from '../..';
import { Button, ButtonDanger } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';
import { deactivateUser } from '../../../../reducers/user/deactivateUser/deactivateUserActions';

// Styles
import './deactivate-user-modal.scss';

const DeactivateUserModal = ({ deactivateUser, closeModal }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <Modal name="DeactivateUserModal" className="deactivate-user-modal">
      <h2 className="h1 modal-title">
        <Trans
          file="Modals"
          id="DeactivateAccount"
          fallback="Are you sure you want to deactive your account?"
        />
      </h2>
      <div className="modal-content">
        <p className="lead">
          <Trans
            file="Modals"
            id="UnableToAccessAccountUntilReactivate"
            fallback="You will not be able to access your account information until you reactivate."
          />
        </p>
        <FormActions align="center">
          <FormActionsItem>
            <ButtonDanger
              onClick={() => {
                setSubmitting(true);
                deactivateUser().catch(() => setSubmitting(false));
              }}
              isLoading={isSubmitting}
              loadingContent={
                <Trans
                  file="Labels"
                  id="Deactivating"
                  fallback="Deactivating"
                />
              }
            >
              <Trans
                file="Labels"
                id="DeactivateAccount"
                fallback="Deactivate Account"
              />
            </ButtonDanger>
          </FormActionsItem>
          <FormActionsItem>
            <Button
              onClick={() => closeModal('DeactivateUserModal')}
              disabled={isSubmitting}
            >
              <Trans file="Labels" id="Cancel" fallback="Cancel" />
            </Button>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeModal, deactivateUser }, dispatch);

DeactivateUserModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  deactivateUser: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(DeactivateUserModal);
