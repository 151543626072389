// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputGroup, InputText, InputRadioGroup, CheckModal } from '../../..';
import { TextButton } from 'texkit/dist/components/Buttons';

// Styles
import './input-group-ach-payment.scss';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Check Payment
 */
const InputGroupACHPayment = ({
  formikProps,
  openModal,
  className,
  ...props
}) => {
  if (!formikProps.values.ach) formikProps.values.ach = {};

  return (
    <InputGroup
      {...props}
      className={classnames('input-group-ach-payment', className)}
    >
      <InputText
        name="ach.name"
        className="ach-payment-name"
        label="Name on check"
        labelPosition="above"
        formikProps={formikProps}
      />
      <InputRadioGroup
        name="ach.accountType"
        label="Type of account"
        inline={true}
        options={[
          { id: 'CHECKING', label: 'Checking', value: 'CHECKING' },
          { id: 'SAVINGS', label: 'Savings', value: 'SAVINGS' },
        ]}
        formikProps={formikProps}
      />
      <InputText
        name="ach.routing"
        className="ach-payment-routing"
        label="Routing number"
        labelPosition="above"
        mask={[...Array(9)].map(() => /\d/)}
        formikProps={formikProps}
        helpText={
          <TextButton type="button" onClick={() => openModal('CheckModal')}>
            Where do I find this number?
          </TextButton>
        }
      />
      <InputText
        name="ach.confirmRouting"
        className="ach-payment-routing-confirm"
        label="Confirm routing number"
        labelPosition="above"
        mask={[...Array(9)].map(() => /\d/)}
        formikProps={formikProps}
      />
      <InputText
        name="ach.account"
        className="ach-payment-account"
        label="Account number"
        labelPosition="above"
        mask={[...Array(18)].map(() => /\d/)}
        formikProps={formikProps}
        helpText={
          <TextButton
            type="button"
            className="ach-payment-helper"
            onClick={() => openModal('CheckModal')}
          >
            Where do I find this number?
          </TextButton>
        }
      />
      <InputText
        name="ach.confirmAccount"
        className="ach-payment-account-confirm"
        label="Confirm account number"
        labelPosition="above"
        mask={[...Array(18)].map(() => /\d/)}
        formikProps={formikProps}
      />
      <CheckModal />
    </InputGroup>
  );
};

InputGroupACHPayment.propTypes = {
  formikProps: formikInjectedPropsTypes,
  openModal: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default InputGroupACHPayment;
