/* eslint-disable react/display-name */

// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import {
  Form,
  InputText,
  InputSSN,
  InputDOB,
  InputZipCode,
  InputTextUpper,
  InputNumeric,
  Trans,
} from '../../..';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';

// Helpers
import { getAppDataByCodes } from '../../../../lib/helpers';
import { createLinkServiceFormValidationSchema } from '../../../../lib/validation';
import * as lookupHints from './LinkServiceLookupHints';

// Styles
import './link-service-lookup-form.scss';

// Types
import { formikInjectedPropsTypes } from '../../../../lib/validation/propTypes/formikPropTypes';

export const AppParamsPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  attr: PropTypes.object.isRequired,
});

const getHint = id => {
  return lookupHints[id] ? lookupHints[id] : {};
};

//TODO: Need to refactor object below
const INPUTS = {
  DEFAULT: ({ form, name, attr, formikProps }) => (
    <InputText
      key={name}
      name={name}
      label={
        <Trans
          file="Labels"
          id={form && form.labels[name] && form.labels[name].id}
        />
      }
      labelPosition="above"
      formikProps={formikProps}
      max={attr.max}
      maxLength={attr.max}
      required={attr.required === 'true'}
      hint={getHint(form.labels[name].id)}
    />
  ),

  dlIDNumber: ({ form, name, attr, formikProps }) => (
    <InputNumeric
      key={name}
      name={name}
      label={<Trans file="Labels" id={form && form.labels[name].id} />}
      labelPosition="above"
      autoComplete={name}
      formikProps={formikProps}
      mask={[...Array(8)].map(() => /\d/)}
      max={attr.max}
      required={attr.required === 'true'}
    />
  ),
  // TODO: Possibly change back to "lastFourSSN" once back-end updates the params in their response.
  last4ssn: ({ form, name, attr, formikProps }) => (
    <InputNumeric
      key={name}
      name={name}
      label={<Trans file="Labels" id={form.labels[name].id} />}
      labelPosition="above"
      autoComplete={name}
      formikProps={formikProps}
      mask={[...Array(4)].map(() => /\d/)}
      max={attr.max}
      maxLength={attr.max}
      required={attr.required === 'true'}
    />
  ),
  dob: ({ form, name, attr, formikProps }) => (
    <InputDOB
      key={name}
      name={name}
      label={<Trans file="Labels" id={form.labels[name].id} />}
      labelPosition="above"
      autoComplete={name}
      formikProps={formikProps}
      max={attr.max}
      maxLength={attr.max}
      required={attr.required === 'true'}
    />
  ),
  lastFourOfVIN: ({ form, name, attr, formikProps }) => (
    <InputZipCode
      key={name}
      name={name}
      label={<Trans file="Labels" id={form.labels[name].id} />}
      labelPosition="above"
      autoComplete={name}
      formikProps={formikProps}
      max={attr.max}
      maxLength={attr.max}
      required={attr.required === 'true'}
      hint={getHint(form.labels[name].id)}
    />
  ),
  plateNumber: ({ form, name, attr, formikProps }) => (
    <InputTextUpper
      key={name}
      name={name}
      label={<Trans file="Labels" id={form.labels[name].id} />}
      labelPosition="above"
      autoComplete={name}
      formikProps={formikProps}
      max={attr.max}
      maxLength={attr.max}
      required={attr.required === 'true'}
      hint={getHint(form.labels[name].id)}
    />
  ),
  ssn: ({ form, name, attr, formikProps }) => (
    <InputSSN
      key={name}
      name={name}
      label={<Trans file="Labels" id={form.labels[name].id} />}
      labelPosition="above"
      autoComplete={name}
      formikProps={formikProps}
      max={attr.max}
      required={attr.required === 'true'}
    />
  ),
};

const INPUTSPropTypes = {
  form: PropTypes.any.isRequired,
  name: PropTypes.string,
  attr: PropTypes.any.isRequired,
  formikProps: formikInjectedPropsTypes.isRequired,
};

INPUTS.DEFAULT.propTypes = INPUTSPropTypes;
INPUTS.dlIDNumber.propTypes = INPUTSPropTypes;
INPUTS.last4ssn.propTypes = INPUTSPropTypes;
INPUTS.dob.propTypes = INPUTSPropTypes;
INPUTS.lastFourOfVIN.propTypes = INPUTSPropTypes;
INPUTS.plateNumber.propTypes = INPUTSPropTypes;
INPUTS.ssn.propTypes = INPUTSPropTypes;

const INPUT_REDUCER = (form, { name, attr = {} }, formikProps) =>
  INPUTS[name]
    ? INPUTS[name]({ form, name, attr, formikProps })
    : INPUTS.DEFAULT({ form, name, attr, formikProps });

const CustomContent = ({ agencyCode }) => {
  if (agencyCode === '452')
    return (
      <p className="link-service-lookup-form-custom">
        <Trans
          file="LinkService"
          id="LookupCustomContent_TDLR"
          fallback="Please make sure your last name matches your TDLR license including any suffix or special characters."
        />
      </p>
    );
  if (agencyCode === '608')
    return (
      <p className="link-service-lookup-form-custom">
        <Trans file="LinkService" id="LookupCustomContent_DMVRR" />
      </p>
    );
  // if (agencyCode === '405')
  //   return (
  //     <p className="link-service-lookup-form-custom">
  //       <Trans file="LinkService" id="LookupCustomContent_DLR" />
  //     </p>
  //   );

  return null;
};

CustomContent.propTypes = {
  agencyCode: PropTypes.string,
};

const LinkServiceLookupForm = ({
  className,
  agency,
  app,
  appParams,
  reLinkData,
  onSubmit,
  onCancel,
  submitText = <Trans file="Labels" id="Next" />,
}) => (
  <Form
    className={classnames('link-service-lookup-form', className)}
    formikConfig={{
      onSubmit,
      initialValues: appParams.reduce((acc, param) => {
        acc[param.name] = '';
        // acc['lastName'] = pathname.includes('cpaln') ? lastName : '';
        acc['lastFourOfVIN'] = reLinkData ? reLinkData : '';
        return acc;
      }, {}),
      validationSchema: createLinkServiceFormValidationSchema(
        agency,
        app,
        appParams
      ),
    }}
    render={({ FormError, FormActions, FormActionsItem, formikProps }) => (
      <>
        <div className="link-service-lookup-form-body">
          {appParams.map(params =>
            INPUT_REDUCER(
              getAppDataByCodes(agency.agencyCode, app.appCode).linkService
                .form,
              params,
              formikProps
            )
          )}
        </div>
        <FormError />
        <FormActions>
          <FormActionsItem>
            <ButtonPrimary
              type="submit"
              disabled={!formikProps.isValid}
              isLoading={formikProps.isSubmitting}
              loadingContent={<Trans file="Labels" id="Submitting" />}
            >
              {submitText}
            </ButtonPrimary>
          </FormActionsItem>
          {onCancel && (
            <FormActionsItem>
              <Button
                className="button-outline"
                onClick={onCancel}
                disabled={formikProps.isSubmitting}
              >
                <Trans file="Labels" id="Back" />
              </Button>
            </FormActionsItem>
          )}
        </FormActions>
      </>
    )}
  />
);

LinkServiceLookupForm.propTypes = {
  agency: PropTypes.object.isRequired,
  app: PropTypes.object.isRequired,
  appParams: PropTypes.arrayOf(AppParamsPropTypes).isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.node,
  onCancel: PropTypes.func,
  className: PropTypes.string,
  reLinkData: PropTypes.string,
};

export default LinkServiceLookupForm;
