// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import InputWrapper from '../InputWrapper';

// Styles
import './input-radio.scss';

/**
 * @visibleName Radio Button
 */
const InputRadio = ({ className, ...props }) => (
  <InputWrapper
    {...props}
    type="radio"
    className={classnames('input-radio', className)}
  />
);

InputRadio.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default InputRadio;
