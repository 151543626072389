// Vendors
import React from 'react';

// Helpers
import { publicURL } from '../../../../lib/helpers';

// Components
import { Modal } from '../..';
import { Download } from 'texkit/dist/components/Download';
import Trans from '../../Trans/Trans';

// Styles
import './terms-and-conditions-modal.scss';

const TermsAndConditionsModal = () => (
  <Modal name="TermsAndConditionsModal" className="terms-and-conditions-modal">
    <div className="flex-row">
      <div className="flex-item terms-and-conditions-modal-header terms-and-conditions-modal-content">
        <div className="terms-and-conditions-modal-content-overflow">
          <h2 className="terms-and-conditions-modal-header-title">
            <Trans file="Common" id="HelloWithComma" fallback="Hello, " />
          </h2>
          <p className="terms-and-conditions-modal-header-content">
            <Trans
              file="Modals"
              id="TermsAndConditionsSummaryHeader"
              fallback="We want to make sure the terms and conditions for using these services are clear, so here’s a summary in simple English."
            />
          </p>
          <div className="terms-and-conditions-modal-download">
            <Download href={publicURL('TermsandConditions.pdf')}>
              <Trans
                file="Modals"
                id="FollowThisLinkForTermsAndConditions"
                fallback="If you want to read the full agreement, simply follow this link."
              />
            </Download>
          </div>
        </div>
      </div>
      <div className="flex-item terms-and-conditions-modal-quicklook terms-and-conditions-modal-content">
        <div
          className="terms-and-conditions-modal-content-overflow"
          tabIndex="0"
        >
          <h3>
            <Trans
              file="Modals"
              id="QuickLookAtTermsAndConditionsHeader"
              fallback="A quick look at the Terms and Conditions"
            />
          </h3>
          <p>
            <Trans
              file="Modals"
              id="TheTermsAndConditions"
              fallback="The terms and conditions of use for {{SITE_DATA.SITE_NAME}} state:"
            />
          </p>
          <ul>
            <Trans
              file="Modals"
              id="TermsAndConditionsListItems"
              fallback="<li>our services will be available to everyone, and every effort will be made to ensure accessibility.</li><li>that personal information will be kept safe and secured, and only information needed for conducting business will be collected.</li><li>that sensitive personal information will not be permanently stored. However, state organizations and agencies that provide services may need to retain your information</li><li>that any suspicious or illegal activity may be reported to law enforcement.</li><li>you can discontinue using {{SITE_DATA.SITE_NAME}} at any point, but your government information will continue to be stored by the government.</li><li>that sensitive and transactional information will be protected in accordance with international, federal, and state security standards and regulations. This includes encrypting or scrambling all personal information.</li><li>that small text files, also known as “cookies,” may be placed on your computer to make transactions more efficient. They will not be used for any marketing purposes.</li><li>personal information will never be shared on Texas.gov social media sites, such as Facebook or Instagram</li><li>that {{SITE_DATA.SITE_NAME}} terms and conditions don’t extend to any other site, even if it is linked from this site.</li><li>any legal dispute must be decided under the laws and in the courts of Texas.</li>"
            />
          </ul>
        </div>
      </div>
    </div>
  </Modal>
);

export default TermsAndConditionsModal;
