const centralTimeOffset = {
  CST: 6 * 60 * 60 * 1000,
  CDT: 5 * 60 * 60 * 1000,
};

function getStdTimezoneOffset() {
  const now = new Date();
  const jan = new Date(now.getFullYear(), 0, 1);
  const jul = new Date(now.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

export function getCentralOffset() {
  const now = new Date();
  if (now.getTimezoneOffset() < getStdTimezoneOffset()) {
    return 'CDT';
  }
  return 'CST';
}

function centralOffsetFromUser() {
  const now = new Date();
  const userOffset = now.getTimezoneOffset();
  const centralOffset = centralTimeOffset[getCentralOffset()];
  return centralOffset - userOffset * 60 * 1000;
}

export function makeUTC(unformattedDate) {
  const [dateString, timeString] = unformattedDate.split(' ');
  const [year, month, date] = dateString.split('-').map(str => parseInt(str));

  if (timeString) {
    const [hr, min, sec] = timeString.split(':').map(str => parseInt(str));
    return Date.UTC(year, month - 1, date, hr, min, sec, 0);
  } else {
    // If there is not a time, just make it today.
    return new Date(year, month - 1, date).getTime();
  }
}

export function getCentralTime(dateUTC) {
  return new Date(dateUTC + centralOffsetFromUser());
}
