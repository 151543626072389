//@flow

// Vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';

// Actions
import {
  linkAppLink,
  linkServiceSuccess,
} from '../../../reducers/agency/linkService/linkServiceActions';

// Components
import { FormActions, FormActionsItem, Trans } from '../../../components';
import SummaryCard from 'texkit/dist/components/Cards/SummaryCard/SummaryCard';
import {
  ButtonPrimary,
  Button,
  TextButton,
} from 'texkit/dist/components/Buttons';

// Helpers
import { capitalize, scrollToTop } from '../../../lib/helpers';

const TDLRLinkServiceConfirm = ({ licenses }) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);

  const handleConfirmClick = () => {
    setSubmitting(true);
    dispatch(linkAppLink())
      .then(() => dispatch(linkServiceSuccess()))
      .catch(() => {
        scrollToTop();
        setSubmitting(false);
      });
  };

  return (
    <>
      <h1 className="h2">
        <Trans file="tdlr" id={`tdlrlr_LinkService_ConfirmTitleDefault`} />
      </h1>
      <p>
        <Trans
          file="tdlr"
          id={`tdlrlr_LinkService_ConfirmDescriptionDefault`}
        />
      </p>

      {(licenses || []).length === 1 && (
        <>
          <SummaryCard>
            <SummaryCard.List
              items={[
                {
                  className: 'word-break',
                  label: <Trans file="Labels" id="Name" fallback="Name" />,
                  value: licenses[0].name,
                },
                {
                  label: (
                    <Trans
                      file="Labels"
                      id="LicenseNumber"
                      fallback="License number"
                    />
                  ),
                  value: licenses[0].licenseNumber,
                },
                {
                  label: (
                    <Trans
                      file="Labels"
                      id="LicenseName"
                      fallback="License Name"
                    />
                  ),
                  value: capitalize(licenses[0].licenseType),
                },
              ]}
            />
          </SummaryCard>
          <TextButton
            className="link-service-lookup-different"
            onClick={() => dispatch(goBack())}
            disabled={submitting}
          >
            <Trans file="Labels" id="LookUpADifferentLicense" />
          </TextButton>
        </>
      )}

      {(licenses || []).length > 1 &&
        licenses.map((license, index) => (
          <React.Fragment key={license.licenseType}>
            <SummaryCard>
              <SummaryCard.List
                items={[
                  {
                    className: 'word-break',
                    label: <Trans file="Labels" id="Name" fallback="Name" />,
                    value: license.name,
                  },
                  {
                    label: (
                      <Trans
                        file="Labels"
                        id="LicenseNumber"
                        fallback="License number"
                      />
                    ),
                    value: license.licenseNumber,
                  },
                  {
                    label: (
                      <Trans
                        file="Labels"
                        id="LicenseName"
                        fallback="License Name"
                      />
                    ),
                    value: capitalize(license.licenseType),
                  },
                ]}
              />
            </SummaryCard>
            {index === 0 && (
              <p className="link-service-additional-linked">
                <Trans
                  file="LinkService"
                  id="MultipleLicensesConfirmLink"
                  fallback="All supported licenses/agencies, including those below, will be linked at the same time."
                />
              </p>
            )}
          </React.Fragment>
        ))}

      <FormActions>
        <FormActionsItem>
          <ButtonPrimary
            onClick={handleConfirmClick}
            isLoading={submitting}
            loadingContent={
              <Trans file="Labels" id="Linking" fallback="Linking" />
            }
          >
            <Trans
              file="Labels"
              id="LinkToMyAccount"
              fallback="Link To My Account"
            />
          </ButtonPrimary>
        </FormActionsItem>
        <FormActionsItem>
          <Button onClick={() => dispatch(goBack())} disabled={submitting}>
            <Trans file="Labels" id="Back" fallback="Back" />
          </Button>
        </FormActionsItem>
      </FormActions>
    </>
  );
};

TDLRLinkServiceConfirm.propTypes = {
  licenses: PropTypes.array.isRequired,
};

export default TDLRLinkServiceConfirm;
