// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputGroup, InputPassword } from '../..';
import Trans from '../../../Trans/Trans';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Update Password
 */
const InputGroupUpdatePassword = ({ className, formikProps, ...props }) => (
  <InputGroup
    {...props}
    className={classnames('input-group-update-password', className)}
  >
    <InputPassword
      className="input-current-password"
      label={
        <Trans file="Labels" id="CurrentPassword" fallback="Current Password" />
      }
      name="currentPassword"
      formikProps={formikProps}
    />
    <InputPassword
      className="input-new-password"
      label={<Trans file="Labels" id="NewPassword" fallback="New Password" />}
      name="newPassword"
      formikProps={formikProps}
    />
    <InputPassword
      className="input-confirm-new-password"
      label={
        <Trans
          file="Labels"
          id="ConfirmNewPassword"
          fallback="Confirm New Password"
        />
      }
      name="confirmNewPassword"
      formikProps={formikProps}
    />
  </InputGroup>
);

InputGroupUpdatePassword.propTypes = {
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
};

export default InputGroupUpdatePassword;
