// Vendors
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { Modal, Stepper } from '../..';
import VerifyEmailChange from './VerifyEmailChangeSteps/VerifyEmailChange';
import EmailChangeSuccess from './VerifyEmailChangeSteps/EmailChangeSuccess';

// Styles
import './verify-email-change-modal.scss';

export const EMAIL_CHANGE_STEPS = {
  VERIFY: 'Email_Change_Verify_Step',
  SUCCESS: 'Email_Change_Success_Step',
};

const VerifyEmailChangeModal = props => {
  const [activeStep, setActiveStep] = useState(EMAIL_CHANGE_STEPS.VERIFY);
  const modalId = 'VerifyContactEmailModal';
  const steps = [
    {
      id: EMAIL_CHANGE_STEPS.VERIFY,
      component: VerifyEmailChange,
      data: {
        setActiveStep: stepId => {
          setActiveStep(stepId);
        },
        modalId,
      },
    },
    { id: EMAIL_CHANGE_STEPS.SUCCESS, component: EmailChangeSuccess },
  ];

  useEffect(() => {
    if (activeStep !== EMAIL_CHANGE_STEPS.VERIFY)
      setActiveStep(EMAIL_CHANGE_STEPS.VERIFY);
  }, []);

  const disableCloseButton =
    activeStep === EMAIL_CHANGE_STEPS.VERIFY ? false : true;

  return (
    <Modal
      {...props}
      id="VerifyContactEmailModal"
      channelCode="SMTP"
      name="VerifyEmailChangeModal"
      className="verify-email-change-modal"
      disableOnClickOutside={true}
      disableCloseButton={disableCloseButton}
    >
      <Stepper
        className="verify-contact-modal-steps"
        transition="GrowTransition"
        steps={steps}
        activeStep={activeStep}
      />
    </Modal>
  );
};

VerifyEmailChangeModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default VerifyEmailChangeModal;
