// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import InputWrapper from '../InputWrapper';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Text
 */
const InputText = ({ className, ...props }) => (
  <InputWrapper
    {...props}
    type="text"
    className={classnames('input-text', className)}
  />
);

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
};

export default InputText;
