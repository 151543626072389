//@flow

// Vendors
import React from 'react';
import { components } from 'react-select';

// Texkit Components
import { Icon } from 'texkit/dist/components/Icon/Icon';

const InputSelectDropdownIndicator = (props: any) =>
  components.DropdownIndicator && (
    <components.DropdownIndicator {...props}>
      <Icon
        name="chevron-down"
        className="input-icon input-icon-select-arrow"
      />
    </components.DropdownIndicator>
  );

export default InputSelectDropdownIndicator;
