//@flow

// Vendors
import React from 'react';
import { components } from 'react-select';

// Texkit Components
import { Icon } from 'texkit/dist/components/Icon/Icon';

const InputSelectMultiValueRemove = (props: any) =>
  components.MultiValueRemove && (
    <components.MultiValueRemove {...props}>
      <Icon name="x" />
    </components.MultiValueRemove>
  );

export default InputSelectMultiValueRemove;
