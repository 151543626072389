//@flow

// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

// Graphics
import { ReactComponent as OnboardingWelcomeSvg } from '../../../../assets/onboarding/onboarding-welcome.svg';

// Components
import { FormActions, FormActionsItem, Trans } from '../../../../components';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { ONBOARDING_STEP_ROUTES } from '..';

// Selectors
import { selectSignInParamValue } from '../../../../reducers/auth/authSelectors';

// Actions
import { onboardingSaveGovEmploymentStatus } from '../../../../reducers/onboarding/onboardingActions';

const OnboardingWelcome = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state);
  const signInParam = useSelector(selectSignInParamValue);

  const handleNextClick = () => {
    if (signInParam === 'hb3834') {
      dispatch(
        push(`/getting-started/${ONBOARDING_STEP_ROUTES.GOV_EMPLOYMENT_STATUS}`)
      );
    } else {
      dispatch(
        onboardingSaveGovEmploymentStatus({ isGovernmentEmployee: 'false' })
      );
      dispatch(
        push(`/getting-started/${ONBOARDING_STEP_ROUTES.SECURITY_QUESTIONS}`)
      );
    }
  };
  return (
    <div className="onboarding-welcome">
      <h2 className="h1">
        <Trans file="Onboarding" id="WelcomeTitle" fallback="Welcome, " />
        {user && user.profile && user.profile.fName}
      </h2>
      <div>
        <p className="lead">
          <Trans
            file="Onboarding"
            id="WelcomeDescription"
            fallback="We just have a few short steps to make sure your {{SITE_DATA.SITE_NAME}} experience is safe and convenient."
          />
        </p>
        <div className="onboarding-body">
          <div className="graphic">
            <OnboardingWelcomeSvg role="presentation" />
          </div>

          <FormActions align="center">
            <FormActionsItem>
              <ButtonPrimary
                onClick={handleNextClick}
                loadingContent={
                  <Trans file="Labels" id="Loading" fallback="Loading" />
                }
              >
                <Trans file="Labels" id="GetStarted" fallback="Get Started" />
              </ButtonPrimary>
            </FormActionsItem>
          </FormActions>
        </div>
      </div>
    </div>
  );
};

export default OnboardingWelcome;
