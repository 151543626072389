import { selectAlertInfo } from './alertSelectors';
import { gaActions } from '../gaActionTypes';

// Consts
export const ALERT_STORE_ALERT_INFO = `[ALERT] ${gaActions.global} Store Alert Info`;
export const ALERT_CLEAR_ALERT_INFO = `[ALERT] ${gaActions.global} Clear Alert Info`;

export const storeAlertInfo = payload => dispatch =>
  dispatch({ type: ALERT_STORE_ALERT_INFO, payload });

export const clearAlertInfo = () => (dispatch, getState) =>
  selectAlertInfo(getState())
    ? dispatch({ type: ALERT_CLEAR_ALERT_INFO })
    : null;
