import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './accordion.scss';

function Accordion({ title, content }) {
  const [setActive, setActiveState] = useState('');
  const openClose = () => {
    setActiveState(setActive === '' ? 'active' : '');
  };

  return (
    <div className={'accordion_section ' + setActive}>
      <button className="accordion" onClick={openClose}>
        <p className="accordion_title">{title}</p>
      </button>
      <div className="accordion_content">
        <p className="accordion_text">{content}</p>
      </div>
    </div>
  );
}

Accordion.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
};

export default Accordion;
