// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';

// Actions
import { openModal } from '../../../../../reducers/modal/modalActions';

// Components
import { InputCheckboxInverse, Trans } from '../../..';
import { TextButton } from 'texkit/dist/components/Buttons';

// Styles
import './input-link-dl-id-account.scss';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

const LinkDLDAccountLabel = ({ onClick }) => (
  <>
    <Trans file="Labels" id="IWantTo" />{' '}
    <TextButton type="button" onClick={onClick}>
      <Trans file="Common" id="LinkDLID" />
    </TextButton>
  </>
);

LinkDLDAccountLabel.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const InputLinkDLIDAccount = ({
  className,
  formikProps,
  openModal,
  ...props
}) => (
  <InputCheckboxInverse
    {...props}
    name="linkdlid"
    label={<LinkDLDAccountLabel onClick={() => openModal('LinkDLIDModal')} />}
    checked={formikProps.values.linkdlid === true}
    formikProps={formikProps}
    className={classnames('input-link-dl-id-account', className)}
  />
);

InputLinkDLIDAccount.propTypes = {
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
  openModal: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ openModal }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputLinkDLIDAccount);
