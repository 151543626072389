//@flow

// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { push, goBack } from 'connected-react-router';
import { verifyContact } from '../../../../reducers/user/updateContact/updateContactActions';
import { clearOTPState } from '../../../../reducers/OTP/OTPActions';
import { onboardingSaveProgress } from '../../../../reducers/onboarding/onboardingActions';
import { updateUserGovernmentEmployeeStatus } from '../../../../reducers/user/updateContact/updateContactActions';

// Components
import {
  Form,
  Trans,
  OTPSentContent,
  InputGroupCode,
} from '../../../../components/common';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';

// Helpers
import { useRedirect } from '../../../../hooks';
import { verifyOTPValidationSchema } from '../../../../lib/validation';
import { ONBOARDING_SAVE_POINT_NAMES, ONBOARDING_STEP_ROUTES } from '..';

const OnboardingGovEmailVerify = () => {
  const dispatch = useDispatch();
  const { OTP, user, onboarding } = useSelector(state => state);
  const onEmailVerify = async () => {
    await dispatch(
      updateUserGovernmentEmployeeStatus(
        onboarding.isGovernmentEmployee === 'true' ? true : false
      )
    );
    await dispatch(
      onboardingSaveProgress(ONBOARDING_SAVE_POINT_NAMES.GOVERNMENT_STATUS)
    );
    dispatch(clearOTPState());
    dispatch(
      push(`/getting-started/${ONBOARDING_STEP_ROUTES.SECURITY_QUESTIONS}`)
    );
  };

  // Send to email capture if the user goes back from the security questions state.
  // We will not have OTP data, but we should have a gov employee with an email entered
  useRedirect(
    !OTP.data && onboarding.isGovernmentEmployee && onboarding.governmentEmail,
    `/getting-started/${ONBOARDING_STEP_ROUTES.GOV_EMAIL_CAPTURE}`
  );

  // Send user to getting started if we are missing OTP data and either gov employee status or email
  useRedirect(
    !OTP.data &&
      (!onboarding.isGovernmentEmployee || !onboarding.governmentEmail),
    '/getting-started'
  );

  return (
    <>
      <h2 className="h1 title">
        <Trans
          file="Onboarding"
          id="GovEmailVerificationTitle"
          fallback="Please verify your work email."
        />
      </h2>
      {OTP.data && (
        <OTPSentContent
          className="lead"
          channelCode={'SMTP'}
          contactInfo={OTP.data.contactInfo}
        />
      )}
      <div className="onboarding-body">
        <Form
          className="onboarding-email-verify"
          formikConfig={{
            validateOnBlur: false,
            enableReinitialize: true,
            onSubmit: (values, formikActions: FormikActions) =>
              dispatch(
                verifyContact({
                  values,
                  formikActions,
                  props: {
                    verifyContact: user.verifyContact,
                    onSuccess: onEmailVerify,
                    OTP,
                  },
                })
              ),
            initialValues: { code: '' },
            validationSchema: verifyOTPValidationSchema,
          }}
          render={({
            FormError,
            FormActions,
            FormActionsItem,
            formikProps,
          }) => (
            <>
              <div className="onboarding-modal-form-body">
                <InputGroupCode formikProps={formikProps} />
              </div>
              <FormError />
              <FormActions align="center">
                <FormActionsItem>
                  <ButtonPrimary
                    type="submit"
                    isLoading={formikProps.isSubmitting}
                    loadingContent={
                      <Trans
                        file="Labels"
                        id="Verifying"
                        fallback="Verifying"
                      />
                    }
                  >
                    <Trans file="Labels" id="Verify" fallback="Verify" />
                  </ButtonPrimary>
                </FormActionsItem>
                <FormActionsItem>
                  <Button
                    onClick={() => dispatch(goBack())}
                    disabled={formikProps.isSubmitting}
                  >
                    <Trans file="Labels" id="Back" fallback="Back" />
                  </Button>
                </FormActionsItem>
              </FormActions>
            </>
          )}
        />
      </div>
    </>
  );
};

export default OnboardingGovEmailVerify;
