// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import InputWrapper from '../InputWrapper';

// Styles
import './input-textarea.scss';

/**
 * @visibleName Textarea
 */
const InputTextarea = ({ className, ...props }) => (
  <InputWrapper
    {...props}
    as="textarea"
    className={classnames('input-textarea', className)}
  />
);

InputTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelPosition: PropTypes.string.isRequired,
};

InputTextarea.defaultProps = {
  labelPosition: 'above',
};

export default InputTextarea;
