// @flow

import { REQUEST_TYPES } from '.';
import { isDevelopmentEnvironment } from '../lib/helpers';
import getDeviceDetails from '../lib/helpers/getDeviceDetails';

export const loginRequest = (
  { email, password }: LoginRequestType,
  url: string
): RequestOptions => {
  const body = {
    input: [
      {
        name: 'IDToken0',
        value: getDeviceDetails(),
      },
    ],
  };
  return {
    type: `[API] Login`,
    location: '',
    url,
    APIMethod: REQUEST_TYPES.POST,
    headers: {
      'X-OpenAM-Username': email,
      'X-OpenAM-Password': password,
      'Accept-API-Version': 'resource=2.0',
    },
    body,
  };
};

export const loginMFARequest = (
  { body }: Object,
  url: string
): RequestOptions => ({
  type: `[API] Login MFA Request`,
  location: '',
  url,
  APIMethod: REQUEST_TYPES.POST,
  headers: {
    'Accept-API-Version': 'resource=2.0',
  },
  body,
});

export const loginSuccessRequest = (url: string) => {
  if (isDevelopmentEnvironment())
    url = url.substring(url.indexOf('/agent/custom-login-response'));
  return {
    type: `[API] Login Success Request`,
    location: '',
    url,
    APIMethod: REQUEST_TYPES.GET,
  };
};
