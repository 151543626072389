import { getAppDataBySlug, getAgencyCodeBySlug } from '../../lib/helpers';

export const selectAgencyLink = ({ agency: { link } }) => link;

//isGovUser should be changed to isAgencyUser
export const isGovUser = ({ agency: { links } }) => {
  const userKey = Object.values(links).find(userKey => {
    return userKey.profileName === 'Government Employee';
  });
  if (userKey) {
    return true;
  }

  return false;
};

export const selectAgencyByRouteParam = (
  { agency: { agencyIds, agencies } },
  {
    match: {
      params: { agencySlug },
    },
  }
) => {
  if (!agencyIds.length || !agencySlug) return {};

  const agency = {
    ...Object.values(agencies).find(
      agency => agency.agencyCode === getAgencyCodeBySlug(agencySlug)
    ),
  };

  if (!agency.agencyId) return false;

  return agency;
};

export const selectAppByRouteParam = (
  { agency: { apps, links } },
  { match, location }
) => {
  const pathAsArray = location.pathname.split('/');
  const appSlug = match.params.appSlug || pathAsArray[pathAsArray.length - 1];
  const appData = getAppDataBySlug(appSlug);

  if (!(appData || {}).appCode) return false;

  const app = {
    ...Object.values(apps).find(app => app.appCode === appData.appCode),
  };
  if (!app.appId) return false;

  const { linkUserKeys } = app;
  if (linkUserKeys)
    app.links = linkUserKeys.map(userKey => ({ ...links[userKey] }));

  return app;
};

export const selectAgencyByAppRouteParam = (
  { agency: { apps, links, agencies } },
  { match, location }
) => {
  const pathAsArray = location.pathname.split('/');
  const appSlug = match.params.appSlug || pathAsArray[pathAsArray.length - 1];
  const appData = getAppDataBySlug(appSlug);
  if (!(appData || {}).appCode) return null;
  const agencySlug = appData.agencySlug;
  const agency = {
    ...Object.values(agencies).find(agency => agency.agencySlug === agencySlug),
  };

  if (!agency) return null;

  const { appIds, linkUserKeys } = agency;

  if (appIds) agency.apps = appIds.map(appId => ({ ...apps[appId] }));
  if (linkUserKeys)
    agency.links = linkUserKeys.map(userKey => ({ ...links[userKey] }));

  return agency;
};

export const selectAllLinksAsArray = ({ agency: { agencies, apps, links } }) =>
  Object.values(links).map(link => ({
    ...link,
    agency: { ...agencies[link.agencyId] },
    app: { ...apps[link.appId] },
  }));

export const selectLinkedAgencies = ({ agency: { agencies, links } }) =>
  Object.values(agencies)
    .filter(agency => agency.linkUserKeys && agency.linkUserKeys.length > 0)
    .map(agency => ({ ...agency }));

//    export const selectCitizenLinkedAgencies = ({ agency: { agencies } }) =>
// Object.values(agencies)
// .filter(agency => (agency.links.profileName === 'Multiple Licenses') && agency.linkUserKeys && agency.linkUserKeys.length > 0)
// .map(agency => ({ ...agency }));

export const selectEmployeeLinkedAgencies = ({ agency: { agencies } }) =>
  Object.values(agencies)
    .filter(agency => agency.linkUserKeys && agency.linkUserKeys.length > 0)
    .map(agency => ({ ...agency }));

export const selectAllAgencyIds = ({ agency: { agencyIds } }) =>
  agencyIds || [];

export const selectAllAgencyApps = ({ agency: { apps } }) => apps || {};

export const selectAllAgencyLinks = ({ agency: { links } }) => links || {};

export const selectAllAgenciesAsArray = ({ agency: { agencies, apps } }) =>
  Object.values(agencies).map(agency => ({
    ...agency,
    apps: agency.appIds ? agency.appIds.map(appId => ({ ...apps[appId] })) : [],
  }));

export const selectAgencyBySlug = (
  { agency: { agencyIds, agencies } },
  { agencySlug }
) => {
  if (!agencyIds.length) return {};

  const agency = {
    ...Object.values(agencies).find(
      agency => agency.agencyCode === getAgencyCodeBySlug(agencySlug)
    ),
  };

  if (!agency.agencyId) return false;

  return agency;
};
