// Vendors
import React from 'react';
import PropTypes from 'prop-types';

import { Link, NavLink } from 'react-router-dom';
import classnames from 'classnames';

// Components
import { Logo } from '../..';

// Styles
import './navbar-branding.scss';

const NavbarBranding = ({ as = 'Link', className, ...props }) => {
  const tags = { Link, NavLink };
  const T = tags[as] || as;

  return (
    <T {...props} className={classnames('navbar-branding', className)}>
      <Logo />
    </T>
  );
};

NavbarBranding.propTypes = {
  as: PropTypes.string.isRequired,
  className: PropTypes.string,
  to: PropTypes.string,
};

export default NavbarBranding;
