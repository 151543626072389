// Vendor
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { formikInjectedPropsTypes } from '../../../lib/validation/propTypes/formikPropTypes';

import classnames from 'classnames';

// Components
import { Trans, CreditCardIcon, InputZipCode } from '../..';

// Helpers
import { paymentAccountsData } from '../../../lib/data';
import { publicURL } from '../../../lib/helpers';
//import { InputZipCode } from '../../common';

const PaymentCard = ({
  className,
  paymentAccount: { paymentAccountType, cardNumber, expirationDate },
  showCvv,
  formikProps,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const onHover = () => {
    setPopoverOpen(true);
  };

  const onHoverLeave = () => {
    setPopoverOpen(false);
  };

  return (
    <>
      <div className={classnames('payment-card', className)}>
        <CreditCardIcon
          name={
            paymentAccountType && paymentAccountsData[paymentAccountType]
              ? paymentAccountsData[paymentAccountType].icon
              : ''
          }
        />
        <div className="payment-card-info">
          <div className="payment-card-column">
            <span className="payment-card-name">
              {paymentAccountType && paymentAccountsData[paymentAccountType] ? (
                <Trans file="Payment" id={paymentAccountType} />
              ) : (
                ''
              )}
            </span>
            <div className="payment-card-number">
              *&nbsp;*&nbsp;*&nbsp;&nbsp;{cardNumber}
            </div>
          </div>

          <div className="flex-spacer" />
          <span className="payment-card-expiration">{expirationDate}</span>
        </div>
      </div>
      {showCvv && (
        <>
          <InputZipCode
            className="payment-card-security-code"
            name="cvv"
            label={<Trans file="Payment" id="SecurityCode" />}
            labelPosition="above"
            formikProps={formikProps}
            required={true}
            maxlength="4"
          />
          <a onMouseEnter={onHover} onMouseLeave={onHoverLeave}>
            <Trans file="Payment" id="WhatIsThis" />
          </a>
          {popoverOpen && (
            <div className="popover top">
              <div className="arrow"></div>
              <div className="popover-content">
                <div>
                  <img
                    src={publicURL('visaMastercardDiscover.png')}
                    className="popover-img pull-left"
                    height="175"
                    width="256"
                  />
                  <img
                    src={publicURL('americanExpress.png')}
                    className="popover-img pull-left"
                    height="175"
                    width="254"
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

PaymentCard.propTypes = {
  className: PropTypes.string,
  paymentAccount: PropTypes.shape({
    paymentAccountType: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    expirationDate: PropTypes.string.isRequired,
  }),
  showCvv: PropTypes.bool.isRequired,
  formikProps: formikInjectedPropsTypes.isRequired,
};

export default PaymentCard;
