// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { Trans, InputCountry, InputGroup, InputText } from '../../..';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Foreign Address
 */
const InputGroupForeignAddress = ({
  className,
  disabled,
  fieldGroupPath,
  formikProps,
  labelPosition,
  ...props
}) => {
  fieldGroupPath = fieldGroupPath ? `${fieldGroupPath}.` : '';

  return (
    <InputGroup
      {...props}
      className={classnames('input-group-address', className)}
    >
      <InputText
        className="input-street"
        name={`${fieldGroupPath}foreignAddress.streetAddress`}
        label={
          <Trans file="Labels" id="StreetAddress" fallback="Street Address" />
        }
        labelPosition={labelPosition}
        maxLength="30"
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputText
        className="input-unit"
        name={`${fieldGroupPath}foreignAddress.addressLine2`}
        label={
          labelPosition === 'above' ? (
            ''
          ) : (
            <Trans file="Labels" id="Unit" fallback="Apartment, suite, etc." />
          )
        }
        labelPosition={labelPosition}
        maxLength="30"
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputText
        className="input-unit"
        name={`${fieldGroupPath}foreignAddress.addressLine3`}
        label={
          labelPosition === 'above' ? (
            ''
          ) : (
            <Trans file="Labels" id="Address3" fallback="Address Line 3" />
          )
        }
        labelPosition={labelPosition}
        maxLength="30"
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputText
        className="input-city"
        name={`${fieldGroupPath}foreignAddress.city`}
        label={<Trans file="Labels" id="City" fallback="City" />}
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputText
        className="input-state"
        name={`${fieldGroupPath}foreignAddress.state`}
        label={<Trans file="Labels" id="State" fallback="State" />}
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputText
        name={`${fieldGroupPath}foreignAddress.postalCode`}
        label={<Trans file="Labels" id="Zip" fallback="Zip Code" />}
        className="input-zip"
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
      />
      <InputCountry
        name={`${fieldGroupPath}foreignAddress.country`}
        className="input-country"
        label={<Trans file="Labels" id="Country" fallback="Country" />}
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
      />
    </InputGroup>
  );
};

InputGroupForeignAddress.defaultProps = {
  label: (
    <Trans
      file="Labels"
      id="ForeignAddressInput"
      fallback="Full address including street, city, state/province, postal code (if applicable), and country."
    />
  ),
  name: 'foreignAddress',
  labelPosition: 'above',
};

InputGroupForeignAddress.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  fieldGroupPath: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
  label: PropTypes.node.isRequired,
  labelPosition: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  statesOptions: PropTypes.object,
};

export default InputGroupForeignAddress;
