// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';

// Actions
import { setAgencyStrongPassword } from '../../../reducers/user/updatePassword/updatePasswordActions';

// Components
import { Form, InputGroupUpdatePassword, Trans } from '../../../components';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';

// Helpers
import { strongPasswordUpdateValidationSchema } from '../../../lib/validation';

const LinkServiceSetStrongPasswordForm = ({
  userEmail,
  setAgencyStrongPassword,
  handleSuccess,
  goBack,
}) => (
  // const { goBack } = this.props;
  <Form
    formikConfig={{
      initialValues: {
        userEmail: userEmail || '',
        currentPassword: undefined,
        newPassword: undefined,
        confirmNewPassword: undefined,
      },
      onSubmit: (values, formikActions) => {
        return setAgencyStrongPassword({
          values,
          formikActions,
          props: { handleSuccess },
        });
      },
      validationSchema: strongPasswordUpdateValidationSchema,
    }}
    render={({ FormError, FormActions, FormActionsItem, formikProps }) => (
      <>
        <InputGroupUpdatePassword formikProps={formikProps} />
        <FormError />
        <FormActions>
          <FormActionsItem>
            <ButtonPrimary
              type="submit"
              isLoading={formikProps.isSubmitting}
              loadingContent={
                <Trans file="Labels" id="Saving" fallback="Saving" />
              }
            >
              <Trans file="Labels" id="Save" fallback="Save" />
            </ButtonPrimary>
          </FormActionsItem>
          <FormActionsItem>
            <Button onClick={goBack} disabled={formikProps.isSubmitting}>
              <Trans file="Labels" id="Back" fallback="Back" />
            </Button>
          </FormActionsItem>
        </FormActions>
      </>
    )}
  />
);

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setAgencyStrongPassword, goBack }, dispatch);
const mapStateToProps = ({ user: { userEmail } }) => ({ userEmail });

LinkServiceSetStrongPasswordForm.propTypes = {
  userEmail: PropTypes.string.isRequired,
  setAgencyStrongPassword: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkServiceSetStrongPasswordForm);
