// Vendors
import React from 'react';

// Components
import { Modal } from '..';
import { ReactComponent as LockoutSVG } from '../../../../assets/lockout.svg';
import Trans from '../../Trans/Trans';

// Styles
import './lockout-modal.scss';

const LockoutModal = () => (
  <Modal name="LockoutModal" className="modal-sm lockout-modal">
    <h2 className="h1 modal-title">
      <Trans
        file="Modals"
        id="AccountTemporarilyLocked"
        fallback="Account temporarily locked"
      />
    </h2>
    <div className="modal-content">
      <div className="modal-graphic">
        <LockoutSVG role="presentation" />
      </div>
      <p>
        <Trans
          file="Modals"
          id="FourHourLockout"
          fallback="There were too many attempts at verifying your credentials. To protect you personal information, we locked your account for four hours."
        />
      </p>
      <p>
        <Trans
          file="Modals"
          id="TryAgainLater"
          fallback="Please try again later."
        />
      </p>
    </div>
  </Modal>
);

export default LockoutModal;
