// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import {
  InputGroup,
  InputText,
  InputState,
  InputZipCode,
  InputCounty,
  InputCountry,
} from '../..';
import Trans from '../../../Trans/Trans';

// Helpers
import dlrStates from '../../../../../lib/data/dlrStates';
import statesWithProvinces from '../../../../../lib/data/statesWithProvinces';

import dlrCounties from '../../../../../lib/data/dlrCounties';
import countries from '../../../../../lib/data/countries';

// Styles
import './input-group-address-with-country.scss';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Address
 */
const InputGroupAddressWithCountry = ({
  fieldGroupPath,
  className,
  disabled,
  labelPosition,
  formikProps,
  hasLine2 = true,
  countyOptions,
  disableCountry,
  placeholders = {},
  id = '',
  ...props
}) => {
  fieldGroupPath = fieldGroupPath ? `${fieldGroupPath}.` : '';
  const filterOption = (candidate, input, options) => {
    const filterValues = options.filter(option => {
      if (option.label.toUpperCase().startsWith(input.toUpperCase()))
        return option.label;
    });
    const filterFinal = filterValues.filter(
      value => value.label === candidate.label
    );
    if (input && filterFinal.length > 0) return true;
    else if (!input) return true;
  };

  return (
    <InputGroup
      {...props}
      className={classnames('iinput-group-address-with-country', className)}
    >
      <InputText
        className="input-street"
        name={`${fieldGroupPath}streetAddress`}
        label={
          <Trans file="Labels" id="StreetAddress" fallback="Street Address" />
        }
        labelPosition={labelPosition}
        maxLength="280"
        disabled={disabled}
        formikProps={formikProps}
        placeholder={placeholders.streetAddress}
        id={id}
      />

      {hasLine2 && (
        <InputText
          className="input-unit"
          name={`${fieldGroupPath}addressLine2`}
          label={
            labelPosition === 'above' ? (
              ''
            ) : (
              <Trans file="Labels" id="Unit" fallback="Apt/Unit" />
            )
          }
          labelPosition={labelPosition}
          maxLength="280"
          disabled={disabled}
          formikProps={formikProps}
          placeholder={placeholders.addressLine2}
          id={id}
        />
      )}

      <InputText
        className="input-city"
        name={`${fieldGroupPath}city`}
        label={<Trans file="Labels" id="City" fallback="City" />}
        labelPosition={labelPosition}
        disabled={disabled}
        formikProps={formikProps}
        placeholder={placeholders.city}
      />
      <InputState
        className="input-state"
        name={`${fieldGroupPath}state`}
        label={<Trans file="Labels" id="State" fallback="State" />}
        labelPosition={labelPosition}
        options={id === 'MAIL' ? statesWithProvinces : dlrStates}
        disabled={disabled}
        formikProps={formikProps}
        filterOption={(...props) =>
          filterOption(
            ...props,
            id === 'MAIL' ? statesWithProvinces : dlrStates
          )
        }
      />
      {formikProps.values[`${fieldGroupPath}country`].code === 'USA' ? (
        <InputZipCode
          name={`${fieldGroupPath}zipFive`}
          className="input-zip"
          label={<Trans file="Labels" id="Zip" fallback="Zip Code" />}
          labelPosition={labelPosition}
          disabled={disabled}
          formikProps={formikProps}
          placeholder={placeholders.zipFive}
        />
      ) : (
        <InputText
          name={`${fieldGroupPath}zipFive`}
          className="input-zip"
          label={<Trans file="Labels" id="Zip" fallback="Zip Code" />}
          labelPosition={labelPosition}
          disabled={disabled}
          formikProps={formikProps}
          placeholder={placeholders.zipFive}
        />
      )}
      {formikProps.values[`${fieldGroupPath}country`].code === 'USA' ? (
        <InputCounty
          name={`${fieldGroupPath}county`}
          label={<Trans file="Labels" id="County" fallback="County" />}
          labelPosition={labelPosition}
          options={dlrCounties}
          disabled={disabled}
          formikProps={formikProps}
          placeholder={placeholders.county}
        />
      ) : null}
      <InputCountry
        name={`${fieldGroupPath}country`}
        className="input-country"
        label={<Trans file="Labels" id="Country" fallback="Country" />}
        labelPosition={labelPosition}
        disabled={disableCountry}
        formikProps={formikProps}
        options={countries}
        placeholder={placeholders.country}
      />
    </InputGroup>
  );
};

InputGroupAddressWithCountry.propTypes = {
  className: PropTypes.string,
  labelPosition: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  fieldGroupPath: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
  hasLine2: PropTypes.bool,
  statesOptions: PropTypes.object,
  countyOptions: PropTypes.object,
  disableCountry: PropTypes.bool,
  placeholders: PropTypes.object,
  id: PropTypes.string.isRequired,
};

export default InputGroupAddressWithCountry;
