// Consts
export const MOBILE_NAV_TOGGLE = '[MOBILE NAV] Toggle';

export const toggleMobileNav = open => (dispatch, getState) => {
  const { mobileNav } = getState();
  const payload = typeof open === 'boolean' ? open : !mobileNav.isActive;
  const bodyClassList = document.body.classList;

  if (payload) bodyClassList.add('mobileNavActive');
  else bodyClassList.remove('mobileNavActive');

  return dispatch({ type: MOBILE_NAV_TOGGLE, payload });
};
