// @flow

import { REQUEST_TYPES } from '.';

export const getUserResetOptionsRequest = (
  userEmail?: string
): RequestOptions => ({
  type: `[API] Password Reset Get Options`,
  url: `/user/ui/pwd/reset/opt/${userEmail || ''}`,
  APIMethod: REQUEST_TYPES.GET,
});

export const getResetSecurityQARequest = (
  userEmail?: string
): RequestOptions => ({
  type: `[API] Password Reset Get Security QAs`,
  url: `/user/ui/pwd/reset/qa/${userEmail || ''}`,
  APIMethod: REQUEST_TYPES.GET,
});

export const checkSecurityAnswersRequest = (
  body: checkSecurityAnswersRequestType
): RequestOptions => ({
  type: `[API] Password Reset Check Security Answers`,
  url: `/user/qa/check`,
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const passwordResetOTPRequest = ({
  body,
  headers,
}: passwordResetOTPRequestType): RequestOptions => ({
  type: `[API] Password Reset Verify OTP`,
  url: `/user/ui/pwd/reset/otp`,
  APIMethod: REQUEST_TYPES.POST,
  body,
  headers,
});

export const passwordResetQARequest = ({
  body,
}: passwordResetQARequestType): RequestOptions => ({
  type: `[API] Password Reset QA`,
  url: `/user/ui/pwd/reset/qa`,
  APIMethod: REQUEST_TYPES.POST,
  body,
});
