// @flow

import { REQUEST_TYPES } from '.';

export const usernameRecoveryTOASRequest = (
  body: usernameRecoveryTOASRequestType
): RequestOptions => {
  return {
    url: `/user/ui/forgot/username`,
    type: `[API] Username Recovery TOAS`,
    body,
    APIMethod: REQUEST_TYPES.POST,
  };
};
