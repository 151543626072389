import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const Languages = ['en', 'es'];

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      detection: {
        htmlTag: document.documentElement,
      },
      lang: 'en',
      defaultLanguage: 'en',
      fallbackLng: 'en',
      debug: false,
      whitelist: Languages,
      ns: [
        'SignIn',
        'Labels',
        'ComingSoon',
        'CreateAccount',
        'ForgotPassword',
        'ForgotUsername',
        'MFA',
        'Onboarding',
        'Footer',
        'Common',
        'Alerts',
        'TransactionHistory',
        'TransactionCodes',
        'Todo',
        'Cybersecurity',
        'Account',
        'SecurityQuestions',
        'Errors',
        'Dashboard',
        'LinkService',
        'Payment',
        'tdlr',
        'Modals',
        'NoMatch',
        'bon',
        'dmv',
        'dlr',
      ],
      defaultNS: 'SignIn',
      interpolation: {
        escapeValue: false,
      },
      preload: ['es', 'en'],

      backend: {
        loadPath: `${process.env.REACT_APP_LOCALES_URL}/{{lng}}/{{ns}}.json`,
        crossDomain: true,
      },
      react: {
        useSuspense: false, //   <---- this will do the magic
      },
    },
    (err, t) => {
      if (err) return console.error('something went wrong loading', err);
      t('key');
    }
  )
  .then(function(t) {
    t('Welcome');
  });
i18n.locale = 'en';
export default i18n;
