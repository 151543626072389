// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Dropdown } from '..';

// TexKit Components
import { Icon } from 'texkit/dist/components/Icon/Icon';
import { ButtonSuccessCircle } from 'texkit/dist/components/Buttons';

// Types
import { HeroActionPropType } from '../../../lib/validation/propTypes/heroPropTypes';

const HeroActionsMultiple = ({ actions }) => (
  <Dropdown
    id="hero-actions-dropdown"
    className="hero-actions-dropdown"
    type="button"
    toggleComponent={props => (
      <ButtonSuccessCircle {...props}>
        <Icon name="more" />
      </ButtonSuccessCircle>
    )}
    items={actions}
  />
);

HeroActionsMultiple.propTypes = {
  actions: PropTypes.arrayOf(HeroActionPropType).isRequired,
};

export default HeroActionsMultiple;
