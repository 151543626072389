// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputGroup, InputEmail, Trans } from '../../..';
import { Icon } from 'texkit/dist/components/Icon/Icon';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Confirm Email
 */
const InputGroupConfirmEmail = ({ className, hint, formikProps, ...props }) => (
  <InputGroup
    {...props}
    className={classnames('input-group-confirm-email', className)}
  >
    <InputEmail
      name="email"
      label={<Trans file="Labels" id="EnterEmail" fallback="Enter Email" />}
      formikProps={formikProps}
      hint={hint}
    />
    <InputEmail
      name="confirmEmail"
      label={<Trans file="Labels" id="ConfirmEmail" />}
      formikProps={formikProps}
      icons={
        formikProps.isValid ? (
          <Icon name="check" className="input-confirm-email-check" />
        ) : (
          ''
        )
      }
    />
  </InputGroup>
);

InputGroupConfirmEmail.propTypes = {
  className: PropTypes.string,
  hint: PropTypes.object,
  formikProps: formikInjectedPropsTypes.isRequired,
};

export default InputGroupConfirmEmail;
