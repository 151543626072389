// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles
import './service-icon-text.scss';

const ServiceIconText = ({ type, text, className }) => {
  if (!type) return null;

  return (
    <div className={classnames(`service-icon service-icon-${type}`, className)}>
      <div className="text-container">
        <div className="text">{text}</div>
      </div>
    </div>
  );
};

ServiceIconText.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default ServiceIconText;
