// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles
import './input-group.scss';

const InputGroup = ({ className, isGrid, children, ...props }) => (
  <div {...props} className={classnames('input-group', { isGrid }, className)}>
    {isGrid ? <div className="input-group-grid">{children}</div> : children}
  </div>
);

InputGroup.propTypes = {
  className: PropTypes.string,
  isGrid: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default InputGroup;
