// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import { InputText, Dropdown } from '../..';

// Texkit Components
import { Icon } from 'texkit/dist/components/Icon/Icon';

// Actions
import { toggleSearchForm } from '../../../reducers/navbar/navbarActions';

// Styles
import './navbar-search.scss';

class NavbarSearch extends Component<Props, State> {
  searchDropdownRef = {};
  searchInputTimeout = null;

  static defaultProps = {
    suggestions: [
      'View my driver license',
      'View my professional license',
      'Renew my driver license',
      'Renew my professional license',
      'View my vehicle registration',
      'View my boat registration',
      'Renew my vehicle registration',
      'Renew my boat registration',
      'Add a credit card to my account',
      'Delete a credit card from my account',
      'How many credit cards can I have on file?',
      'Paying with Paypal (available soon!)',
      'Password security requirements',
      'Modify my password',
      'Modify my username',
      'Modify my email',
      'Modify my current notifications',
      'Cancel my notifications',
      'Changing the frequency of my notifications',
      'Changing the channel of my notifications',
    ],
  };

  state = {
    searchInputValue: '',
  };

  componentWillUnmount() {
    this.toggleSearch(false);
  }

  toggleSearch(open) {
    const { toggleSearchForm } = this.props;
    toggleSearchForm(open);
    this.setState({ searchInputValue: '' }, () =>
      setTimeout(() => this.focusSearchInput(), 150)
    );
  }

  getSearchDropdownRef(ref) {
    if (ref && typeof ref.getInstance === 'function') {
      this.searchDropdownRef = ref.getInstance();
    }
  }

  focusSearchInput() {
    const searchInput = document.querySelector('.navbar-search-input .input');

    if (this.isActive && searchInput) {
      searchInput.focus();
    }
  }

  get isActive() {
    return this.props.navbar.searchActive;
  }

  handleOnFocus() {
    if (this.searchInputTimeout) clearTimeout(this.searchInputTimeout);
  }

  handleOnBlur() {
    if (!this.state.searchInputValue) {
      this.searchInputTimeout = setTimeout(() => {
        this.toggleSearch(false);
      });
    }
  }

  handleOnInputChange(event) {
    const eventTarget = event.target;

    this.setState(prevState => {
      const newSearchInputValueLength =
        prevState.searchInputValue.length +
        (eventTarget.value.length - prevState.searchInputValue.length);

      this.searchDropdownRef.toggleDropdown(
        newSearchInputValueLength > 2 ? true : false
      );

      return {
        searchInputValue: eventTarget.value,
      };
    });
  }

  handleOnKeyDown(event) {
    const keyUp = event.keyCode === 38;
    const keyDown = event.keyCode === 40;
    const currentElement = event.target;
    const searchForm = currentElement.closest('.navbar-search');
    const searchInput = searchForm.querySelector('.input');
    const dropdownMenuItems = [].slice.call(
      searchForm.querySelectorAll('.dropdown-menu-item-action')
    );
    const dropdownMenuItemsLength = dropdownMenuItems.length;
    const dropdownMenuItemIndex = dropdownMenuItems.indexOf(currentElement);
    const isSearchInput = currentElement === searchInput;
    const isDropdownItem = dropdownMenuItemIndex > -1;

    if (
      (isSearchInput || isDropdownItem) &&
      (keyUp || keyDown) &&
      dropdownMenuItemsLength
    ) {
      event.preventDefault();

      let newFocusItem = null;

      if (isSearchInput) {
        if (keyDown) {
          newFocusItem = dropdownMenuItems[0];
        } else {
          newFocusItem = dropdownMenuItems[dropdownMenuItemsLength - 1];
        }
      } else {
        newFocusItem = searchInput;

        if (keyDown) {
          if (dropdownMenuItemIndex < dropdownMenuItemsLength - 1) {
            newFocusItem = dropdownMenuItems[dropdownMenuItemIndex + 1];
          }
        } else {
          if (dropdownMenuItemIndex > 0) {
            newFocusItem = dropdownMenuItems[dropdownMenuItemIndex - 1];
          }
        }
      }

      if (newFocusItem) {
        newFocusItem.focus();
        searchInput.value =
          newFocusItem === searchInput
            ? this.state.searchInputValue
            : newFocusItem.textContent;
      }
    }
  }

  filterSuggestions(suggestions, query) {
    if (query.length < 3) return [];

    const filteredSuggestions = suggestions.reduce((acc, curr) => {
      const regex = new RegExp(query, 'gi');
      const match = curr.match(regex);

      if (match) {
        const highlightedText = curr.replace(
          regex,
          str => `<strong>${str}</strong>`
        );
        acc.push({ highlightedText, originalText: curr });
      }
      return acc;
    }, []);

    if (!filteredSuggestions.length) {
      return [
        {
          as: 'span',
          label: 'There are no results that match your query.',
          className: 'navbar-search-dropdown-no-results',
        },
      ];
    }

    return filteredSuggestions.map(item => {
      return {
        as: 'Link',
        label: item.highlightedText,
        to: '/help',
        // TODO: Replace with action that updates our Redux state
        onClick: () => this.setState({ searchInputValue: item.originalText }),
      };
    });
  }

  render() {
    const { suggestions } = this.props;
    const { searchInputValue } = this.state;
    const filteredSuggestions = this.filterSuggestions(
      suggestions,
      searchInputValue
    );

    return (
      <div
        className="navbar-search"
        onKeyDown={this.handleOnKeyDown.bind(this)}
        onFocus={this.handleOnFocus.bind(this)}
        onBlur={this.handleOnBlur.bind(this)}
      >
        <button
          className="navbar-search-toggle"
          onClick={this.toggleSearch.bind(this)}
          aria-label="Search"
        >
          <Icon name="search" />
        </button>
        <>
          <div className="navbar-search-form-wrapper">
            <form className="navbar-search-form">
              <InputText
                className="navbar-search-input"
                placeholder="Search our FAQs"
                value={this.state.searchInputValue}
                onChange={this.handleOnInputChange.bind(this)}
                icons={
                  <span className="input-icon input-icon-search">
                    <Icon name="search" />
                  </span>
                }
              />
              <button
                type="button"
                className="navbar-search-close"
                onClick={this.toggleSearch.bind(this)}
              >
                <Icon name="x" />
              </button>
            </form>
          </div>
          <Dropdown
            ref={this.getSearchDropdownRef.bind(this)}
            id="navbar-search-dropdown"
            className="navbar-search-dropdown"
            items={filteredSuggestions}
            outsideClickIgnoreClass={'navbar-search'}
            disableOnBlur={true}
          />
        </>
      </div>
    );
  }
}

const mapStateToProps = ({ navbar }) => {
  return { navbar };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators({ toggleSearchForm }, dispatch);

NavbarSearch.propTypes = {
  navbar: PropTypes.object.isRequired,
  toggleSearchForm: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavbarSearch);
