// Vendors
import React from 'react';

// Components
import { Modal } from '../../..';
import { ReactComponent as LockoutSVG } from '../../../../../../assets/lockout.svg';
import Trans from '../../../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Styles
import '../dlr-FAQ-modal.scss';

const DLR_USER_LOCKOUT_MODAL = () => (
  <Modal
    name="DLR_USER_LOCKOUT_MODAL"
    disableCloseButton={true}
    disableOnClickOutside={true}
  >
    <h2 className="h1 modal-title">
      <Trans
        file="dlr"
        id="dlr_User_Account_Locked"
        fallback="Your TxT account is temporarily locked"
      />
    </h2>
    <div className="modal-content">
      <div className="modal-graphic">
        <LockoutSVG role="presentation" />
      </div>
      <p>
        <Trans
          file="dlr"
          id="dlr_User_Account_Locked_Desc"
          fallback="To protect your personal information, your account has been locked for four hours because of too many unsuccessful log in attempts."
        />
      </p>
    </div>
    <ButtonPrimary className="modal-close-button " as="Link" to="/">
      <Trans file="Labels" id="ViewMyDashboard" />
    </ButtonPrimary>
  </Modal>
);

export default DLR_USER_LOCKOUT_MODAL;
