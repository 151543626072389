// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Components
import { Form, Modal, Trans, InputText } from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

//Helpers
import { addressLine2ValidationSchema } from '../../../../lib/validation/schemas/common';

// Styles
import '../VerifyAddressModal/verify-address-modal.scss';
import { states, counties } from '../../../../lib/data/agencyData/BON';

function AddressTwoMissingModal(props) {
  const {
    onSuccess,
    closeModal,
    id = 'AddressTwoMissingModal',
    modal: { data },
    ...rest
  } = props;

  if (!data || !data.addressOptions) return false;

  const handleSubmit = values => {
    closeModal(id);
    onSuccess({
      ...data.enteredAddress,
      state:
        states.find(state => state.value === data.enteredAddress.state) || null,
      county:
        counties.find(county => county.label === data.enteredAddress.county) ||
        null,
      addressLine2: values.addressLine2,
    });
  };

  return (
    <Modal
      {...rest}
      id={id}
      disableOnClickOutside={true}
      className="verify-address-modal"
    >
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="AddressTwoMissingTitle" />
      </h2>
      <div className="modal-content">
        <div>
          <h3 style={{ marginTop: 0 }}>
            <Trans file="Modals" id="AddressTwoMissingBody" />
          </h3>
          <h4>
            {data.enteredAddress.streetAddress} <br />
            {data.enteredAddress.city},{''}
            {data.enteredAddress.state} {data.enteredAddress.zipFive}{' '}
            {data.enteredAddress.county}
          </h4>
        </div>
        <Form
          style={{ minWidth: 400 }}
          formikConfig={{
            initialValues: {
              addressLine2: '',
            },
            validationSchema: addressLine2ValidationSchema,
            onSubmit: handleSubmit,
          }}
          render={({ FormActions, FormActionsItem, formikProps }) => (
            <>
              <InputText
                className="address-line-2"
                name="addressLine2"
                maxLength="32"
                formikProps={formikProps}
                placeholder="Enter an apt, suite, building #, etc."
              />
              <FormActions align="center">
                <FormActionsItem>
                  <ButtonPrimary type="submit">
                    <Trans file="Labels" id="Confirm" />
                  </ButtonPrimary>
                </FormActionsItem>
              </FormActions>
            </>
          )}
        />
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ modal }) => ({ modal });
const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeModal }, dispatch);

AddressTwoMissingModal.propTypes = {
  id: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressTwoMissingModal);
