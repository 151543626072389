// Vendors
import React from 'react';
import { useSelector } from 'react-redux';

// Graphics
import { ReactComponent as ServerErrorSvg } from '../../../../assets/server-error.svg';

// Components
import { Modal, Trans } from '../..';

// Helpers
import { selectModalData } from '../../../../reducers/modal/modalSelectors';

// Styles
import './server-error-modal.scss';

const ServerErrorModal = () => {
  const errorReference = (useSelector(selectModalData) || {})['REF'];
  return (
    <Modal name="ServerErrorModal" className="modal-sm server-error-modal">
      <h2 className="h1 modal-title">
        <Trans
          file="Errors"
          id="ServerErrorModal_Title"
          fallabck="Houston, we have a problem."
        />
      </h2>
      <div className="modal-content">
        <div className="modal-graphic">
          <ServerErrorSvg role="presentation" />
        </div>
        <p>
          <Trans
            file="Errors"
            id="ServerErrorModal_Content"
            fallback="services are unavailable at this time. Please try again after"
          />
        </p>
        {errorReference && (
          <p>
            <Trans
              file="Labels"
              id="ErrorReference"
              fallback="Error Reference: "
            />{' '}
            {errorReference}
          </p>
        )}
      </div>
    </Modal>
  );
};

export default ServerErrorModal;
