// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

// Components
import { Modal, Trans } from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { FormActions, FormActionsItem } from '../../Forms';

// Helpers

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './vehicle-not-found-modal.scss';

const VehicleNotFoundModal = () => {
  const dispatch = useDispatch();
  return (
    <Modal
      name="VehicleNotFoundModal"
      className="vehicle-not-found-modal"
      disableOnClickOutside
      disableCloseButton
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Errors"
          id="VehicleNotFoundModal_Title"
          fallback="We couldn’t find your vehicle."
        />
      </h2>
      <div className="modal-content">
        <Trans
          file="Errors"
          id="VehicleNotFoundModal_Content"
          fallback="We unlinked the Texas Department of Motor Vehicles (TxDMV) from your account. You need to link the agency again to add vehicles to your TxT dashboard."
        />
        <FormActions align="center">
          <FormActionsItem>
            <ButtonPrimary
              onClick={() => {
                dispatch(closeModal('VehicleNotFoundModal'));
                dispatch(replace('/dashboard'));
              }}
            >
              <Trans
                file="Labels"
                id="ViewMyDashboard"
                fallback="Return to TxT dashboard"
              />
            </ButtonPrimary>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
};

export default VehicleNotFoundModal;
