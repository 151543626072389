//@flow

// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import { Trans } from '../../../../../../components';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

const DMVRRCanNotRenewOnline = () => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="takeover-page-header">
        <div>
          <Trans file="dmv" id="AgencyName" />
        </div>
      </div>
      <p className="h2">
        <Trans
          file="dmv"
          id="dmvrr_CanNotRenewOnline_Title"
          fallback="You can’t renew this vehicle online"
        />
      </p>
      <p>
        <Trans
          file="dmv"
          id="dmvrr_CanNotRenewOnline_p1"
          fallback="You’ll need to contact the Texas Department of Motor Vehicles (TxDMV) or go to a <a>TxDMV Regional Service Center or a County Tax Office</a> before continuing the renewal."
        />
      </p>
      <p>
        <Trans
          file="dmv"
          id="dmvrr_CanNotRenewOnline_p2"
          fallback="Call TxDMV: <a>+1 (888) 368-4689</a>"
        />
      </p>

      <ButtonPrimary
        className="dmv-inspection-cant-confirm"
        onClick={() => dispatch(push('/dashboard'))}
      >
        <Trans
          file="Labels"
          id="ReturnToDashboard"
          fallback="Return to TxT Dashboard"
        />
      </ButtonPrimary>
    </>
  );
};
export default DMVRRCanNotRenewOnline;
