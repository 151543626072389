import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function useHasExistingContact(contactType) {
  const [hasContact, setHasContact] = useState(null);
  const { contacts } = useSelector(state => state.user);

  useEffect(() => {
    setHasContact(!!(contacts && contacts[contactType]));
  }, []);

  return hasContact;
}
