// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import InputSelect from '../InputSelect/InputSelect';
import Trans from '../../../Trans/Trans';

// Data
import { states } from '../../../../../lib/data';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Select State
 */
const InputState = ({ className, ...props }) => (
  <InputSelect
    {...props}
    optionLabelKey="label"
    optionValueKey="value"
    emptyOptionLabel={<Trans file="Labels" id="SelectAState" />} //"Select a state..."
    isSearchable={true}
    className={classnames('input-state', className)}
  />
);

InputState.propTypes = {
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
  name: PropTypes.string,
};

InputState.defaultProps = {
  label: 'State',
  name: 'state',
  options: states,
};

export default InputState;
