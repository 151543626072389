// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import InputCheckbox from './InputCheckbox';
/**
 * @visibleName Inverse Checkbox
 */
const InputCheckboxInverse = ({ className, ...props }) => (
  <InputCheckbox
    {...props}
    className={classnames('input-checkbox-inverse', className)}
  />
);

InputCheckboxInverse.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default InputCheckboxInverse;
