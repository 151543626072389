// Vendors
import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { submitPaymentFromForm } from '../../../../reducers/payment/paymentActions';
import { goBack } from 'connected-react-router';

// Components
import { InputCheckbox, Trans, FormActions, FormActionsItem } from '../../..';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';

// Helpers
import { PaymentContext } from '../../Payment';
import { clearAlertInfo } from '../../../../reducers/alert/alertActions';
import { selectTotalFee } from '../../../../reducers/agency/dlr/dlrSelectors';

// Styles
import './reviewAndPay.scss';

const ReviewAndPay = () => {
  const paymentContext = useContext(PaymentContext);
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState();
  const [savePaymentAccount, setSavePaymentAccount] = useState(false);
  const { walletStatus } = useSelector(state => state.payment);
  const totalFee = useSelector(selectTotalFee);
  const handleSubmit = () => {
    dispatch(clearAlertInfo());
    setSubmitting(true);
    dispatch(
      submitPaymentFromForm(paymentContext, { savePaymentAccount })
    ).finally(() => setSubmitting(false));
  };

  const onCancel = () => {
    dispatch(clearAlertInfo());
    dispatch(goBack());
  };

  return (
    <>
      <h2 className="payment-review-subtitle">
        <Trans file="Payment" id={'SaveCardInfo'} />
      </h2>
      {walletStatus === 'SUCCESS' && (
        <InputCheckbox
          name="savePaymentAccount"
          label={<Trans file="Labels" id="SaveCardYes" />}
          checked={savePaymentAccount}
          onChange={event => setSavePaymentAccount(!!event.target.checked)}
        />
      )}
      {totalFee ? (
        <div className="feeTotalWrapper">
          <div className="feeTotal flex-row-wrap">
            <div className="flex-item">
              <Trans file="Labels" id="total" />
            </div>
            <div className="value flex-item">${totalFee}</div>
          </div>
        </div>
      ) : null}
      <FormActions>
        <FormActionsItem>
          <ButtonPrimary
            onClick={handleSubmit}
            isLoading={submitting}
            loadingTrans={
              <Trans file="Payment" id="Paying" fallback="Paying" />
            }
          >
            <Trans file="Payment" id="PayNow" fallback="Pay Now" />
          </ButtonPrimary>
        </FormActionsItem>
        <FormActionsItem>
          <Button onClick={onCancel} disabled={submitting}>
            <Trans file="Labels" id="Back" fallback="Back" />
          </Button>
        </FormActionsItem>
      </FormActions>
    </>
  );
};

export default ReviewAndPay;
