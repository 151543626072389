// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputWrapper, Trans } from '../../..';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Email
 */
const InputEmail = ({ className, hint, ...props }) => {
  return (
    <InputWrapper
      {...props}
      type="email"
      className={classnames('input-email', className)}
      hint={hint}
    />
  );
};

InputEmail.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  hint: PropTypes.object,
  formikProps: formikInjectedPropsTypes.isRequired,
};

InputEmail.defaultProps = {
  label: <Trans file="Labels" id="Email" fallback="Email" />,
  name: 'email',
  autoComplete: 'off',
};

export default InputEmail;
