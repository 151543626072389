//@flow

// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack, push } from 'connected-react-router';

// Actions

// Components
import { FormActions, Trans, Loading } from '../../../components';
import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';

// Helpers
import { selectAgencyLink } from '../../../reducers/agency/agencySelectors';
import useRedirect from '../../../hooks/useRedirect';

const LinkServiceInegible = () => {
  const link = useSelector(selectAgencyLink);
  useRedirect(!link, '/link');
  const dispatch = useDispatch();
  if (!link) return <Loading />;

  const { app, agency } = link;

  return (
    <>
      <h1 className="h2">
        <Trans
          file={agency.agencySlug}
          id={`${app.appSlug}_LinkService_InegibleRegistration`}
        />
      </h1>
      <p>
        <Trans
          file={agency.agencySlug}
          id={`${app.appSlug}_LinkService_DescriptionInegibleRegistration`}
        />
      </p>

      <FormActions>
        <div className="form-actions-item service-inegible-link-another">
          <ButtonPrimary onClick={() => dispatch(push('/dashboard'))}>
            <Trans file="Labels" id="ViewMyDashboard" />
          </ButtonPrimary>
        </div>
        <div className="form-actions-item service-inegible-link-another">
          <Button className="Button" onClick={() => dispatch(goBack())}>
            <Trans
              file={agency.agencySlug}
              id={`${app.appSlug}_Label_LinkAnother`}
            />
          </Button>
        </div>
      </FormActions>
    </>
  );
};

export default LinkServiceInegible;
