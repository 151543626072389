// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, NavLink, withRouter } from 'react-router-dom';

// Components
import MobileNavToggle from './MobileNavToggle';
import { Container, Logo, Trans } from '..';
import { Icon } from 'texkit/dist/components/Icon/Icon';

// Actions
import { signOut } from '../../reducers/auth/authActions';
import { toggleMobileNav } from '../../reducers/mobileNav/mobileNavActions';

import LanguageSwitcher from '../Navbar/NavbarLanguageSwitcher/LanguageSwitcher';
import { getServiceMenuItems } from '../Navbar/NavbarServiceMenu/NavbarServiceMenu';

// Styles
import './mobile-nav.scss';

class MobileNav extends Component<Props, State> {
  state = {
    windowScrollY: 0,
  };

  primaryMenuItems = [
    {
      label: <Trans file="Labels" id="Home" fallback="Home" />,
      url: '/dashboard',
    },
  ];

  primary2MenuItems = [
    {
      label: (
        <Trans file="Labels" id="AccountSettings" fallback="Account Settings" />
      ),
      url: '/account',
    },
    {
      label: <Trans file="Labels" id="History" fallback="History" />,
      url: '/history/transactions',
    },
  ];

  signoutMenuItems = [
    {
      as: 'button',
      label: <Trans file="Labels" id="SignOut" fallback="Sign Out" />,
      onClick: () => {
        this.props.signOut();
        this.props.toggleMobileNav(false);
      },
    },
  ];

  open() {
    if (document.defaultView)
      this.setState({ windowScrollY: document.defaultView.scrollY });
    this.props.toggleMobileNav(true);
  }

  close() {
    this.props.toggleMobileNav(false);
    if (document.defaultView) {
      setTimeout(() => {
        window.scrollTo(0, this.state.windowScrollY);
      }, 5);
    }
  }

  handleToggleClick() {
    const { mobileNav } = this.props;
    return mobileNav.isActive ? this.close() : this.open();
  }

  handleNavItemClick() {
    setTimeout(() => {
      this.props.toggleMobileNav(false);
    }, 10);
  }

  render() {
    const { mobileNav, agency } = this.props;
    const currentPath = this.props.location.pathname;

    const menuItemsServices = getServiceMenuItems(agency);

    return (
      <>
        <nav id="mobile-nav" className="mobile-nav" role="navigation">
          <div className="mobile-nav__top-level">
            <Link
              to="/dashboard"
              className="mobile-nav-branding"
              onClick={this.handleNavItemClick.bind(this)}
              aria-label="View My Dashboard"
            >
              <Logo />
            </Link>
            <MobileNavToggle
              mobileNav={mobileNav}
              onClick={this.handleToggleClick.bind(this)}
            />
          </div>

          <>
            <Container className="mobile-nav-container">
              <ul className="mobile-nav-primary-menu">
                {this.primaryMenuItems.map((item, i) => (
                  <li
                    key={`mobile-nav-primary-menu-item-${i}`}
                    className={
                      'mobile-nav-primary-menu-item' +
                      (item.url && item.url === currentPath
                        ? ' current-selected'
                        : '')
                    }
                  >
                    {item.url && item.url === currentPath && (
                      <Icon className="current-star" name="lone-star" />
                    )}
                    <NavLink
                      to={item.url}
                      onClick={this.handleNavItemClick.bind(this)}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                ))}
              </ul>

              <ul className="mobile-nav-services-menu">
                {menuItemsServices.map((item, i) => (
                  <li
                    key={`mobile-nav-services-menu-item-${i}`}
                    className={
                      'mobile-nav-services-menu-item' +
                      (item.path && item.path === currentPath
                        ? ' current-selected'
                        : '')
                    }
                  >
                    {item.path && item.path === currentPath && (
                      <Icon className="current-star" name="lone-star" />
                    )}
                    <Link
                      className="nav-dropdown-menu-service-title"
                      to={item.path}
                      onClick={this.handleNavItemClick.bind(this)}
                    >
                      {item.service}
                      <p>{item.agency}</p>
                    </Link>
                  </li>
                ))}
              </ul>

              <ul className="mobile-nav-primary-menu">
                {this.primary2MenuItems.map((item, i) => (
                  <li
                    key={`mobile-nav-primary2-menu-item-${i}`}
                    className={
                      'mobile-nav-primary-menu-item' +
                      (item.url && item.url === currentPath
                        ? ' current-selected'
                        : '')
                    }
                  >
                    {item.url && item.url === currentPath && (
                      <Icon className="current-star" name="lone-star" />
                    )}
                    <NavLink
                      to={item.url}
                      onClick={this.handleNavItemClick.bind(this)}
                    >
                      {item.label}
                    </NavLink>
                  </li>
                ))}
                <li className="mobile-nav-language-menu">
                  <span className="mobile-nav-language-menu-label">
                    <Trans file="Labels" id="Language" fallback="Language" />
                  </span>
                  <LanguageSwitcher />
                </li>
              </ul>

              <ul className="mobile-nav-primary-menu">
                {this.signoutMenuItems.map(({ as: T, label, ...rest }, i) => (
                  <li
                    key={`mobile-nav-signout-menu-item-${i}`}
                    className="mobile-nav-primary-menu-item"
                  >
                    <T {...rest}>{label}</T>
                  </li>
                ))}
              </ul>
            </Container>
          </>
        </nav>
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ toggleMobileNav, signOut }, dispatch);
const mapStateToProps = ({ mobileNav, agency }) => ({ mobileNav, agency });

MobileNav.propTypes = {
  toggleMobileNav: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  mobileNav: PropTypes.object.isRequired,
  agency: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MobileNav));
