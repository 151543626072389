// Helpers
import { to } from '../../../lib/helpers';
import {
  checkRequestFailure,
  handleRequestFailure,
  ERRORS_MAP,
} from '../../../lib/validation';
import { gaActions } from '../../gaActionTypes';

// Requests
import { userUpdateUserSecurityQuestionsRequest } from '../../../backend/requests';

// Consts
export const USER_UPDATE_USER_SECURITY_QUESTIONS_SUCCESS_RECEIVED = `[USER] ${gaActions.submit} Update User Security Questions Success`;

export const updateUserSecurityQuestions = ({
  values: { question1, question2, question3, answer1, answer2, answer3 },
  formikActions,
  props: { setToggle },
}) => async dispatch => {
  const data = {
    questions: [
      { ...question1, answer: answer1, quesNum: '1' },
      { ...question2, answer: answer2, quesNum: '2' },
      { ...question3, answer: answer3, quesNum: '3' },
    ],
  };

  const [{ response, body }] = await to(
    dispatch(userUpdateUserSecurityQuestionsRequest(data))
  );

  if (checkRequestFailure({ response, body }))
    return handleRequestFailure(
      body,
      formikActions,
      ERRORS_MAP.UPDATE_SECURITY_QUESTIONS
    );

  setToggle();

  return dispatch({
    type: USER_UPDATE_USER_SECURITY_QUESTIONS_SUCCESS_RECEIVED,
    payload: body.data.questions,
  });
};
