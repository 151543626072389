// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { Container } from '..';

// Styles
import './hero.scss';

const Hero = ({ className, children }) => (
  <header className={classnames('hero', className)} role="banner">
    <Container className="hero-container">
      <div className="hero-body">{children}</div>
    </Container>
  </header>
);

Hero.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Hero;
