// Helpers
import { to } from '../../../lib/helpers';

// Requests
import { userGetUserRequest } from '../../../backend/requests';

// Consts
export const USER_GET_USER_SUCCESS_RECEIVED = '[USER] Get User Success';

export const getUser = () => async dispatch => {
  const [{ body, response }, err] = await to(dispatch(userGetUserRequest()));

  if (err) return Promise.reject(err);

  dispatch(storeUser({ body, response }));
  return Promise.resolve({ body, response });
};

export const storeUser = ({ body, response }) => dispatch => {
  const payload = body.data;
  payload.userId = response.headersObject.userid;
  dispatch({ type: USER_GET_USER_SUCCESS_RECEIVED, payload });
};
