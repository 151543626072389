export default {
  CYBER_ATTESTATION: {
    licensingEntity: 'publicSector',
    action: '/cybersecurity',
    actionWord: 'Confirm',
  },
  TDLR_RENEWAL: {
    licensingEntity: 'tdlr',
    action: '/tdlrlr',
    actionWord: 'Renew',
  },
  DMV_RENEWAL: {
    licensingEntity: 'dmv',
    action: '/dmvrr',
    actionWord: 'Renew',
  },
  DLR_RENEWAL: {
    licensingEntity: 'dlr',
    action: '/dlrlr',
    actionWord: 'Renew',
  },
};
