// Vendors
import React, { useState, useContext, createContext, useRef } from 'react';
import PropTypes from 'prop-types';

// Components
import { Button, TextButton, ButtonIcon } from 'texkit/dist/components/Buttons';
import * as Transitions from '../..';

const DrawerContext = createContext({ open: false });

function Drawer(props) {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(isOpen => !isOpen);
  return <DrawerContext.Provider value={{ open, toggle }} {...props} />;
}

Drawer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

function Open({ transition = 'DrawerTransition', ...props }) {
  const Transition = Transitions[transition];
  const transitionRef = useRef();
  const [height, set] = useState(0);
  const setHeight = height =>
    set(
      typeof height === 'number'
        ? height
        : transitionRef.current && transitionRef.current.scrollHeight
    );
  const { open } = useContext(DrawerContext);
  const style = { maxHeight: height };
  return (
    <div ref={transitionRef}>
      <Transition
        in={open}
        onEnter={() => setHeight()}
        onExiting={() => setHeight(0)}
        style={style}
        {...props}
      />
    </div>
  );
}

Open.propTypes = {
  transition: PropTypes.string.isRequired,
};

function Toggle({ as, ...props }) {
  const { toggle } = useContext(DrawerContext);
  const components = { Button, TextButton, ButtonIcon };
  const T = components[as] || as;
  return <T {...props} onClick={toggle} />;
}

Toggle.propTypes = {
  as: PropTypes.string,
};

Drawer.Open = Open;
Drawer.Toggle = Toggle;

export default Drawer;
