// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Components
import { Modal, Trans } from '../..';
import { Button, ButtonPrimary } from 'texkit/dist/components/Buttons';

// Styles
import './delete-contact-modal.scss';

const DeleteContactModal = ({
  modal: { data: { onDelete } = {} } = {},
  closeModal,
}) => {
  const onConfirm = async () => {
    onDelete();
    closeModal('DeleteContactModal');
  };
  return (
    <Modal name="DeleteContactModal" className="delete-contact-modal">
      <>
        <h2 className="h1 modal-title delete-contact-modal-header">
          <Trans file="Modals" id="DeleteContact" />
        </h2>

        <div className="modal-footer delete-contact-modal-footer">
          <Button onClick={() => closeModal('DeleteContactModal')}>
            <Trans file="Labels" id="Cancel" />
          </Button>
          <ButtonPrimary
            className="delete-contact-modal-button"
            onClick={onConfirm}
          >
            <Trans file="Labels" id="Yes_Delete" />
          </ButtonPrimary>
        </div>
      </>
    </Modal>
  );
};

const mapStateToProps = ({ modal }) => ({ modal });

const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeModal }, dispatch);

DeleteContactModal.propTypes = {
  modal: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteContactModal);
