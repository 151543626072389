//@flow

import { REQUEST_TYPES } from '.';
import { APP_TRANSACTION_IDS } from '../reducers/app/appActions';

// Example Request for Payment
// const examplePaymentRequest = {
//   userKey: '513',
//   agencyId: '4',
//   appId: '3',
//   profileInfo: {
//     profileName: 'Multiple Licenses',
//     licenseNumber: 'MT047040',
//     licenseType: 'Licensed Massage Therapist',
//     name: 'SHERRY CARTER',
//     licenseData: {
//       licenseCode: '5801',
//       renewalApplicationId: '13442796'
//     }
//   },
//   transactionInfo: {
//     paymentAccountId: 'fbf339d8-b476-4dee-a3d3-aa4f010df7af',
//     paymentMethod: 'credit',
//     renewalFee: {
//       fee: '75.00',
//       lateFee: '37.50',
//       feeTotal: '112.50'
//     },
//     feeCodes: [
//       {
//         key: 'FEECODE1',
//         value: '0120'
//       },
//       {
//         key: 'FEECODE2',
//         value: '1808'
//       }
//     ]
//   },
//   transactionId: 'SUBMIT_RENEWAL_PAYMENT'
// };

export const submitPayment = (
  paymentData: Object,
  basicInfo: Object
): RequestOptions => ({
  type: `[API] Payment Request`,
  url: '/agency/app/transact',
  APIMethod: REQUEST_TYPES.POST,
  body: {
    userKeys: [basicInfo.userKey],
    agencyId: basicInfo.agencyId,
    appId: basicInfo.appId,
    profileInfo: basicInfo.selectedLicense,
    transactionInfo: {
      ...paymentData,
      renewalFee: basicInfo.renewalFee,
      feeCodes: basicInfo.feeCodes,
    },
    transactionId: APP_TRANSACTION_IDS.SUBMIT_RENEWAL_PAYMENT,
  },
});

// Sample DLR payment request payload
//{
//   "appId": "9",
//   "profileName": null,
//   "userKeys": [
//       "1087"
//   ],
//   "transactionId": "SUBMIT_RENEWAL_PAYMENT",
//   "profileInfo": {
//       "userKey": "1087"
//   },
//   "transactionInfo": {
//       "cardType": "ID",
//        "paymentToken": "1e9fce02-9f9e-4e98-b26b-34343",
//             "saveSelectedCard" : true or false,
//             "paymentAccountType" : "VISA Card"
//       "organDonorOption": true,
//       "voterRegistration": false,
//       "transactionId": null,
//       "traceNumber": null,
//       "surrenderId": false,
//       "surrenderDl": false,
//       "transactionAmounts": {
//           "bestDonationFee": "1.00",
//           "bestDonationOption": true,
//           "glendaDonationOption": false,
//           "glendaDonationFee": null,
//           "fvaDonationOption": false,
//           "fvaDonationFee": null,
//           "evidenceTestingDonationOption": false,
//           "evidenceTestingDonationFee": null,
//           "youthFundDonationOption": false,
//           "youthFundDonationFee": null,
//           "subTotal": "17.00"
//       }
//   }
// }

export const submitDLRPayment = (
  paymentData: Object = {},
  basicInfo: Object
): RequestOptions => {
  const { address: billingAddress = {} } = paymentData;
  if (paymentData && paymentData.address) delete paymentData.address;

  return {
    type: `[API] Payment Request`,
    url: '/agency/app/transact',
    APIMethod: REQUEST_TYPES.POST,
    body: {
      userKeys: [basicInfo.userKey],
      agencyId: basicInfo.agencyId,
      appId: basicInfo.appId,
      profileInfo: { userKey: basicInfo.userKey },
      transactionInfo: {
        ...paymentData,
        billingAddress,
        cardType: basicInfo.licenseType,
        renewalFee: basicInfo.renewalFee,
        organDonorOption: basicInfo.organDonorOption,
        voterRegistration: basicInfo.voterRegistration,
        volunteerElectionJudge: basicInfo.volunteerElectionJudge,
        voterRegistrationApplicationType: basicInfo.voterRegistrationAppType,
        surrenderCardOption: basicInfo.surrenderCardOption,
        serviceFee: basicInfo.serviceFee,
        mailingSameAsHomeAddress: basicInfo.mailingSameAsHomeAddress,
        mailingAddress: basicInfo.renewalMailingAddress,
        homeAddress: basicInfo.renewalHomeAddress,
        primaryEmergencyContactInfo:
          basicInfo.emergencyContacts.primaryEmergencyContactInfo,
        secondryEmergencyContactInfo:
          basicInfo.emergencyContacts.secondryEmergencyContactInfo,
        transactionAmounts: {
          bestDonationFee: (
            Number.parseInt(basicInfo.donationValue.bestDonationOption) || ''
          ).toString(),
          bestDonationOption:
            basicInfo.donationOption.bestDonationOption &&
            basicInfo.donationValue.bestDonationOption > 0,
          glendaDonationOption:
            basicInfo.donationOption.glendaDonationOption &&
            basicInfo.donationValue.glendaDonationOption > 0,
          glendaDonationFee: (
            Number.parseInt(basicInfo.donationValue.glendaDonationOption) || ''
          ).toString(),
          fvaDonationOption:
            basicInfo.donationOption.fvaDonationOption &&
            basicInfo.donationValue.fvaDonationOption > 0,
          fvaDonationFee: (
            Number.parseInt(basicInfo.donationValue.fvaDonationOption) || ''
          ).toString(),
          evidenceTestingDonationOption:
            basicInfo.donationOption.evidenceTestingDonationOption &&
            basicInfo.donationValue.evidenceTestingDonationOption > 0,
          evidenceTestingDonationFee: (
            Number.parseInt(
              basicInfo.donationValue.evidenceTestingDonationOption
            ) || ''
          ).toString(),
          youthFundDonationOption:
            basicInfo.donationOption.youthFundDonationOption &&
            basicInfo.donationValue.youthFundDonationOption > 0,
          youthFundDonationFee: (
            Number.parseInt(basicInfo.donationValue.youthFundDonationOption) ||
            ''
          ).toString(),
          subTotal: basicInfo.donationValue.total || '0.00',
        },
      },
      transactionId: basicInfo.renewAddressChangeFlag
        ? APP_TRANSACTION_IDS.REPLACEMENT
        : APP_TRANSACTION_IDS.SUBMIT_RENEWAL_PAYMENT,
    },
  };
};

export const submitDMVPayment = (body: Object): RequestOptions => ({
  type: `[API] Payment Request`,
  url: '/agency/app/transact',
  APIMethod: REQUEST_TYPES.POST,
  body,
});
