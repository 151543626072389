// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

// Styles
import './grow-transition.scss';

const GrowTransition = ({ className, children, ...props }) => (
  <CSSTransition
    {...props}
    classNames="grow-transition"
    timeout={300}
    unmountOnExit
  >
    <div className={classnames('grow-transition', className)}>{children}</div>
  </CSSTransition>
);

GrowTransition.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default GrowTransition;
