// Vendors
import React, { useRef } from 'react';

// Components
import { Modal } from '../../..';
import Trans from '../../../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { Icon } from 'texkit/dist/components/Icon/Icon';
import { Accordion } from '../../../../../../components';
// Styles
import '../dlr-FAQ-modal.scss';

const DLRFAQModal = () => {
  const modal = useRef();
  return (
    <Modal name="DLRFAQModal">
      <h2 className="h1 modal-title">
        <Trans file="dlr" id="dlr_FAQ_modal_title" />
      </h2>
      <p className="modal-description">
        <Trans file="dlr" id="dlr_FAQ_modal_desc" />
      </p>
      <div ref={modal} className="modal-inner-body">
        <p className="h4 modal-subHeading ">
          <Trans file="dlr" id="dlr_General" />
        </p>
        <Accordion
          key={'dlr_General_FAQ_1'}
          title={<Trans file="dlr" id="dlr_General_FAQ_1" />}
          content={<Trans file="dlr" id="dlr_General_FAQ_1_Desc" />}
        />
        <Accordion
          key={'dlr_FAQ_1'}
          title={<Trans file="dlr" id="dlr_FAQ_1" />}
          content={<Trans file="dlr" id="dlr_FAQ_1_Desc" />}
        />
        <Accordion
          key={'dlr_General_FAQ_2'}
          title={<Trans file="dlr" id="dlr_General_FAQ_2" />}
          content={<Trans file="dlr" id="dlr_General_FAQ_2_Desc" />}
        />
        <Accordion
          key={'dlr_FAQ_2'}
          title={<Trans file="dlr" id="dlr_FAQ_2" />}
          content={<Trans file="dlr" id="dlr_FAQ_2_Desc" />}
        />
        <Accordion
          key={'dlr_RENEWAL_FAQ_3'}
          title={<Trans file="dlr" id="dlr_RENEWAL_FAQ_3" />}
          content={<Trans file="dlr" id="dlr_RENEWAL_FAQ_3_Desc" />}
        />
        <Accordion
          key={'dlr_FAQ_4'}
          title={<Trans file="dlr" id="dlr_FAQ_4" />}
          content={<Trans file="dlr" id="dlr_FAQ_4_Desc" />}
        />
        <Accordion
          key={'dlr_General_FAQ_3'}
          title={<Trans file="dlr" id="dlr_General_FAQ_3" />}
          content={<Trans file="dlr" id="dlr_General_FAQ_3_Desc" />}
        />

        <p className="h4 modal-subHeading ">
          <Trans file="dlr" id="dlr_ID_CARD_Renewal" />
        </p>
        <Accordion
          key={'dlr_FAQ_3'}
          title={<Trans file="dlr" id="dlr_FAQ_3" />}
          content={<Trans file="dlr" id="dlr_FAQ_3_Desc" />}
        />
        <Accordion
          key={'dlr_RENEWAL_FAQ_4'}
          title={<Trans file="dlr" id="dlr_RENEWAL_FAQ_4" />}
          content={<Trans file="dlr" id="dlr_RENEWAL_FAQ_4_Desc" />}
        />
        <Accordion
          key={'dlr_RENEWAL_FAQ_5'}
          title={<Trans file="dlr" id="dlr_RENEWAL_FAQ_5" />}
          content={<Trans file="dlr" id="dlr_RENEWAL_FAQ_5_Desc" />}
        />
        <Accordion
          key={'dlr_FAQ_6'}
          title={<Trans file="dlr" id="dlr_FAQ_6" />}
          content={<Trans file="dlr" id="dlr_FAQ_6_Desc" />}
        />
        <Accordion
          key={'dlr_RENEWAL_FAQ_13'}
          title={<Trans file="dlr" id="dlr_RENEWAL_FAQ_13" />}
          content={<Trans file="dlr" id="dlr_RENEWAL_FAQ_13_Desc" />}
        />

        <p className="h4 modal-subHeading ">
          <Trans file="dlr" id="dlr_Card_Replacement" />
        </p>
        <Accordion
          key={'dlr_Card_Replacement_FAQ_1'}
          title={<Trans file="dlr" id="dlr_Card_Replacement_FAQ_1" />}
          content={<Trans file="dlr" id="dlr_Card_Replacement_FAQ_1_Desc" />}
        />
        <Accordion
          key={'dlr_RENEWAL_FAQ_6'}
          title={<Trans file="dlr" id="dlr_RENEWAL_FAQ_6" />}
          content={<Trans file="dlr" id="dlr_RENEWAL_FAQ_6_Desc" />}
        />
        <Accordion
          key={'dlr_RENEWAL_FAQ_7'}
          title={<Trans file="dlr" id="dlr_RENEWAL_FAQ_7" />}
          content={<Trans file="dlr" id="dlr_RENEWAL_FAQ_7_Desc" />}
        />
        <Accordion
          key={'dlr_Card_Replacement_FAQ_2'}
          title={<Trans file="dlr" id="dlr_Card_Replacement_FAQ_2" />}
          content={<Trans file="dlr" id="dlr_Card_Replacement_FAQ_2_Desc" />}
        />
        <Accordion
          key={'dlr_REPLACEMENT_FAQ_6'}
          title={<Trans file="dlr" id="dlr_REPLACEMENT_FAQ_6" />}
          content={<Trans file="dlr" id="dlr_REPLACEMENT_FAQ_6_Desc" />}
        />
        <Accordion
          key={'dlr_REPLACEMENT_FAQ_8'}
          title={<Trans file="dlr" id="dlr_REPLACEMENT_FAQ_8" />}
          content={<Trans file="dlr" id="dlr_REPLACEMENT_FAQ_8_Desc" />}
        />

        <p className="h4 modal-subHeading ">
          <Trans file="dlr" id="dlr_update_emergency_Contacts" />
        </p>
        <Accordion
          key={'dlr_EMERGENCY_FAQ_2'}
          title={<Trans file="dlr" id="dlr_EMERGENCY_FAQ_2" />}
          content={<Trans file="dlr" id="dlr_EMERGENCY_FAQ_2_Desc" />}
        />
        <Accordion
          key={'dlr_EMERGENCY_FAQ_3'}
          title={<Trans file="dlr" id="dlr_EMERGENCY_FAQ_3" />}
          content={<Trans file="dlr" id="dlr_EMERGENCY_FAQ_3_Desc" />}
        />
        <Accordion
          key={'dlr_EMERGENCY_FAQ_4'}
          title={<Trans file="dlr" id="dlr_EMERGENCY_FAQ_4" />}
          content={<Trans file="dlr" id="dlr_EMERGENCY_FAQ_4_Desc" />}
        />
        <Accordion
          key={'dlr_EMERGENCY_FAQ_5'}
          title={<Trans file="dlr" id="dlr_EMERGENCY_FAQ_5" />}
          content={<Trans file="dlr" id="dlr_EMERGENCY_FAQ_5_Desc" />}
        />
        <Accordion
          key={'dlr_EMERGENCY_FAQ_6'}
          title={<Trans file="dlr" id="dlr_EMERGENCY_FAQ_6" />}
          content={<Trans file="dlr" id="dlr_EMERGENCY_FAQ_6_Desc" />}
        />

        <p className="h4 modal-subHeading ">
          <Trans file="dlr" id="dlr_REAL_ID" />
        </p>
        <Accordion
          key={'dlr_FAQ_9'}
          title={<Trans file="dlr" id="dlr_FAQ_9" />}
          content={<Trans file="dlr" id="dlr_FAQ_9_Desc" />}
        />
        <Accordion
          key={'dlr_REAL_ID_FAQ_1'}
          title={<Trans file="dlr" id="dlr_REAL_ID_FAQ_1" />}
          content={<Trans file="dlr" id="dlr_REAL_ID_FAQ_1_Desc" />}
        />
        <Accordion
          key={'dlr_RENEWAL_FAQ_11'}
          title={<Trans file="dlr" id="dlr_RENEWAL_FAQ_11" />}
          content={<Trans file="dlr" id="dlr_RENEWAL_FAQ_11_Desc" />}
        />
        <Accordion
          key={'dlr_REAL_ID_FAQ_2'}
          title={<Trans file="dlr" id="dlr_REAL_ID_FAQ_2" />}
          content={<Trans file="dlr" id="dlr_REAL_ID_FAQ_2_Desc" />}
        />
        <Accordion
          key={'dlr_REAL_ID_FAQ_3'}
          title={<Trans file="dlr" id="dlr_REAL_ID_FAQ_3" />}
          content={<Trans file="dlr" id="dlr_REAL_ID_FAQ_3_Desc" />}
        />

        <p className="h4 modal-subHeading ">
          <Trans file="dlr" id="dlr_Fees" />
        </p>
        <Accordion
          key={'dlr_RENEWAL_FAQ_12'}
          title={<Trans file="dlr" id="dlr_RENEWAL_FAQ_12" />}
          content={<Trans file="dlr" id="dlr_Fees_Desc" />}
        />
      </div>
      <ButtonPrimary
        className="modal-button initial"
        onClick={() => {
          if (modal && modal.current) modal.current.scrollTop = 0;
        }}
        icon="more"
      >
        <Icon name="chevron-up" />
        <Trans file="Labels" id="BackToTop" />
      </ButtonPrimary>
    </Modal>
  );
};

export default DLRFAQModal;
