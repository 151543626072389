// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { TxAlert, TxAlertType } from 'texkit-ui/components';
import Trans from '../../Trans/Trans';

// Styles
import './form-error.scss';

// Types
import { formikInjectedPropsTypes } from '../../../../lib/validation/propTypes/formikPropTypes';

const FormError = ({ hasError, message, formikProps }) => {
  const { status = {} } = formikProps;
  const localError = message;
  const serverError = (status.serverErrors || {}).formError;
  const errorMap = serverError || localError || {};

  hasError = !!serverError || hasError;

  if (!hasError || !errorMap.id) return null;

  let errorType;
  switch (errorMap.type) {
    case 'info':
      errorType = TxAlertType.Info;
      break;
    case 'danger':
      errorType = TxAlertType.Error;
      break;
    case 'warning':
    default:
      errorType = TxAlertType.Warning;
  }

  return (
    <div className="alert-container">
      <TxAlert
        type={errorType}
        heading={
          <Trans file="Errors" id={errorMap.title} args={errorMap.args} />
        }
      >
        <Trans file="Errors" id={errorMap.id} args={errorMap.args} />
      </TxAlert>
    </div>
  );
};

FormError.propTypes = {
  formikProps: formikInjectedPropsTypes.isRequired,
  hasError: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default FormError;
