// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import {
  Modal,
  Form,
  InputGroupCode,
  FormActions,
  FormActionsItem,
  OTPSentContent,
  Trans,
} from '../..';

import { ButtonPrimary, Button } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';
import { updateContactSubmitRequest } from '../../../../reducers/user/updateContact/updateContactActions';

// Helpers
import { verifyOTPValidationSchema } from '../../../../lib/validation';

// Styles
import './verify-contact-modal.scss';

class VerifyContactModal extends Component<Props> {
  modalName = 'VerifyContactModal';

  get initialValues() {
    const { OTP } = this.props;
    const { channelCode } = OTP.data || {};

    return {
      code: '',
      channelCode: channelCode || '',
    };
  }

  handleCancel() {
    const { id, onCancel, closeModal } = this.props;
    closeModal(id);
    setTimeout(() => {
      if (onCancel) onCancel();
    }, 300);
  }

  handleSuccess() {
    const { id, onSuccess, closeModal } = this.props;
    closeModal(id);
    setTimeout(() => {
      if (onSuccess) onSuccess();
    }, 300);
  }

  handleSubmit(values, formikActions) {
    this.props.updateContactSubmitRequest({
      values,
      formikActions,
      props: { ...this.props, onSuccess: this.handleSuccess.bind(this) },
    });
  }

  render() {
    const { id, OTP, ...props } = this.props;
    if (!OTP.data || !OTP.data.channelCode || !OTP.data.contactInfo)
      return null; // Requires necessary modal data
    const { channelCode, contactInfo } = OTP.data;

    return (
      <Modal
        {...props}
        id={id}
        name={this.modalName}
        className="verify-contact-modal"
      >
        <h2 className="h1 modal-title">
          {channelCode === 'SMS' ? (
            <Trans
              file="MFA"
              id="VerifyNewPhone"
              fallback="Please verify your new phone."
            />
          ) : (
            <Trans
              file="MFA"
              id="VerifyNewEmail"
              fallback="Verify your new email"
            />
          )}
        </h2>
        <div className="modal-content">
          <OTPSentContent channelCode={channelCode} contactInfo={contactInfo} />
          <Form
            className="modal-form"
            formikConfig={{
              validateOnBlur: false,
              enableReinitialize: true,
              onSubmit: this.handleSubmit.bind(this),
              initialValues: this.initialValues,
              validationSchema: verifyOTPValidationSchema,
            }}
            render={({ FormError, formikProps }) => (
              <>
                <InputGroupCode formikProps={formikProps} />
                <FormError />
                <FormActions align="center">
                  <FormActionsItem>
                    <ButtonPrimary
                      type="submit"
                      isLoading={formikProps.isSubmitting}
                      loadingContent={<Trans file="Labels" id="Submitting" />}
                    >
                      <Trans file="Labels" id="Submit" fallback="Submit" />
                    </ButtonPrimary>
                  </FormActionsItem>
                  <FormActionsItem>
                    <Button
                      onClick={this.handleCancel.bind(this)}
                      disabled={formikProps.isSubmitting}
                    >
                      <Trans file="Labels" id="Cancel" fallback="Cancel" />
                    </Button>
                  </FormActionsItem>
                </FormActions>
              </>
            )}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ OTP, user: { verifyContact } }) => ({
  OTP,
  verifyContact,
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeModal, updateContactSubmitRequest }, dispatch);

VerifyContactModal.propTypes = {
  channelCode: PropTypes.string.isRequired,
  contactInfo: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  OTP: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  updateContactSubmitRequest: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyContactModal);
