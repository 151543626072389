// Vendors
import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

// Components
import { Container } from '..';

// Styles
import './navbar.scss';

const Navbar = ({ className, children }) => (
  <div className={classnames('navbar-wrapper', className)}>
    <nav className="navbar" role="navigation">
      <Container className="navbar-container">{children}</Container>
    </nav>
  </div>
);

Navbar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Navbar;
