import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHasExistingContact } from './useHasExistingContact';
import { push } from 'connected-react-router';
import { onboardingSaveProgress } from '../../reducers/onboarding/onboardingActions';
import {
  ONBOARDING_STEP_ROUTES,
  ONBOARDING_SAVE_POINT_NAMES,
} from './OnboardingSteps';

export function useProtectOnboardingPhone() {
  const dispatch = useDispatch();
  const { isGovernmentEmployee } = useSelector(state => state.onboarding);
  const hasPhone = useHasExistingContact('phone');

  useEffect(() => {
    (async function asyncExistingPhone() {
      if (hasPhone && isGovernmentEmployee) {
        await dispatch(
          onboardingSaveProgress(ONBOARDING_SAVE_POINT_NAMES.COMPLETE)
        );
      } else if (hasPhone && !isGovernmentEmployee) {
        dispatch(
          push(`/getting-started/${ONBOARDING_STEP_ROUTES.START_LINKING}`)
        );
      }
    })();
  }, [hasPhone]);
}
