import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHasExistingContact } from './useHasExistingContact';
import { ONBOARDING_STEP_ROUTES } from './OnboardingSteps';
import { push } from 'connected-react-router';

export function useProtectOnboardingEmail() {
  const dispatch = useDispatch();
  const hasWorkEmail = useHasExistingContact('workEmail');

  useEffect(() => {
    if (hasWorkEmail) {
      dispatch(
        push(`/getting-started/${ONBOARDING_STEP_ROUTES.SECURITY_QUESTIONS}`)
      );
    }
  }, [hasWorkEmail]);
}
