export const dmv = {
  APP_ERRORS: {
    dmvrr: {
      linkService: {
        lastFourOfVIN: 'params.lastFourOfVIN',
        plateNumber: 'params.plateNumber',
        ownerIdentification: 'params.ownerIdentification',
      },
    },
  },
};
