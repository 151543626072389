// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// Styles
import './section.scss';

/**
 * @visibleName Default
 */
const Section = ({ className, title, children, viewAll, ...props }) => (
  <section {...props} className={classnames('section', className)}>
    <header className="section-header" role="banner" aria-label="banner">
      {title && <h2 className="section-title">{title}</h2>}
      {viewAll && viewAll.text && (
        <Link to={viewAll.url} className="section-view-all">
          {viewAll.text}
        </Link>
      )}
    </header>
    <div className="section-body">{children}</div>
  </section>
);

Section.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  viewAll: {
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  },
};

export default Section;
