// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Requests
import { APP_TRANSACTION_IDS } from '../../../reducers/app/appActions';
import { getLookUpDataAndRenewDMVRegistration } from '../../../reducers/agency/dmv/dmvActions';

// Components

import { Button, ButtonPrimary } from 'texkit/dist/components/Buttons';
import { FormActions, FormActionsItem, Trans } from '../../../components';

// Types
import { AppParamsPropTypes } from '../../../components/common/Forms/LinkServiceLookupForm/LinkServiceLookupForm';

const LinkServiceSuccess = ({ link }) => {
  const dispatch = useDispatch();

  if (!link || !link.agency || !link.app || !link.success)
    return <Redirect to="/link" />;

  const { agency, app, relinkData } = link;

  const relinkLabel = relinkData ? '_Relink' : '';

  const handleRenewClick = () => {
    dispatch(
      getLookUpDataAndRenewDMVRegistration({
        appId: app.appId,
        userKeys: app.linkUserKeys.slice(app.linkUserKeys.length - 1),
        transactionId: APP_TRANSACTION_IDS.BASIC_INFO,
      })
    );
  };

  const goToDMVDashboard = () => {
    dispatch(push(`/agency/${agency.agencySlug}`));
  };

  return (
    <>
      <h1 className="h2">
        <Trans
          file={agency.agencySlug}
          id={`${app.appSlug}_LinkService_SuccessTitle${relinkLabel}`}
        />
      </h1>

      {link.linkData.renewalIndicator === 'Y' && (
        <>
          <p className="link-service-lookup-form-custom">
            <Trans file="LinkService" id="SuccessCustomContent_DMV_Renew" />
          </p>
          <FormActions>
            <FormActionsItem>
              <ButtonPrimary onClick={handleRenewClick}>
                <Trans file="Labels" id="Renew" />
              </ButtonPrimary>
            </FormActionsItem>
            <FormActionsItem>
              <Button onClick={goToDMVDashboard}>
                <Trans file="Labels" id={`${app.appSlug}_ViewMyLicense`} />
              </Button>
            </FormActionsItem>
          </FormActions>
        </>
      )}
      {link.linkData.renewalIndicator === 'N' && (
        <>
          <p className="link-service-lookup-form-custom">
            <Trans file="LinkService" id="SuccessCustomContent_DMV" />
          </p>
          <FormActions>
            <FormActionsItem>
              <ButtonPrimary onClick={goToDMVDashboard}>
                <Trans file="Labels" id={`${app.appSlug}_ViewMyLicense`} />
              </ButtonPrimary>
            </FormActionsItem>
          </FormActions>
        </>
      )}
    </>
  );
};

LinkServiceSuccess.propTypes = {
  link: PropTypes.shape({
    params: PropTypes.arrayOf(AppParamsPropTypes),
    agency: PropTypes.object,
    app: PropTypes.object,
    linkData: PropTypes.object,
    success: PropTypes.object,
    relinkData: PropTypes.string,
  }).isRequired,
};

export default LinkServiceSuccess;
