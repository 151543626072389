// Vendors
import React from 'react';

// Helpers
import { publicURL } from '../../../../lib/helpers';

// Components
import { Modal } from '..';
import Trans from '../../Trans/Trans';

// Styles
import './license-details-modal.scss';

const LicenseDetailsModal = () => (
  <Modal name="LicenseDetailsModal" className="license-details-modal">
    <h2 className="h1 modal-title">
      <Trans
        file="Modals"
        id="FindYourID"
        fallback="Find your ID and DD/Audit numbers."
      />
    </h2>
    <div className="modal-content">
      <Trans
        file="Modals"
        id="LicenseOrIDNumber"
        fallback="Driver License or ID number"
      />
      <div className="modal-graphic">
        <img
          src={publicURL('dl-example.png')}
          alt={<Trans file="Modals" id="LicenseDetailsImageAlt" />}
        />
      </div>
      Audit number
    </div>
  </Modal>
);

export default LicenseDetailsModal;
