//@flow

// Vendors
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

// Components
import { Container, Trans, LanguageSwitcher } from '..';
import { TextButton } from 'texkit/dist/components/Buttons';
import DLRFooter from './apps/DLR/DLRFooter';

// Helpers
import SITE_DATA from '../../lib/data/siteData';

// Actions
import { openModal } from '../../reducers/modal/modalActions';

// Selectors
import { selectAgencyByRouteParam } from '../../reducers/agency/agencySelectors';

// Assets
import StateOfTexasLogo from '../../assets/state-of-texas-logo.png';

// Styles
import './footer.scss';

const Footer = ({ className, agency: { agencySlug = '' } }: Props) => {
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={classnames('footer', className)} role="contentinfo">
      <Container>
        <div className="footer-copyright">
          <a
            href="https://texas.gov/"
            className="state-of-texas-logo"
            target="_blank"
            rel="noopener noreferrer"
            alt="State of Texas Logo"
          >
            <img
              src={StateOfTexasLogo}
              alt={
                <Trans
                  file="Footer"
                  id="StateOfTexasLogoAlt"
                  fallback="State of Texas Logo"
                />
              }
            />
          </a>
          <Trans
            file="Footer"
            id="Copyright"
            fallback={`Copyright © ${currentYear} State of Texas. All rights reserved.`}
          />
        </div>
        <nav className="footer-nav" role="navigation">
          <ul className="footer-nav-list">
            {/* DLR agency footer */}
            <DLRFooter />
            <li className="footer-nav-list-item">
              <TextButton
                as="a"
                onClick={() => dispatch(openModal('TermsAndConditionsModal'))}
              >
                <Trans
                  file="Common"
                  id="TermsAndConditions"
                  fallback="Terms & Conditions"
                />
              </TextButton>
            </li>
            <li className="footer-nav-list-item">
              <TextButton as="a" href={`tel:${SITE_DATA.HELP_DESK_PHONE}`}>
                <Trans file="Footer" id="CallSupport" fallback="Call support" />{' '}
                <span className="nobr">{SITE_DATA.HELP_DESK_PHONE}</span>
              </TextButton>
            </li>
            <li className="footer-nav-list-item">
              <LanguageSwitcher dropdownProps={{ menuPosition: 'top-left' }} />
            </li>
          </ul>
        </nav>
      </Container>
    </footer>
  );
};

const mapStateToProps = (state, props): Object => ({
  agency: selectAgencyByRouteParam(state, props),
});

Footer.propTypes = {
  className: PropTypes.string,
  agency: PropTypes.object,
  agencySlug: PropTypes.string,
};

export default memo(withRouter(connect(mapStateToProps, null)(Footer)));
