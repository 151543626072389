// @flow

// Vendors
import * as React from 'react';

// Components
import { Trans } from '../../../../components';

const OnboardingSecurityQuestionsStepWrapper = ({ children }: Props) => {
  return (
    <div className="onboarding-setup-security-questions">
      <h2 className="h1 title">
        <Trans
          file="Onboarding"
          id="SecurityQuestionsTitle"
          fallback="Set up security questions."
        />
      </h2>
      <p className="lead">
        <Trans
          file="Onboarding"
          id="SecurityQuestionsDescription"
          fallback="Choose three security questions so we can verify your identity if there’s ever an issue with your account."
        />
      </p>
      <div className="onboarding-body">{children}</div>
    </div>
  );
};

type Props = {
  children?: React.Node,
};

export default OnboardingSecurityQuestionsStepWrapper;
