// @flow

// Helpers
import { to, stripDashes } from '../../../lib/helpers';
import {
  checkRequestFailure,
  handleRequestFailure,
  ERRORS_MAP,
} from '../../../lib/validation';
import { gaActions } from '../../gaActionTypes';

// Requests
import {
  userExistsRequest,
  workEmailExistsRequest,
  userVerifyUserNameRequest,
  userVerifyContactRequest,
  userAddUpdateContactRequest,
  userDeleteContactRequest,
} from '../../../backend/requests';

// Actions
import { sendOTP, OTP_MESSAGE_TYPES } from '../../OTP/OTPActions';
import { updateProfileInfo } from '../updateProfileInfo/updateProfileInfoActions';

// Consts
export const USER_UPDATE_CONTACT_VERIFY_CONTACT_CLOSE_MODAL =
  '[USER] Update Contact Close Verify Modal';
export const USER_UPDATE_CONTACT_SEND_OTP_SUCCESS_RECEIVED = `[USER] ${gaActions.submit} Update Contact Send OTP Success`;
export const USER_UPDATE_CONTACT_VERIFY_USER_NAME_SUCCESS_RECEIVED = `[USER] ${gaActions.submit} Update Contact Verify User Name Success`;
export const USER_UPDATE_CONTACT_VERIFY_CONTACT_SUCCESS_RECEIVED =
  '[USER] Update Contact Verify Contact Success';
export const USER_UPDATE_CONTACT_ADD_UPDATE_CONTACT_SUCCESS_RECEIVED = `[USER] ${gaActions.success} Update Contact Add/Update Contact Success`;
export const USER_CLEAR_WORKEMAIL = `[USER] Clear Work Email From State`;
export const USER_RECAPTURE_WORK_EMAIL =
  '[USER] Clear Verify Contact from State';

export const sendOTPAndSetVerifyContact = ({
  formikActions,
  typeCode,
  channelCode,
  userEmail,

  newEmail,

  newPhoneNumber,
}: {
  formikActions: Function,
  typeCode: string,
  channelCode: string,
  userEmail: string,
  workEmail?: string,
  newEmail?: string,
  phone?: Object,
  newPhoneNumber?: string,
}) => async (dispatch: Function, getState: Function) => {
  if (newEmail)
    await dispatch(
      handleCheckIfEmailIsBeingUsed({
        typeCode,
        newEmail,
        formikActions,
      })
    );

  const messageType =
    typeCode === 'WE'
      ? OTP_MESSAGE_TYPES.UPDATE_WORK_EMAIL
      : typeCode === 'PM'
      ? OTP_MESSAGE_TYPES.UPDATE_CONTACT
      : OTP_MESSAGE_TYPES.UPDATE_USER_NAME;

  const OTPData = {
    channelCode,
    typeCode,
    userEmail,
    contactInfo:
      channelCode === 'SMTP' ? newEmail : stripDashes(newPhoneNumber),
    messageType,
  };

  const { body, response } = await dispatch(
    handleSendOTP({ OTPData, formikActions })
  );
  if (checkRequestFailure({ response, body })) return Promise.reject();

  const contacts = getState().user.contacts || {};

  const contactDataOptions = {
    WE: () => ({
      data: {
        contactGuid:
          contacts.workEmail && contacts.workEmail.contactGuid
            ? contacts.workEmail.contactGuid
            : '',
        channelCode,
        contactInfo: newEmail,
        typeCode,
      },
    }),
    PM: () => ({
      data: {
        contactGuid:
          contacts.phone && contacts.phone.contactGuid
            ? contacts.phone.contactGuid
            : '',
        channelCode,
        contactInfo: stripDashes(newPhoneNumber),
        typeCode,
      },
    }),
    PE: () => ({
      data: {
        contactGuid:
          contacts.email && contacts.email.contactGuid
            ? contacts.email.contactGuid
            : '',
        contactInfo: newEmail,
        typeCode,
        channelCode,
      },
    }),
  };

  const verifyContact = { ...contactDataOptions[typeCode](), channelCode };
  dispatch(handleUpdateVerifyContact({ verifyContact }));
  return Promise.resolve();
};

export const handleCheckIfEmailIsBeingUsed = ({
  typeCode,
  newEmail,
  formikActions,
}: {
  typeCode: string,
  newEmail: string,
  formikActions: Object,
}) => async (dispatch: Function) => {
  const checkIfExistsRequest =
    typeCode === 'WE' ? workEmailExistsRequest : userExistsRequest;
  const [{ body, response }] = await to(
    dispatch(checkIfExistsRequest(newEmail))
  );

  if (body && body.message && body.message.toLowerCase() === 'user exists') {
    body.errors = { ...body.errors, ERROR: ['MGMW_E_0002'] };
  }

  if (checkRequestFailure({ response, body })) {
    if (body.errors.ERROR.includes('MGMW_E_0001')) {
      handleRequestFailure(body, formikActions, ERRORS_MAP.USER_DEACTIVATED);
    } else if (body.errors.ERROR.includes('MGMW_E_0002')) {
      handleRequestFailure(body, formikActions, ERRORS_MAP.USER_EXISTS);
    }
    return Promise.reject({ body, response });
  }

  return Promise.resolve({ body, response });
};

export const handleSendOTP = ({
  OTPData,
  formikActions,
}: {
  OTPData: {
    channelCode: string,
    typeCode: string,
    userEmail: string,
    messageType: any,
    contactInfo?: string,
  },
  formikActions: Function,
}) => async (dispatch: Function) => {
  const [{ body, response }] = await to(dispatch(sendOTP(OTPData)));
  if (checkRequestFailure({ body, response })) {
    handleRequestFailure(body, formikActions, ERRORS_MAP.SEND_OTP);
    return Promise.reject({ body, response });
  }
  return Promise.resolve({ body, response });
};

export const handleUpdateVerifyContact = ({
  verifyContact,
}: {
  verifyContact: {
    channelCode: string,
    data: {
      contactGuid: string,
      channelCode: string,
      contactInfo: string,
      typeCode: string,
    },
  },
}) => (dispatch: Function) => {
  dispatch({
    type: USER_UPDATE_CONTACT_SEND_OTP_SUCCESS_RECEIVED,
    payload: { verifyContact },
  });
  return Promise.resolve(true);
};

export const updateContactSubmitRequest = (params: Object) => (
  dispatch: Function
) => {
  const typeCode = params.props.OTP
    ? params.props.OTP.data.typeCode
    : params.values.phoneType === 'SMS'
    ? 'PM'
    : 'PL';
  const methods = {
    PL: addUpdateContact, // Primary Landline
    PM: verifyContact, // Primary Mobile
    WE: verifyContact, // Work Email
    PE: verifyUserName, // Primary Email
  };
  return dispatch(methods[typeCode](params));
};

const verifyUserName = ({ values, props, formikActions }) => async dispatch => {
  const {
    OTP: { headers },
    verifyContact,
    onSuccess,
  } = props;
  const { code } = values;
  const data = {
    body: {
      ...verifyContact.data,
      otp: code,
    },
    headers,
  };

  const [{ response, body }] = await to(
    dispatch(userVerifyUserNameRequest(data))
  );

  if (checkRequestFailure({ response, body }))
    return handleRequestFailure(body, formikActions, ERRORS_MAP.VERIFY_OTP);

  if (onSuccess) onSuccess();

  return dispatch({
    type: USER_UPDATE_CONTACT_VERIFY_USER_NAME_SUCCESS_RECEIVED,
    payload: verifyContact.data,
  });
};

export const verifyContact = ({
  values,
  props,
  formikActions,
}: {
  values: Object,
  props: Object,
  formikActions: Object,
}) => async (dispatch: Function) => {
  const {
    OTP: { headers },
    verifyContact,
    onSuccess,
  } = props;
  const { code } = values;
  const data = {
    body: {
      ...verifyContact.data,
      otp: code,
    },
    headers,
  };

  const [{ response, body }] = await to(
    dispatch(userVerifyContactRequest(data))
  );

  if (checkRequestFailure({ response, body }))
    return handleRequestFailure(body, formikActions, ERRORS_MAP.VERIFY_OTP);

  dispatch({
    type: USER_UPDATE_CONTACT_VERIFY_CONTACT_SUCCESS_RECEIVED,
    payload: body.data,
  });

  if (onSuccess) onSuccess();
};

const addUpdateContact = ({
  values,
  props: { onSuccess, phone },
  formikActions,
}) => async dispatch => {
  const { phoneType } = values;
  const data = {
    body: {
      versionId: phone.versionId,
      channelCode: phoneType,
      contactGuid: phone.contactGuid,
      contactInfo: stripDashes(values.phone),
      typeCode: 'PL',
    },
  };

  const [{ response, body }] = await to(
    dispatch(userAddUpdateContactRequest(data))
  );

  if (checkRequestFailure({ response, body }))
    return handleRequestFailure(body, formikActions, ERRORS_MAP.UPDATE_CONTACT);

  if (onSuccess) onSuccess();

  return dispatch({
    type: USER_UPDATE_CONTACT_ADD_UPDATE_CONTACT_SUCCESS_RECEIVED,
    payload: body.data,
  });
};

export const removeWorkEmail = ({
  workEmailString,
  workEmailContact,
}) => async (dispatch: Function) => {
  const data = {
    body: {
      contactGuid: workEmailContact.contactGuid,
      channelCode: 'SMTP',
      typeCode: 'WE',
      contactInfo: workEmailString,
    },
  };
  const [{ response, body }] = await to(
    dispatch(userDeleteContactRequest(data))
  );
  dispatch({ type: USER_CLEAR_WORKEMAIL });
  if (!body || response.status !== 200)
    return Promise.reject({ response, body });
  return Promise.resolve({ response, body });
};

export const updateUserGovernmentEmployeeStatus = (
  isGovernmentEmployee: boolean
) => async (dispatch: Function) => {
  const [{ response, body }] = await to(
    dispatch(updateProfileInfo({ isGovernmentEmployee }))
  );

  if (!body || response.status !== 200)
    return Promise.reject({ response, body });
  if (isGovernmentEmployee === false) dispatch({ type: USER_CLEAR_WORKEMAIL });
  return Promise.resolve({ response, body });
};
