// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import Select from 'react-select';
import AsyncSelect from 'react-select/lib/Async';

// Styles
import './input.scss';

const Input = ({
  as,
  inputRef,
  className,
  mask,
  guide,
  name,
  id,
  ...props
}) => {
  const tags = { Select };
  const T = props.loadOptions ? AsyncSelect : tags[as] || as;

  return mask ? (
    <MaskedInput
      {...props}
      ref={inputRef}
      mask={mask}
      guide={guide}
      id={id}
      name={name}
      className={className}
      aria-label={name}
    />
  ) : (
    <T
      {...props}
      ref={inputRef}
      id={id}
      name={name}
      className={className}
      aria-label={name}
    />
  );
};

Input.propTypes = {
  as: PropTypes.string.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  mask: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.instanceOf(RegExp), PropTypes.string])
  ),
  guide: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  children: PropTypes,
  inputRef: PropTypes.func,
  loadOptions: PropTypes.func,
};

Input.defaultProps = {
  as: 'input',
  type: 'text',
};

export default Input;
