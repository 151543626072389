//@flow

export function deleteCookie(name: string) {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}

export function deleteLocalStorageTokens() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
}

export function getQueryStringParams(query: string) {
  query = query.substring(query.indexOf('?'), query.length);
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split('&')
        .reduce((params, param) => {
          let [key, value] = param.split('=');
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, ' '))
            : '';
          return params;
        }, {})
    : {};
}

// Reading a dimension prop will cause the browser to recalculate, which will let our animations work.
// Reference: https://github.com/react-bootstrap/react-bootstrap/blob/master/src/utils/triggerBrowserReflow.js
export function triggerBrowserReflow(node: Node) {
  node.offsetHeight; // eslint-disable-line no-unused-expressions
}

export function isTouchCapable() {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
}

export function isMobileDevice() {
  try {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  } catch (e) {
    return false;
  }
}

export function iOSVersion() {
  const matches = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
  if (matches) return `${matches[1]}.${matches[2] ? matches[2] : 0}`;
  return false;
}

export function checkBrowserFeatures(
  options: { cssProps: Array<string> } = {
    cssProps: ['-webkit-box', '-ms-flexbox', 'flex'],
  }
) {
  const invalidUserAgents = ['MSIE 7', 'MSIE 8', 'MSIE 9', 'MSIE 10'];

  var i;
  for (i = 0; i < invalidUserAgents.length; i++) {
    if (navigator.userAgent.indexOf(invalidUserAgents[i]) > -1) return false;
  }

  const element = document.createElement('b');

  let index = options.cssProps.length;

  try {
    while (index--) {
      const cssProp = options.cssProps[index];
      element.style.display = cssProp;
      return element.style.display === cssProp;
    }
  } catch (err) {
    return false;
  }
}

export function nodeListToArray(nodeList: NodeList<Node>) {
  return [].slice.call(nodeList);
}

export function isVisibleElement(element: Node) {
  return !!element.offsetHeight && !!element.offsetWidth;
}

export function getFocusableElements(parentNode: Node = document) {
  const focusableElements = nodeListToArray(
    parentNode.querySelectorAll(
      'button, a, input, select, textarea, [tabindex]:not([tabindex="-1"])'
    )
  );
  return focusableElements.filter(element => isVisibleElement(element));
}

export function focusFirstElement(parentNode: Node = document) {
  if (document.activeElement && document.activeElement.tagName === 'INPUT')
    return;
  const focusableElements = getFocusableElements(parentNode);
  if (focusableElements.length) focusableElements[0].focus();
}

export function scrollToTop(element: Node = window) {
  if (!element) return;
  if (element !== window && element instanceof HTMLElement)
    return (element.scrollTop = 0);
  element.scrollTo(0, 0);
}

// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433
export function isElementInViewport(element: Node) {
  const rect = element.getBoundingClientRect();

  if (!document || !document.documentElement) return false;

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /*or $(window).height() */ &&
    rect.right <=
      (window.innerWidth ||
        document.documentElement.clientWidth) /*or $(window).width() */
  );
}
