// Vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { ToggleGroup } from '../../..';
import SummaryCard from 'texkit/dist/components/Cards/SummaryCard/SummaryCard';

// Types
import { formikInjectedPropsTypes } from '../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Editable Summary Card
 */
const SummaryCardEditGroup = ({
  fieldGroupPath,
  formikProps,
  open: OpenComponent,
  closed: ClosedComponent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  if (!formikProps.getFieldValue(fieldGroupPath)) return null;

  // when we get validation errors from the server this will currently open all of the summary cards
  const fieldError =
    formikProps.getFieldError(fieldGroupPath) ||
    formikProps.getFieldStatus('serverErrors');
  const hasError = fieldError && formikProps.submitCount > 0;

  if (!isOpen && hasError) setIsOpen(true);

  return (
    <ToggleGroup
      isOpen={isOpen}
      open={() => (
        <OpenComponent
          fieldGroupPath={fieldGroupPath}
          formikProps={formikProps}
        />
      )}
      closed={() => (
        <ClosedComponent
          fieldGroupPath={fieldGroupPath}
          formikProps={formikProps}
        />
      )}
      className={'toggle-group-edit'}
    >
      <SummaryCard />
    </ToggleGroup>
  );
};

SummaryCardEditGroup.propTypes = {
  fieldGroupPath: PropTypes.string.isRequired,
  formikProps: formikInjectedPropsTypes.isRequired,
  open: PropTypes.node.isRequired,
  closed: PropTypes.node.isRequired,
};

export default SummaryCardEditGroup;
