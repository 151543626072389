// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import InputRadio from './InputRadio';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Inverse Radio Button
 */
const InputRadioInverse = ({ className, ...props }) => (
  <InputRadio
    {...props}
    className={classnames('input-radio-inverse', className)}
  />
);

InputRadioInverse.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
};

export default InputRadioInverse;
