// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Components
import { Modal, FormActions, FormActionsItem, Trans } from '../..';
import { Button, ButtonPrimary } from 'texkit/dist/components/Buttons';

// Styles
import '../VerifyAddressModal/verify-address-modal.scss';
import { states, counties } from '../../../../lib/data/agencyData/BON';

function AddressCantConfirm(props) {
  const {
    onSuccess,
    closeModal,
    id = 'AddressCantConfirm',
    modal: { data },
    ...rest
  } = props;

  if (!data || !data.enteredAddress) return false;

  const handleSuccess = () => {
    closeModal(id);
    onSuccess({
      ...data.enteredAddress,
      state:
        states.find(state => state.value === data.enteredAddress.state) || null,
      county:
        counties.find(county => county.label === data.enteredAddress.county) ||
        null,
    });
  };

  const close = () => {
    closeModal(id);
  };

  return (
    <Modal
      {...rest}
      id={id}
      disableOnClickOutside={true}
      className="verify-address-modal"
    >
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="AddressCantConfirmTitle" />
      </h2>
      <div className="modal-content">
        <div>
          <h3 style={{ marginTop: 0 }}>
            <Trans file="Modals" id="AddressTwoInvalidBody" />
          </h3>
          <h4>
            {data.enteredAddress.streetAddress} <br />
            {data.enteredAddress.addressLine2}
            {data.enteredAddress.addressLine2 &&
            data.enteredAddress.addressLine2.length ? (
              <br />
            ) : (
              ''
            )}
            {data.enteredAddress.city}, {data.enteredAddress.state}{' '}
            {data.enteredAddress.zipFive} {data.enteredAddress.county}
          </h4>
        </div>
        <FormActions align="center">
          <FormActionsItem>
            <ButtonPrimary onClick={close}>
              <Trans file="Labels" id="Edit" />
            </ButtonPrimary>
          </FormActionsItem>
          <FormActionsItem>
            <Button onClick={handleSuccess}>
              <Trans file="Labels" id="Confirm" />
            </Button>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ modal }) => ({ modal });
const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeModal }, dispatch);

AddressCantConfirm.propTypes = {
  id: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  modal: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressCantConfirm);
