// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles
import './container.scss';

/**
 * @visibleName
 */
const Container = ({ className, ...props }) => (
  <div {...props} className={classnames('container', className)} />
);

Container.propTypes = {
  className: PropTypes.string,
};

export default Container;
