import React from 'react';
import PropTypes from 'prop-types';
import './tabbed.scss';

function Tabbed({ config, handleTabClick, activeTab }) {
  return (
    <div className="tabbed">
      <nav>
        <ul className="tabbed__nav-list">
          {config.map(({ title, key }, index) => {
            return (
              <li key={`tabbed_${index}_${key}`}>
                <button
                  onClick={() => handleTabClick(index)}
                  type="button"
                  className={activeTab === index ? 'active' : null}
                >
                  {title}
                </button>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className="tabbed__content">{config[activeTab].component}</div>
    </div>
  );
}

const configExample = [
  {
    title: '🚫 Oops!',
    component: '{React Component or JSX}',
  },
  {
    title: 'Example',
    component: '{React Component or JSX}',
  },
];

Tabbed.defaultProps = {
  config: [
    {
      title: '🚫 Oops!',
      component: <div>You forgot a config!</div>,
    },
    {
      title: 'Example',
      component: (
        <div>
          <pre>{JSON.stringify(configExample, null, 1)}</pre>
        </div>
      ),
    },
  ],
  // eslint-disable-next-line no-console
  handleTabClick: tab => console.log(`${tab} clicked`),
  activeTab: 0,
};

Tabbed.propTypes = {
  config: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.object,
      component: PropTypes.component,
    })
  ).isRequired,
  handleTabClick: PropTypes.func.isRequired,
  activeTab: PropTypes.number,
};

export default Tabbed;
