// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import {
  Trans,
  FormActions,
  FormActionsItem,
} from '../../../../components/common';
import { ButtonPrimary, TextButton } from 'texkit/dist/components/Buttons';
import { OnboardingPhoneStepWrapper } from './index';
import { useProtectOnboardingPhone } from '../../useProtectOnboardingPhone';

// Onboarding Steps
import { ONBOARDING_STEP_ROUTES, ONBOARDING_SAVE_POINT_NAMES } from '..';

// Actions
import { onboardingSaveProgress } from '../../../../reducers/onboarding/onboardingActions';

// Graphics
import { ReactComponent as OnboardingPhoneSvg } from '../../../../assets/onboarding/onboarding-phone.svg';

const OnboardingPhone = () => {
  const dispatch = useDispatch();
  const {
    user: {
      profile: { cacheExist },
    },
    onboarding: { isGovernmentEmployee },
  } = useSelector(state => state);
  const handleNextClick = () =>
    dispatch(push(`/getting-started/${ONBOARDING_STEP_ROUTES.PHONE_CAPTURE}`));

  useProtectOnboardingPhone();

  return (
    <OnboardingPhoneStepWrapper optin="false">
      <div className="modal-graphic">
        <OnboardingPhoneSvg role="presentation" />
      </div>
      <FormActions align="center">
        <FormActionsItem>
          <ButtonPrimary onClick={handleNextClick}>
            <Trans file="Labels" id="Next" fallback="Next" />
          </ButtonPrimary>
        </FormActionsItem>
      </FormActions>
      <div className="page-links">
        <TextButton
          onClick={async () => {
            await dispatch(
              onboardingSaveProgress(
                ONBOARDING_SAVE_POINT_NAMES.PHONE_NUM_VALIDATION
              )
            );
            if (cacheExist || !isGovernmentEmployee) {
              dispatch(
                push(`/getting-started/${ONBOARDING_STEP_ROUTES.START_LINKING}`)
              );
            } else {
              await dispatch(
                onboardingSaveProgress(ONBOARDING_SAVE_POINT_NAMES.COMPLETE)
              );
            }
          }}
        >
          <Trans file="Labels" id="SkipForNow" fallback="Skip for now" />
        </TextButton>
      </div>
    </OnboardingPhoneStepWrapper>
  );
};

export default OnboardingPhone;
