// Helpers
import { to, toCamel } from '../../lib/helpers';
import { gaActions } from '../gaActionTypes';

// Requests
import {
  getAllAgenciesRequest,
  getAllAppsRequest,
  getMyAppsRequest,
  lookupAppLinkRequest,
  unlinkAppLinkRequest,
} from '../../backend/requests';

// Consts
import { APP_TRANSACTION_IDS } from '../app/appActions';
import { openModal } from '../../reducers/modal/modalActions';
import { bodyHasError } from '../../lib/validation/serverErrors';
import { storeAlertInfo } from '../alert/alertActions';
export const AGENCY_STORE_ALL_AGENCIES = '[AGENCY] Store All Agencies';
export const AGENCY_STORE_ALL_APPS = '[AGENCY] Store All Apps';
export const AGENCY_STORE_MY_APPS = '[AGENCY] Store My Apps';
export const AGENCY_STORE_AGENCY = '[AGENCY] Store Agency';
export const AGENCY_STORE_LINK = '[AGENCY] Store Link';
export const AGENCY_REMOVE_LINK_BY_USERKEY = `[AGENCY] ${gaActions.click} Remove Link By Userkey`;
export const AGENCY_UPDATE_LINK_DATA = '[AGENCY] Update Links';
export const AGENCY_CLEAR_DATA = '[AGENCY] Clear Data';

export const getAllAgencies = () => async dispatch => {
  const [{ response, body }, err] = await to(dispatch(getAllAgenciesRequest()));
  if (err) return Promise.reject(err);
  dispatch({ type: AGENCY_STORE_ALL_AGENCIES, payload: body.data });
  return Promise.resolve({ response, body });
};

export const getAllApps = () => async dispatch => {
  const [{ response, body }, err] = await to(dispatch(getAllAppsRequest()));
  if (err) return Promise.reject(err);
  dispatch({ type: AGENCY_STORE_ALL_APPS, payload: body.data });
  return Promise.resolve({ response, body });
};

export const getMyApps = () => async dispatch => {
  const [{ response, body }, err] = await to(dispatch(getMyAppsRequest()));
  if (err) return Promise.reject(err);
  dispatch({ type: AGENCY_STORE_MY_APPS, payload: body.data });
  return Promise.resolve({ response, body });
};

export const unlinkAppLink = ({ userKey }, linkKey) => async dispatch => {
  const [{ response, body }, err] = await to(
    dispatch(
      unlinkAppLinkRequest({
        userKey,
        linkKey: linkKey ? linkKey : '',
      })
    )
  );
  if (err) return Promise.reject(err);
  return Promise.resolve({ response, body });
};

export const removeLinkFromState = link => dispatch =>
  dispatch({ type: AGENCY_REMOVE_LINK_BY_USERKEY, payload: link });

export const storeLink = link => dispatch =>
  dispatch({ type: AGENCY_STORE_LINK, payload: link });

export const lookupBasicLinkData = links => async dispatch => {
  const apps = [];

  links.forEach(link => {
    const toPush = {
      appId: link.appId,
      userKeys: [link.userKey],
    };
    if (apps.some(app => app.appId === toPush.appId)) {
      apps.find(app => app.appId === toPush.appId).userKeys.push(link.userKey);
    } else {
      apps.push({
        appId: link.appId,
        userKeys: [link.userKey],
      });
    }
  });

  const results = apps.map(async link => {
    let { appId, userKeys } = link;

    const [{ body }, error] = await to(
      dispatch(
        lookupAppLinkRequest({
          appId,
          userKeys,
          transactionId: APP_TRANSACTION_IDS.BASIC_INFO,
        })
      )
    );
    if (bodyHasError(body, { errorCodes: ['MGMW_EX_3007'] })) {
      dispatch(
        storeAlertInfo({
          type: 'warning',
          title: { file: 'Alerts', id: 'SorryTitle' },
          message: { file: 'Alerts', id: 'LinkNotFound' },
          closeable: true,
        })
      );
      return Promise.reject(error);
    }
    if (bodyHasError(body, { errorCodes: ['MGMW_EX_5001'] })) {
      dispatch(
        storeAlertInfo({
          type: 'warning',
          title: { file: 'Alerts', id: 'SorryTitle' },
          message: { file: 'Alerts', id: 'AgencyNotFound' },
          closeable: true,
        })
      );
      return Promise.reject(error);
    }
    if (error) return Promise.reject(error);

    if (bodyHasError(body, { errorCodes: ['MGMW_EX_5002'] })) {
      dispatch(
        storeAlertInfo({
          type: 'warning',
          title: { file: 'Alerts', id: 'SorryTitle' },
          message: { file: 'Alerts', id: 'ApplicationNotFound' },
          closeable: true,
        })
      );
      return Promise.reject(error);
    }

    if (bodyHasError(body, { errorCodes: ['MGMW_EX_3014', 'MGMW_EX_4000'] })) {
      dispatch(openModal('VehicleNotFoundModal'));
      return Promise.reject(error);
    }

    if (error) return Promise.reject(error);

    const linkData = Array.isArray(body.data)
      ? { ...link, profiles: [...body.data] }
      : { ...link, ...body.data };

    const linkData2 = toCamel(linkData);

    return Promise.resolve(linkData2);
  });

  return Promise.all(results).then(updatedLinks => {
    dispatch({ type: AGENCY_UPDATE_LINK_DATA, payload: updatedLinks });
    return Promise.resolve(updatedLinks);
  });
};

export const clearAgencyData = () => dispatch =>
  dispatch({ type: AGENCY_CLEAR_DATA });
