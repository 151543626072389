// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputWrapper, Trans } from '../../..';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Phone
 */
const InputPhone = ({ className, ...props }) => (
  <InputWrapper
    {...props}
    type="tel"
    mask={[
      /[1-9]/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]}
    className={classnames('input-phone', className)}
  />
);

InputPhone.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
  label: PropTypes.any,
};

InputPhone.defaultProps = {
  label: <Trans file="Labels" id="PhoneNumber" fallback="Phone Number" />,
};

export default InputPhone;
