//@flow

// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import { Form, Trans, InputRadioGroup } from '../../../../components/common';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { ONBOARDING_STEP_ROUTES } from '..';

// Helpers
import { useProtectOnboardingEmail } from '../../useProtectOnboardingEmail';
import { useRedirect } from '../../../../hooks';

// Actions
import { onboardingSaveGovEmploymentStatus } from '../../../../reducers/onboarding/onboardingActions';

const OnboardingGovEmploymentStatus = () => {
  useProtectOnboardingEmail();
  const dispatch = useDispatch();
  const inputOptions = [
    {
      id: 'input-gov-employee-yes',
      label: <Trans file="Labels" id="Yes" fallback="Yes" />,
      value: 'true',
    },
    {
      id: 'input-gov-employee-no',
      label: <Trans file="Labels" id="No" fallback="No" />,
      value: 'false',
    },
  ];
  const { onboarding } = useSelector(state => state);
  const { contacts } = useSelector(state => state.user);
  useRedirect(
    contacts && contacts.workEmail,
    `/getting-started/${ONBOARDING_STEP_ROUTES.SECURITY_QUESTIONS}`
  ); // To prevent the user from changing his answer once he has confirmed his gov email address

  return (
    <>
      <h2 className="h1">
        <Trans
          file="Onboarding"
          id="GovEmploymentStatusTitle"
          fallback="Are you a public-sector employee?"
        />
      </h2>
      <p className="lead">
        <Trans
          file="Onboarding"
          id="GovEmploymentStatusDescription"
          fallback="If you are a state or local government employee, additional functionality may be available to you."
        />
      </p>
      <div className="onboarding-body">
        <Form
          className="gov-employment-status-form"
          formikConfig={{
            onSubmit: async values => {
              const { isGovernmentEmployee } = values;

              const nextStep =
                isGovernmentEmployee === 'true'
                  ? ONBOARDING_STEP_ROUTES.GOV_EMAIL_CAPTURE
                  : ONBOARDING_STEP_ROUTES.SECURITY_QUESTIONS;

              // compare new value with value in state
              if (isGovernmentEmployee !== onboarding.isGovernmentEmployee) {
                await dispatch(
                  onboardingSaveGovEmploymentStatus({ isGovernmentEmployee })
                );
              }

              dispatch(push(`/getting-started/${nextStep}`));
            },
            validationSchema: null,
            initialValues: {
              // `+ ''` converts the boolean value to a string to use as value
              isGovernmentEmployee:
                typeof onboarding.isGovernmentEmployee === 'undefined'
                  ? null
                  : onboarding.isGovernmentEmployee + '',
            },
          }}
          render={({
            FormError,
            FormActions,
            FormActionsItem,
            formikProps,
          }) => (
            <>
              <InputRadioGroup
                name="isGovernmentEmployee"
                formikProps={formikProps}
                options={inputOptions}
                inline={true}
                spacedEvenly={true}
              />
              <FormError />
              <FormActions align="center">
                <FormActionsItem>
                  <ButtonPrimary
                    type="submit"
                    isLoading={formikProps.isSubmitting}
                    loadingContent={<Trans file="Labels" id="Submitting" />}
                    disabled={formikProps.values.isGovernmentEmployee === null}
                  >
                    <Trans file="Labels" id="Next" fallback="Next" />
                  </ButtonPrimary>
                </FormActionsItem>
              </FormActions>
            </>
          )}
        />
      </div>
    </>
  );
};

export default OnboardingGovEmploymentStatus;
