//@flow

// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace, push } from 'connected-react-router';

// Actions
import { onboardingSaveProgress } from '../../../../reducers/onboarding/onboardingActions';
import { verifyAppLinkOnBoarding } from '../../../../reducers/agency/linkService/linkServiceActions';

// Helpers
import { ONBOARDING_SAVE_POINT_NAMES } from '..';
import { LINK_SERVICES_STEPS } from '../../../../../src/pages/LinkServicePage/LinkServiceSteps';

// Graphics
import { ReactComponent as OnboardingLinkServicesSvg } from '../../../../assets/onboarding/onboarding-link-services.svg';

// Components
import {
  FormActions,
  FormActionsItem,
  Trans,
} from '../../../../components/common';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

const OnboardingStartLinking = () => {
  const dispatch = useDispatch();
  const {
    user: {
      profile: {
        cacheExist,
        cacheInfo: { dlNumber: dlIDNumber, dob: dob, lastFourSSN: last4ssn },
      },
    },
    onboarding: { isGovernmentEmployee },
  } = useSelector(state => state);
  return (
    <>
      <h2 className="h1 title">
        <Trans
          file="Onboarding"
          id="CompleteTitle"
          fallback="Get the most out of your account."
        />
      </h2>
      <>
        <p className="lead">
          <Trans
            file="Onboarding"
            id="CompleteDescription"
            fallback="You created an account and verified your identity. Now link your driver license or ID card to your account to get faster and simpler renewals."
          />
        </p>
        <div className="onboarding-body">
          <div className="graphic">
            <OnboardingLinkServicesSvg role="presentation" />
          </div>
          <FormActions align="center">
            <FormActionsItem>
              <ButtonPrimary
                onClick={async () => {
                  await dispatch(
                    onboardingSaveProgress(ONBOARDING_SAVE_POINT_NAMES.COMPLETE)
                  );
                  const values = { dlIDNumber, dob, last4ssn };
                  if (cacheExist) {
                    dispatch(verifyAppLinkOnBoarding(values)).then(() =>
                      dispatch(push(`/link/${LINK_SERVICES_STEPS.CONFIRM}`))
                    );
                    // dispatch(replace('/link/lookup/dlrlr'));
                  } else {
                    dispatch(replace('/link'));
                  }
                }}
              >
                <Trans file="Labels" id="okLetsGo" />
              </ButtonPrimary>
            </FormActionsItem>
          </FormActions>
          {/* <div className="page-links">
            <TextButton
              onClick={() => {
                dispatch(
                  onboardingSaveProgress(ONBOARDING_SAVE_POINT_NAMES.COMPLETE)
                );
              }}
            >
              <Trans
                file="Labels"
                id="IllDoThisLater"
                fallback="No thanks, I'll do this later."
              />
            </TextButton>
          </div> */}
        </div>
      </>
    </>
  );
};

export default OnboardingStartLinking;
