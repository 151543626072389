// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { InputRadioGroup, Trans } from '../../../../..';

/**
 * @visibleName Address Type
 */
const InputRadioGroupAddressLocation = ({
  fieldGroupPath = '',
  formikProps,
  className,
  inline = true,
  ...props
}) => {
  if (fieldGroupPath) fieldGroupPath = `${fieldGroupPath}.`;

  return (
    <InputRadioGroup
      {...props}
      className={classnames('input-radio-group-address-type', className)}
      name={`${fieldGroupPath}addressLocation`}
      formikProps={formikProps}
      inline={inline}
      options={[
        {
          id: `${fieldGroupPath}domesticAddress`,
          label: (
            <Trans file="Labels" id="DomesticAddress" fallback="U.S. Address" />
          ),
          value: 'domestic',
        },
        {
          id: `${fieldGroupPath}foreignAddress`,
          label: (
            <Trans
              file="Labels"
              id="ForeignAddress"
              fallback="International Address"
            />
          ),
          value: 'foreign',
        },
      ]}
    />
  );
};

InputRadioGroupAddressLocation.propTypes = {
  fieldGroupPath: PropTypes.string,
  formikProps: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default InputRadioGroupAddressLocation;
