export const dmvrr = {
  agencySlug: 'dmv',
  appCode: 'DMV_RR',
  slug: 'dmvrr',
  linkService: {
    linkType: 'registration',
    form: {
      labels: {
        lastFourOfVIN: { id: 'lastFourOfVIN' },
        plateNumber: { id: 'plateNumber' },
        ownerIdentification: { id: 'ownerIdentification' },
      },
    },
  },
};
