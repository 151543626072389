// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import { Modal } from '../../..';

// Components
import { FormActions, FormActionsItem, Trans } from '../../../..';
import { Button, ButtonPrimary } from 'texkit/dist/components/Buttons';

//Actions
import { closeModal } from '../../../../../../reducers/modal/modalActions';

const RenewalSpeedbumpModal = () => {
  const dispatch = useDispatch();

  const handleExitClick = () => {
    window.onunload = window.onbeforeunload = null;
    dispatch(closeModal('RenewalSpeedbumpModal'));
    dispatch(push('/dashboard'));
  };

  return (
    <Modal name="RenewalSpeedbumpModal" className="renewal-speedbump-modal">
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="renewal_speedbump_Title" />
      </h2>
      <div className="modal-content">
        <p className="lead" style={{ maxWidth: '570px', margin: '0 auto' }}>
          <Trans file="Modals" id="renewal_speedbump_Body" />
        </p>
      </div>
      <FormActions align="center">
        <FormActionsItem>
          <ButtonPrimary onClick={handleExitClick}>
            <Trans file="Labels" id="exitRenewal" />
          </ButtonPrimary>
        </FormActionsItem>
        <FormActionsItem>
          <Button onClick={() => dispatch(closeModal('RenewalSpeedbumpModal'))}>
            <Trans file="Labels" id="continueRenewal" />
          </Button>
        </FormActionsItem>
      </FormActions>
    </Modal>
  );
};

export default RenewalSpeedbumpModal;
