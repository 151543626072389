export function selectLicense(state) {
  return state.dlr.selected;
}

export function selectAppProfile({ dlr: { appProfile } }) {
  return appProfile;
}

export function selectTotalFee({ dlr: { donationValues: { total } = {} } }) {
  return total;
}

export function selectSurrenderCardOption({
  dlr: { surrenderCardOption = false },
}) {
  return surrenderCardOption;
}

export function selectDonationValues({ dlr: { donationValues = {} } }) {
  return donationValues;
}

export function selectDonationOptions({ dlr: { donationOptions = {} } }) {
  return donationOptions;
}

export function selectOrganDonorOption({ dlr: { organDonorOption = false } }) {
  return organDonorOption;
}

export function selectVoterRegistration({
  dlr: { voterRegistration = false },
}) {
  return voterRegistration;
}

export function selectVolunteerElectionJudge({
  dlr: { volunteerElectionJudge = false },
}) {
  return volunteerElectionJudge;
}

export function selectVoterRegistrationAppType({
  dlr: { voterRegistrationAppType = false },
}) {
  return voterRegistrationAppType;
}

export function selectEmergencyContacts({ dlr: { emergencyContacts = {} } }) {
  return emergencyContacts;
}

export function selectRenewAddressChangeFlag({
  dlr: { renewAddressChangeFlag = false },
}) {
  return renewAddressChangeFlag;
}

export function selectRenewalHomeAddress({ dlr: { renewalHomeAddress = {} } }) {
  return renewalHomeAddress;
}

export function selectRenewalMailingAddress({
  dlr: { renewalMailingAddress = {} },
}) {
  return renewalMailingAddress;
}

export function selectMailingSameAsHomeAddress({
  dlr: { mailingSameAsHomeAddress = false },
}) {
  return mailingSameAsHomeAddress;
}
