// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
import HeroActionsSingle from './HeroActionsSingle';
import HeroActionsMultiple from './HeroActionsMultiple';

// Types
import { HeroActionPropType } from '../../../lib/validation/propTypes/heroPropTypes';

const HeroActions = ({ actions }) => {
  if (!actions) return null;

  return (
    <div className="hero-actions">
      {Array.isArray(actions) ? (
        <HeroActionsMultiple actions={actions} />
      ) : (
        <HeroActionsSingle {...actions} />
      )}
    </div>
  );
};

HeroActions.propTypes = {
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(HeroActionPropType),
    HeroActionPropType,
  ]),
};

export default HeroActions;
