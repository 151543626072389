// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Assets
import { ReactComponent as LogoSvg } from '../../../assets/logo.svg';

// Styles
import './logo.scss';

/**
 * @visibleName Default
 */
const Logo = ({ className }) => (
  <span className={classnames('logo', className)}>
    <LogoSvg role="presentation" />
  </span>
);

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
