// @flow

// Vendors
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push, goBack } from 'connected-react-router';

// Components
import {
  Trans,
  InputGroupPhoneCell,
  Form,
} from '../../../../components/common';
import {
  Button,
  TextButton,
  ButtonPrimary,
} from 'texkit/dist/components/Buttons';
import { ONBOARDING_SAVE_POINT_NAMES, ONBOARDING_STEP_ROUTES } from '..';
import { OnboardingPhoneStepWrapper } from './index';

// Helpers
import { useProtectOnboardingPhone } from '../../useProtectOnboardingPhone';
import { phoneValidationSchema } from '../../../../lib/validation';

// Actions
import {
  updateOnboarding,
  onboardingSendPhoneOTP,
  onboardingSaveProgress,
} from '../../../../reducers/onboarding/onboardingActions';
import { updateContactSubmitRequest } from '../../../../reducers/user/updateContact/updateContactActions';

const OnboardingPhoneCapture = () => {
  const dispatch = useDispatch();
  const { userEmail } = useSelector(state => state.user);
  const { phone } = useSelector(state => state.onboarding);
  const {
    user: {
      profile: { cacheExist },
    },
    onboarding: { isGovernmentEmployee },
  } = useSelector(state => state);

  useProtectOnboardingPhone();

  function getInitialValues() {
    return {
      phone: phone ? phone.phone : '',
      phoneType: phone ? phone.phoneType : 'SMS',
    };
  }

  async function handleSubmit(values, formikActions: FormikActions) {
    dispatch(updateOnboarding({ phone: values }));
    const typeCode = values.phoneType === 'SMS' ? 'PM' : 'PL';
    if (typeCode === 'PL') {
      let props = {
        userEmail,
        phone: {
          userEmail,
          contactGuid: '',
          channelCode: 'TALK',
          contactInfo: values.phone,
          typeCode: 'PL',
          verifiedSwitch: 'N',
        },
      };
      await dispatch(
        updateContactSubmitRequest({ values, formikActions, props })
      );
      await dispatch(
        onboardingSaveProgress(ONBOARDING_SAVE_POINT_NAMES.PHONE_NUM_VALIDATION)
      );
      formikActions.setSubmitting(false);
      return dispatch(
        push(`/getting-started/${ONBOARDING_STEP_ROUTES.START_LINKING}`)
      );
    }
    await dispatch(
      onboardingSendPhoneOTP({
        values,
        formikActions,
        props: { userEmail },
      })
    );
    formikActions.setSubmitting(false);
    return dispatch(
      push(`/getting-started/${ONBOARDING_STEP_ROUTES.PHONE_VERIFY}`)
    );
  }

  function handleBackClick() {
    dispatch(goBack());
  }

  return (
    <OnboardingPhoneStepWrapper optin="true">
      <Form
        formikConfig={{
          onSubmit: handleSubmit.bind(this),
          initialValues: getInitialValues(),
          validationSchema: phoneValidationSchema,
        }}
        render={({ FormError, FormActions, FormActionsItem, formikProps }) => (
          <>
            <InputGroupPhoneCell formikProps={formikProps} />
            <FormError />
            <FormActions align="center">
              <FormActionsItem>
                <ButtonPrimary
                  type="submit"
                  isLoading={formikProps.isSubmitting}
                  loadingContent={
                    <Trans file="Labels" id="Loading" fallback="Loading" />
                  }
                >
                  <Trans file="Labels" id="Next" fallback="Next" />
                </ButtonPrimary>
              </FormActionsItem>
              <FormActionsItem>
                <Button
                  onClick={handleBackClick}
                  disabled={formikProps.isSubmitting}
                >
                  <Trans file="Labels" id="Back" fallback="Back" />
                </Button>
              </FormActionsItem>
            </FormActions>
          </>
        )}
      />
      <TextButton
        onClick={async () => {
          await dispatch(
            onboardingSaveProgress(
              ONBOARDING_SAVE_POINT_NAMES.PHONE_NUM_VALIDATION
            )
          );
          if (cacheExist || !isGovernmentEmployee) {
            dispatch(
              push(`/getting-started/${ONBOARDING_STEP_ROUTES.START_LINKING}`)
            );
          } else {
            await dispatch(
              onboardingSaveProgress(ONBOARDING_SAVE_POINT_NAMES.COMPLETE)
            );
          }
        }}
      >
        <Trans file="Labels" id="SkipForNow" fallback="Skip for now" />
      </TextButton>
    </OnboardingPhoneStepWrapper>
  );
};

export default OnboardingPhoneCapture;
