// Vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Components
import { Modal, FormActions, FormActionsItem, Trans } from '../..';
import { ButtonOutline, ButtonPrimary } from 'texkit/dist/components/Buttons';

// Styles
import './address-error-generic.scss';
import { states, counties } from '../../../../lib/data/agencyData/BON';

function AddressErrorGenericModal(props) {
  const {
    onSuccess,
    closeModal,
    id = 'AddressErrorGenericModal',
    modal: { data },
    ...rest
  } = props;

  if (!data || !data.enteredAddress) return false;
  if (data.enteredAddress.state && data.enteredAddress.state.value)
    data.enteredAddress = {
      ...data.enteredAddress,
      state: data.enteredAddress.state.value,
    };

  const [selectedAddress, setSelectedAddress] = useState(
    data.enteredAddress || null
  );

  function handleSuccess() {
    closeModal(id);
    onSuccess({
      ...selectedAddress,
      verifySkip: true,
      state:
        states.find(state => state.value === selectedAddress.state) || null,
      county:
        counties.find(county => county.label === selectedAddress.county) ||
        null,
    });
    setSelectedAddress(data.enteredAddress || null);
  }

  const close = () => {
    closeModal(id);
  };

  return (
    <Modal
      {...rest}
      id={id}
      disableOnClickOutside={true}
      className="verify-address-modal address-error-generic"
    >
      <h2 className="h1 modal-title">
        <Trans
          file="Modals"
          id="AddressCantConfirmTitle"
          fallback="We can’t confirm your address"
        />
      </h2>
      <p>
        <Trans
          file="Modals"
          id="AddressCantConfirmBody"
          fallback="Check that your address is correct and select “Edit” if you need to update your address. If it’s correct, select “Confirm” to continue."
        />
      </p>
      <p>
        <b>
          {data.enteredAddress.streetAddress} <br />
          {data.enteredAddress.addressLine2 && (
            <>
              {data.enteredAddress.addressLine2} <br />
            </>
          )}
          {data.enteredAddress.city},&nbsp;
          {data.enteredAddress.state} {data.enteredAddress.zipFive}
          &nbsp;
          {data.enteredAddress.county}
        </b>
      </p>
      <FormActions align="center">
        <FormActionsItem>
          <ButtonPrimary onClick={close}>
            <Trans file="Labels" id="Edit" fallback="Edit" />
          </ButtonPrimary>
        </FormActionsItem>
        <FormActionsItem>
          <ButtonOutline onClick={handleSuccess}>
            <Trans file="Labels" id="Confirm" fallback="Confirm" />
          </ButtonOutline>
        </FormActionsItem>
      </FormActions>
    </Modal>
  );
}

const mapStateToProps = ({ modal }) => ({ modal });
const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeModal }, dispatch);

AddressErrorGenericModal.propTypes = {
  id: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressErrorGenericModal);
