//@flow

// Vendors
import React from 'react';
import { components } from 'react-select';

const InputSelectMenu = ({ isMobileDevice, ...props }: Object) =>
  components.Menu && (
    <components.Menu
      {...props}
      className={
        isMobileDevice
          ? 'input-select__menu--mobile'
          : 'input-select__menu--desktop'
      }
    >
      <div className="input-select__menu-list-wrapper">{props.children}</div>
    </components.Menu>
  );

export default InputSelectMenu;
