// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const FormActionsItem = ({ fullWidth, className, ...props }) => (
  <div
    {...props}
    className={classnames(
      'form-actions-item',
      { 'form-actions-item-full-width': fullWidth },
      className
    )}
  />
);

FormActionsItem.propTypes = {
  fullWidth: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
};

export default FormActionsItem;
