// Agency specific counties should not be created in the front end.
// Rather the backend should map the UI county codes into agency specific correct county codes before storing.
// This needs to be fixed in M&O. The change involves both frontend and backend.

export default [
  { label: 'Anderson', value: '001' },
  { label: 'Andrews', value: '003' },
  { label: 'Angelina', value: '005' },
  { label: 'Aransas', value: '007' },
  { label: 'Archer', value: '009' },
  { label: 'Armstrong', value: '011' },
  { label: 'Atascosa', value: '013' },
  { label: 'Austin', value: '015' },
  { label: 'Bailey', value: '017' },
  { label: 'Bandera', value: '019' },
  { label: 'Bastrop', value: '021' },
  { label: 'Baylor', value: '023' },
  { label: 'Bee', value: '025' },
  { label: 'Bell', value: '027' },
  { label: 'Bexar', value: '029' },
  { label: 'Blanco', value: '031' },
  { label: 'Borden', value: '033' },
  { label: 'Bosque', value: '035' },
  { label: 'Bowie', value: '037' },
  { label: 'Brazoria', value: '039' },
  { label: 'Brazos', value: '041' },
  { label: 'Brewster', value: '043' },
  { label: 'Briscoe', value: '045' },
  { label: 'Brooks', value: '047' },
  { label: 'Brown', value: '049' },
  { label: 'Burleson', value: '051' },
  { label: 'Burnet', value: '053' },
  { label: 'Caldwell', value: '055' },
  { label: 'Calhoun', value: '057' },
  { label: 'Callahan', value: '059' },
  { label: 'Cameron', value: '061' },
  { label: 'Camp', value: '063' },
  { label: 'Carson', value: '065' },
  { label: 'Cass', value: '067' },
  { label: 'Castro', value: '069' },
  { label: 'Chambers', value: '071' },
  { label: 'Cherokee', value: '073' },
  { label: 'Childress', value: '075' },
  { label: 'Clay', value: '077' },
  { label: 'Cochran', value: '079' },
  { label: 'Coke', value: '081' },
  { label: 'Coleman', value: '083' },
  { label: 'Collin', value: '085' },
  { label: 'Collingsworth', value: '087' },
  { label: 'Colorado', value: '089' },
  { label: 'Comal', value: '091' },
  { label: 'Comanche', value: '093' },
  { label: 'Concho', value: '095' },
  { label: 'Cooke', value: '097' },
  { label: 'Coryell', value: '099' },
  { label: 'Cottle', value: '101' },
  { label: 'Crane', value: '103' },
  { label: 'Crockett', value: '105' },
  { label: 'Crosby', value: '107' },
  { label: 'Culberson', value: '109' },
  { label: 'Dallam', value: '111' },
  { label: 'Dallas', value: '113' },
  { label: 'Dawson', value: '115' },
  { label: 'Deaf Smith', value: '117' },
  { label: 'Delta', value: '119' },
  { label: 'Denton', value: '121' },
  { label: 'Dewitt', value: '123' },
  { label: 'Dickens', value: '125' },
  { label: 'Dimmit', value: '127' },
  { label: 'Donley', value: '129' },
  { label: 'Duval', value: '131' },
  { label: 'Eastland', value: '133' },
  { label: 'Ector', value: '135' },
  { label: 'Edwards', value: '137' },
  { label: 'Ellis', value: '139' },
  { label: 'El Paso', value: '141' },
  { label: 'Erath', value: '143' },
  { label: 'Falls', value: '145' },
  { label: 'Fannin', value: '147' },
  { label: 'Fayette', value: '149' },
  { label: 'Fisher', value: '151' },
  { label: 'Floyd', value: '153' },
  { label: 'Foard', value: '155' },
  { label: 'Fort Bend', value: '157' },
  { label: 'Franklin', value: '159' },
  { label: 'Freestone', value: '161' },
  { label: 'Frio', value: '163' },
  { label: 'Gaines', value: '165' },
  { label: 'Galveston', value: '167' },
  { label: 'Garza', value: '169' },
  { label: 'Gillespie', value: '171' },
  { label: 'Glasscock', value: '173' },
  { label: 'Goliad', value: '175' },
  { label: 'Gonzales', value: '177' },
  { label: 'Gray', value: '179' },
  { label: 'Grayson', value: '181' },
  { label: 'Gregg', value: '183' },
  { label: 'Grimes', value: '185' },
  { label: 'Guadalupe', value: '187' },
  { label: 'Hale', value: '189' },
  { label: 'Hall', value: '191' },
  { label: 'Hamilton', value: '193' },
  { label: 'Hansford', value: '195' },
  { label: 'Hardeman', value: '197' },
  { label: 'Hardin', value: '199' },
  { label: 'Harris', value: '201' },
  { label: 'Harrison', value: '203' },
  { label: 'Hartley', value: '205' },
  { label: 'Haskell', value: '207' },
  { label: 'Hays', value: '209' },
  { label: 'Hemphill', value: '211' },
  { label: 'Henderson', value: '213' },
  { label: 'Hidalgo', value: '215' },
  { label: 'Hill', value: '217' },
  { label: 'Hockley', value: '219' },
  { label: 'Hood', value: '221' },
  { label: 'Hopkins', value: '223' },
  { label: 'Houston', value: '225' },
  { label: 'Howard', value: '227' },
  { label: 'Hudspeth', value: '229' },
  { label: 'Hunt', value: '231' },
  { label: 'Hutchinson', value: '233' },
  { label: 'Irion', value: '235' },
  { label: 'Jack', value: '237' },
  { label: 'Jackson', value: '239' },
  { label: 'Jasper', value: '241' },
  { label: 'Jeff Davis', value: '243' },
  { label: 'Jefferson', value: '245' },
  { label: 'Jim Hogg', value: '247' },
  { label: 'Jim Wells', value: '249' },
  { label: 'Johnson', value: '251' },
  { label: 'Jones', value: '253' },
  { label: 'Karnes', value: '255' },
  { label: 'Kaufman', value: '257' },
  { label: 'Kendall', value: '259' },
  { label: 'Kenedy', value: '261' },
  { label: 'Kent', value: '263' },
  { label: 'Kerr', value: '265' },
  { label: 'Kimble', value: '267' },
  { label: 'King', value: '269' },
  { label: 'Kinney', value: '271' },
  { label: 'Kleberg', value: '273' },
  { label: 'Knox', value: '275' },
  { label: 'Lamar', value: '277' },
  { label: 'Lamb', value: '279' },
  { label: 'Lampasas', value: '281' },
  { label: 'Lasalle', value: '283' },
  { label: 'Lavaca', value: '285' },
  { label: 'Lee', value: '287' },
  { label: 'Leon', value: '289' },
  { label: 'Liberty', value: '291' },
  { label: 'Limestone', value: '293' },
  { label: 'Lipscomb', value: '295' },
  { label: 'Live Oak', value: '297' },
  { label: 'Llano', value: '299' },
  { label: 'Loving', value: '301' },
  { label: 'Lubbock', value: '303' },
  { label: 'Lynn', value: '305' },
  { label: 'McCulloch', value: '307' },
  { label: 'McLennan', value: '309' },
  { label: 'McMullen', value: '311' },
  { label: 'Madison', value: '313' },
  { label: 'Marion', value: '315' },
  { label: 'Martin', value: '317' },
  { label: 'Mason', value: '319' },
  { label: 'Matagorda', value: '321' },
  { label: 'Maverick', value: '323' },
  { label: 'Medina', value: '325' },
  { label: 'Menard', value: '327' },
  { label: 'Midland', value: '329' },
  { label: 'Milam', value: '331' },
  { label: 'Mills', value: '333' },
  { label: 'Mitchell', value: '335' },
  { label: 'Montague', value: '337' },
  { label: 'Montgomery', value: '339' },
  { label: 'Moore', value: '341' },
  { label: 'Morris', value: '343' },
  { label: 'Motley', value: '345' },
  { label: 'Nacogdoches', value: '347' },
  { label: 'Navarro', value: '349' },
  { label: 'Newton', value: '351' },
  { label: 'Nolan', value: '353' },
  { label: 'Nueces', value: '355' },
  { label: 'Ochiltree', value: '357' },
  { label: 'Oldham', value: '359' },
  { label: 'Orange', value: '361' },
  { label: 'Palo Pinto', value: '363' },
  { label: 'Panola', value: '365' },
  { label: 'Parker', value: '367' },
  { label: 'Parmer', value: '369' },
  { label: 'Pecos', value: '371' },
  { label: 'Polk', value: '373' },
  { label: 'Potter', value: '375' },
  { label: 'Presidio', value: '377' },
  { label: 'Rains', value: '379' },
  { label: 'Randall', value: '381' },
  { label: 'Reagan', value: '383' },
  { label: 'Real', value: '385' },
  { label: 'Red River', value: '387' },
  { label: 'Reeves', value: '389' },
  { label: 'Refugio', value: '391' },
  { label: 'Roberts', value: '393' },
  { label: 'Robertson', value: '395' },
  { label: 'Rockwall', value: '397' },
  { label: 'Runnels', value: '399' },
  { label: 'Rusk', value: '401' },
  { label: 'Sabine', value: '403' },
  { label: 'San Augustine', value: '405' },
  { label: 'San Jacinto', value: '407' },
  { label: 'San Patricio', value: '409' },
  { label: 'San Saba', value: '411' },
  { label: 'Schleicher', value: '413' },
  { label: 'Scurry', value: '415' },
  { label: 'Shackelford', value: '417' },
  { label: 'Shelby', value: '419' },
  { label: 'Sherman', value: '421' },
  { label: 'Smith', value: '423' },
  { label: 'Somervell', value: '425' },
  { label: 'Starr', value: '427' },
  { label: 'Stephens', value: '429' },
  { label: 'Sterling', value: '431' },
  { label: 'Stonewall', value: '433' },
  { label: 'Sutton', value: '435' },
  { label: 'Swisher', value: '437' },
  { label: 'Tarrant', value: '439' },
  { label: 'Taylor', value: '441' },
  { label: 'Terrell', value: '443' },
  { label: 'Terry', value: '445' },
  { label: 'Throckmorton', value: '447' },
  { label: 'Titus', value: '449' },
  { label: 'Tom Green', value: '451' },
  { label: 'Travis', value: '453' },
  { label: 'Trinity', value: '455' },
  { label: 'Tyler', value: '457' },
  { label: 'Upshur', value: '459' },
  { label: 'Upton', value: '461' },
  { label: 'Uvalde', value: '463' },
  { label: 'Val Verde', value: '465' },
  { label: 'Van Zandt', value: '467' },
  { label: 'Victoria', value: '469' },
  { label: 'Walker', value: '471' },
  { label: 'Waller', value: '473' },
  { label: 'Ward', value: '475' },
  { label: 'Washington', value: '477' },
  { label: 'Webb', value: '479' },
  { label: 'Wharton', value: '481' },
  { label: 'Wheeler', value: '483' },
  { label: 'Wichita', value: '485' },
  { label: 'Wilbarger', value: '487' },
  { label: 'Willacy', value: '489' },
  { label: 'Williamson', value: '491' },
  { label: 'Wilson', value: '493' },
  { label: 'Winkler', value: '495' },
  { label: 'Wise', value: '497' },
  { label: 'Wood', value: '499' },
  { label: 'Yoakum', value: '501' },
  { label: 'Young', value: '503' },
  { label: 'Zapata', value: '505' },
  { label: 'Zavala', value: '507' },
  { label: 'Out of Country/State', value: '999' },
];
