// Actions
import { signOut } from '../auth/authActions';
import { storeAlertInfo } from '../alert/alertActions';
import { closeModal } from '../modal/modalActions';
import moment from 'moment';

import { selectSessionData } from './sessionSelectors';

export const SESSION_UPDATED = '[SESSION] Updated';
export const SESSION_ENDED = '[SESSION] Ended';
export const REFRESHING_TOKEN = '[SESSION] Refreshing Token';

export const updateSession = date => dispatch => {
  dispatch({
    type: SESSION_UPDATED,
    payload: {
      latestAccessTime: moment
        .utc(date)
        .subtract(15, 'minutes')
        .format(),
      maxIdleExpirationTime: moment.utc(date).format(),
      maxSessionExpirationTime: moment
        .utc(date)
        .add(105, 'minutes')
        .format(),
    },
  });
};

export const updateRefreshingSessionFlag = data => dispatch => {
  dispatch({
    type: REFRESHING_TOKEN,
    payload: data,
  });
};

export const updateIdleExpirationTime = date => (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: SESSION_UPDATED,
    payload: {
      ...selectSessionData(state),
      maxIdleExpirationTime: moment.utc(date).format(),
    },
  });
};

export const endSession = () => async dispatch => {
  dispatch(closeModal('SessionWarningModal'));
  await dispatch(signOut());
  dispatch(
    storeAlertInfo({
      type: 'warning',
      title: { file: 'Alerts', id: 'SessionExpiredTitle' },
      message: { file: 'Alerts', id: 'SignInAgain' },
      closeable: true,
    })
  );
  //dispatch({ type: SESSION_ENDED });
};
