export default {
  DEFAULT: {
    formError: ['ERROR'],
  },
  ADDRESS: {
    streetAddress: 'streetAddress',
    addressLine2: 'addressLine2',
    city: 'city',
    'state.value': 'state',
    zipFive: 'zipFive',
  },
  SEND_OTP: {
    formHelp: ['input', 'channelCode'],
    email: 'path.userEmail',
    phone: 'contactInfo',
  },
  VERIFY_OTP: {
    formHelp: [
      'headers.expires',
      'headers.messageType',
      'headers.cParam',
      'userEmail',
      'contactInfo',
      'channelCode',
      'typeCode',
    ],
    code: 'otp',
  },
  USER_EXISTS: {
    email: 'path.userEmail',
  },
  USER_DEACTIVATED: {
    email: 'path.userEmail',
  },
  CREATE_ACCOUNT_IDENTITY: {
    formHelp: [
      'pii.password',
      'user.userEmail',
      'user.phoneNum',
      'user.phoneNumType',
    ],
    fName: 'user.fName',
    lName: 'user.lName',
    dob: 'pii.dob',
    lastFourSSN: 'pii.lastFourSSN',
    terms: 'acceptTos',
  },
  CREATE_ACCOUNT_IDENTITY_TOAS: {
    dlNumber: 'pii.dlNumber',
    auditNum: 'pii.auditNum',
  },
  UPDATE_CONTACT: {
    phone: 'contactInfo',
    phoneType: 'channelCode',
  },
  FORGOT_PASSWORD_EMAIL: {
    email: 'path.userEmail',
  },
  FORGOT_PASSWORD_RESET_OPTIONS: {
    MFAOption: 'MFAOption',
  },
  FORGOT_PASSWORD_RESET: {
    password: 'newPass',
  },
  UPDATE_PASSWORD: {
    currentPassword: 'password',
    newPassword: 'newPass',
  },
  UPDATE_SECURITY_QUESTIONS: {
    'question1.quid': 'question1.quid',
    answer1: 'question1.answer',
    'question2.quid': 'question2.quid',
    answer2: 'question2.answer',
    'question3.quid': 'question3.quid',
    answer3: 'question3.answer',
  },
  CHECK_SECURITY_ANSWERS: {
    answer1: 'question1.answer',
    answer2: 'question2.answer',
  },
  CYBER_ATTESTATION: {
    trainingCompletionDate: 'invalidTrainingDt',
  },
};
