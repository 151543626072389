// Vendors
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import Input from './Input';
import Label from '../Label/Label';
import Trans from '../../Trans/Trans';

// Types
import { formikInjectedPropsTypes } from '../../../../lib/validation/propTypes/formikPropTypes';

const hintPropTypes = {
  yupCondition: PropTypes.func,
  content: PropTypes.object.isRequired,
  onFocusOnly: PropTypes.bool,
};

const ErrorMessage = ({ errorMap: { id: errorId, args } }) => (
  <div role="alert" className="input-message input-message-error">
    <Trans file="Errors" id={errorId} args={args} />
  </div>
);
ErrorMessage.propTypes = {
  errorMap: PropTypes.shape({
    id: PropTypes.string.isRequired,
    args: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const HintMessage = ({ hint: { content } }) => (
  <div className="input-message input-message-hint">
    <Trans file={content.file} id={content.id} />
  </div>
);
HintMessage.propTypes = {
  hint: hintPropTypes.isRequired,
};

const InputWrapper = ({
  id,
  name,
  className,
  label,
  labelPosition = 'inside',
  icons,
  helpText,
  hideError,
  onBlur,
  onChange,
  formikProps,
  hint,
  upperCase,
  optional,
  ...props
}) => {
  let hasLabel, hasError, hasSuccess, showError, errorMap;
  const value = props.value
    ? props.value
    : formikProps
    ? formikProps.getFieldValue(name) || ''
    : '';

  const defaultHintState =
    hint.content && !hint.yupCondition && !hint.onFocusOnly ? true : false;
  const [showHint, setShowHint] = useState(defaultHintState);

  const handleChange = event => {
    if (upperCase) event.target.value = event.target.value.toUpperCase();

    if (onChange) onChange(event);
    else if (formikProps) formikProps.handleChange(event);
  };

  const handleBlur = event => {
    if (onBlur) {
      onBlur(event);
    } else {
      if (formikProps) {
        formikProps.handleBlur(event);
      }
    }

    //Dont remove hint if trying to submit form
    if (
      event.relatedTarget &&
      event.relatedTarget.classList &&
      event.relatedTarget.classList.contains('button')
    )
      return;
    setShowHint(defaultHintState);
  };

  const onKeyDown = e => {
    // Commenting this out because this code is causing wrong cursor behavior
    // const cursorPos = Number(e.target.selectionStart);
    if (
      (e.keyCode === 8 || e.keyCode === 46) &&
      props.mask &&
      (name || '').includes('Donation')
    ) {
      // e.preventDefault();
      // e.stopPropagation();
      // // Simulate backspace delete
      // if (cursorPos === 0 && cursorPos !== 46) {
      //   e.target.value = e.target.value.substring(0, e.target.value.length - 1);
      //   e.target.setSelectionRange(cursorPos, cursorPos);
      // } else if (cursorPos !== e.target.value.length) {
      //   e.target.value =
      //     e.target.value.substring(0, cursorPos - 1) +
      //     e.target.value.substring(cursorPos, e.target.value.length);
      //   e.target.setSelectionRange(cursorPos - 1, cursorPos - 1);
      // }
      if (onBlur) {
        onBlur(e);
      }
    }
  };

  const handleFocus = () => setShowHint(!defaultHintState);

  useEffect(() => {
    let mounted = true;
    if (hint && hint.yupCondition) {
      hint.yupCondition
        .validate(value)
        .then(() => {
          if (mounted) {
            hint.onFocusOnly ? setShowHint(false) : setShowHint(true);
          }
        })
        .catch(() => {
          hint.onFocusOnly ? setShowHint(false) : setShowHint(true);
        });
    }
    return function() {
      mounted = false;
      return undefined;
    };
  }, [value.length]);

  if (formikProps) {
    const { getFieldTouched, getFieldError, getFieldStatus } = formikProps;
    const touched = getFieldTouched(name);
    const localError = getFieldError(name) || {};
    const serverError = (getFieldStatus('serverErrors') || {})[name];

    errorMap = serverError || localError;
    showError =
      (serverError && typeof serverError.id === 'string') ||
      (touched && typeof localError.id === 'string');
    hasError =
      (touched && (localError === true || localError.id)) || serverError;
    hasSuccess = !hasError && touched;
    hasLabel = label ? true : false;
  }

  const inputWrapperClasses = classnames(
    'input-wrapper',
    `input-label-position-${labelPosition}`,
    className,
    {
      hasLabel,
      hasError,
      hasSuccess,
      hasHint: showHint,
      showHintOnFocus: hint.onFocusOnly,
      hasHelpText: !!helpText,
    }
  );

  return (
    <div className={inputWrapperClasses}>
      {label && labelPosition.includes('above') && (
        <Label label={label} name={name} id={id}>
          {optional && (
            <span className="input-optional">
              <Trans file="Labels" id="InputOptional" />
            </span>
          )}
        </Label>
      )}
      <div className="input-wrapper-field">
        <Input
          {...props}
          id={id || name}
          name={name}
          className="input"
          value={value}
          onFocus={handleFocus.bind(this)}
          onBlur={handleBlur.bind(this)}
          onChange={handleChange.bind(this)}
          onKeyDown={onKeyDown.bind(this)}
          autoComplete={hint.onFocusOnly ? 'off' : 'on'}
        />
        {label && labelPosition === 'inside' && (
          <Label label={label} name={name} id={id} />
        )}
        {icons}
        {!hideError && showError && errorMap && (
          <ErrorMessage errorMap={errorMap} />
        )}
        {(!showError || hideError) && showHint && hint.content ? (
          <HintMessage hint={hint} />
        ) : null}
        {helpText && <span className="input-help">{helpText}</span>}
      </div>
    </div>
  );
};

InputWrapper.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  labelPosition: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  mask: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.instanceOf(RegExp), PropTypes.string])
  ),
  guide: PropTypes.bool,
  icons: PropTypes.node,
  helpText: PropTypes.string,
  hideError: PropTypes.bool,
  value: PropTypes.any.isRequired,
  hint: hintPropTypes,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  formikProps: formikInjectedPropsTypes,
  loadOptions: PropTypes.bool,
  upperCase: PropTypes.bool,
  optional: PropTypes.bool,
};

InputWrapper.defaultProps = {
  status: {
    serverErrors: {},
  },
  value: '',
  errors: {},
  guide: false,
  hint: {
    onFocusOnly: false,
  },
  upperCase: false,
};

export default InputWrapper;
