// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputRadioGroup, Trans } from '../../../..';

// Styles
import './input-group-mfa-options.scss';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName MFA Options
 */
const InputGroupMFAOptions = ({
  inverse,
  options,
  optionValueKey,
  className,
  formikProps,
  ...props
}) => {
  if (!options) return null;

  const labelOptions = {
    SMS: { text: <Trans file="MFA" id="RadioSMS" fallback="Text to" /> },
    SMTP: { text: <Trans file="MFA" id="RadioSMPT" fallback="Email to" /> },
    QA: { text: '' },
  };

  const getLabel = ({ type, value }) => (
    <>
      {labelOptions[type].text}
      {typeof value === 'string' ? ` ${value}` : ''}
    </>
  );

  getLabel.propTypes = {
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  };

  const optionsSorted = options.reduce((acc, curr) => {
    const option = {
      id: `MFAOption${curr[optionValueKey]}`,
      name: 'MFAOption',
      label: getLabel(curr),
      value: curr,
    };

    // TODO: Fix the way we are sorting these options to avoid empty items into the array...
    if (curr.type === 'SMS') acc[0] = option;
    if (curr.type === 'SMTP') acc[1] = option;
    if (curr.type === 'QA') acc[2] = option;

    return acc;
  }, []);

  return (
    <InputRadioGroup
      {...props}
      name="MFAOption"
      className={classnames('input-group-mfa-options', className)}
      options={optionsSorted}
      optionValueKey={optionValueKey}
      inverse={inverse}
      formikProps={formikProps}
    />
  );
};

InputGroupMFAOptions.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionValueKey: PropTypes.string.isRequired,
  formikProps: formikInjectedPropsTypes.isRequired,
  inverse: PropTypes.bool.isRequired,
};

export default InputGroupMFAOptions;
