// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import i18n from '../../../i18n';

// Actions
import { switchLanguage } from '../../../reducers/language/languageActions';

// Texkit Components
import { Icon } from 'texkit/dist/components/Icon/Icon';
import { TextButton } from 'texkit/dist/components/Buttons';

// Styles
import '../../LanguageSwitcher/language-switcher.scss';

class LanguageSwitcher extends Component {
  constructor(props) {
    super(props);
    i18n.changeLanguage(props.selected);
  }

  handleLanguageSelect(language) {
    i18n.changeLanguage(language);
    this.props.switchLanguage(language);
  }

  get languageValue() {
    const { options, selected } = this.props;
    const selectedLanguage = options.find(
      language => language.code === selected
    );
    return selectedLanguage ? selectedLanguage.value : options[0].value;
  }

  render() {
    const { options } = this.props;
    const items = options.map(option => {
      const optionClassNames = classnames({
        'language-switcher-menu-item': true,
        isSelected: this.languageValue === option.value,
      });
      option.onClick = () => this.handleLanguageSelect(option.code);
      option.className = optionClassNames;
      return option;
    });

    return (
      <>
        {items.map((item, i) => (
          <TextButton
            className={'nav-dropdown-menu-item-action ' + item.className}
            onClick={item.onClick}
          >
            {typeof item.label === 'string' ? (
              <span dangerouslySetInnerHTML={{ __html: item.label }} />
            ) : (
              item.label
            )}
            {this.languageValue === item.value && <Icon name="check" />}
          </TextButton>
        ))}
      </>
    );
  }
}

const mapStateToProps = ({
  language: { selected, options, isDropdownOpen },
}) => ({
  selected,
  options,
  isDropdownOpen,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ switchLanguage }, dispatch);

LanguageSwitcher.propTypes = {
  selected: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  switchLanguage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
