// Helpers
import { to } from '../../../lib/helpers';

// Requests
import { userGetAllSecurityQuestionsRequest } from '../../../backend/requests';

// Consts
export const USER_GET_ALL_SECURITY_QUESTIONS_SUCCESS_RECEIVED =
  '[USER] Get All Security Questions Success';

export const getAllSecurityQuestions = () => {
  return async dispatch => {
    const [{ response, body }] = await to(
      dispatch(userGetAllSecurityQuestionsRequest())
    );

    if (!body) return false;
    if (response.status !== 200) return Promise.reject({ response, body });

    dispatch({
      type: USER_GET_ALL_SECURITY_QUESTIONS_SUCCESS_RECEIVED,
      payload: body.data,
    });

    return Promise.resolve({ response, body });
  };
};
