export const bnlr = {
  agencySlug: 'bon',
  appCode: '27',
  slug: 'bnlr',
  linkService: {
    linkType: 'license',
    form: {
      labels: {
        licenseNumber: { id: 'LicenseNumber' },
        ssn: { id: 'SSN' },
      },
    },
  },
};
