// Vendors
import React from 'react';
import { useDispatch } from 'react-redux';

// Components
import { Modal } from '..';
import Trans from '../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './link-dl-id-modal.scss';

const LinkDLIDModal = () => {
  const dispatch = useDispatch();
  return (
    <Modal name="LinkDLIDModal" className="link-dl-id-modal">
      <h2 className="h1 modal-title">
        <Trans file="dlr" id="dlr_LinkDLIDAccount_Title" />
      </h2>
      <p className="modal-description">
        <Trans file="dlr" id="dlr_LinkDLIDAccount_Description" />
      </p>

      <ButtonPrimary
        className="modal-close-button "
        onClick={() => dispatch(closeModal('LinkDLIDModal'))}
      >
        <Trans file="Labels" id="okGotIt" />
      </ButtonPrimary>
    </Modal>
  );
};

export default LinkDLIDModal;
