// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
import { CreditCardIcon } from '../..';

// Teckit Components
import { Icon } from 'texkit/dist/components/Icon/Icon';

const PaymentCardListAddButton = ({ onClick, content }) => (
  <div className="payment-add-wrapper">
    <button type="button" className="payment-add" onClick={onClick}>
      <CreditCardIcon name="blank" />
      <div className="payment-add-text">{content}</div>
      <div className="flex-item" />
      <Icon className="payment-add-icon" name="plus-circle" />
    </button>
  </div>
);

PaymentCardListAddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  content: PropTypes.node.isRequired,
};

export default PaymentCardListAddButton;
