// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { InputDate, Trans } from '../../../../common';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Date of Birth
 */
const InputDOB = ({ className, ...props }) => (
  <InputDate {...props} className={classnames('input-dob', className)} />
);

InputDOB.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
};

InputDOB.defaultProps = {
  label: <Trans file="Labels" id="DOB" fallback="DOB" />,
  name: 'dob',
  autoComplete: 'dob',
};

export default InputDOB;
