import * as yup from 'yup';
import { commonRules } from '../../rules';
import { domesticForeignAddressSchema } from '../common';
import ERRORS from '../../errorCopy';

export const DLRLRReviewProfileFormSchema = yup.object().shape({
  contactInfo: yup.object().shape({
    email: commonRules.email({ required: false }),
    phoneNumber: commonRules.phone({
      required: false,
      excludeEmptyString: true,
    }),
  }),
  address: domesticForeignAddressSchema,
  militaryService: commonRules.yesNoRadioGroup,
  militaryModifiers: yup
    .object()
    .shape({
      id: yup.number(),
      code: yup.string(),
      description: yup.string(),
    })
    .nullable()
    .when('militaryService', {
      is: true,
      then: yup
        .object()
        .shape({
          id: yup.number(),
          code: yup.string().required(ERRORS.FIELDS.REQUIRED),
          description: yup.string(),
        })
        .required(ERRORS.FIELDS.REQUIRED),
    }),
});

export const createDLRLREligibiliyQuestionsFormSchema = license => {
  const schema = {
    felonyMisdemeanor: commonRules.yesNoRadioGroup,
    criminalOffense: commonRules.yesNoRadioGroup,
    attestation: commonRules.requiredCheckbox,
  };

  if (license.licenseData.licenseCode === '975') {
    schema.continuingEducation = commonRules.yesNoRadioGroup;
    schema.humantraffickingcourse = commonRules.requiredCheckbox;
  }
  return yup.object().shape(schema);
};

export const feesSchema = yup.object().shape({
  bestDonationOption: yup.number().test(
    'max',
    ERRORS.FIELDS.ONE_DLL_DONATION,
    /* eslint-disable*/
    val => {
      return (
        val === 1.0 ||
        val === 0 ||
        val === 1 ||
        val === 0.0 ||
        val === undefined
      );
    }
  ),
  glendaDonationOption: yup.number().test(
    'max',
    ERRORS.FIELDS.MAX_DLL_DONATION,
    /* eslint-disable*/
    val => {
      return val <= 99999.99 || val === undefined;
    }
  ),
  fvaDonationOption: yup.number().test(
    'max',
    ERRORS.FIELDS.MAX_DLL_DONATION,
    /* eslint-disable*/
    val => {
      return val <= 99999.99 || val === undefined;
    }
  ),
  evidenceTestingDonationOption: yup.number().test(
    'max',
    ERRORS.FIELDS.MAX_DLL_DONATION,
    /* eslint-disable*/
    val => {
      return val <= 99999.99 || val === undefined;
    }
  ),
  youthFundDonationOption: yup.number().test(
    'max',
    ERRORS.FIELDS.MAX_DLL_DONATION,
    /* eslint-disable*/
    val => {
      return val <= 99999.99 || val === undefined;
    }
  ),
});

export const donorAndVoterOptionsSchema = yup.object().shape({
  voterRegistration: yup.string().required(),
  affirmation: commonRules.requiredCheckbox,
  voterApplication: yup.string().when('voterRegistration', {
    is: 'true',
    then: yup.string().required(),
  }),
  electionJudge: yup.string().when('voterRegistration', {
    is: 'true',
    then: yup.string().required(),
  }),
  acknowledgeVoterRegistration: yup.boolean().when('voterRegistration', {
    is: 'true',
    then: commonRules.requiredCheckbox,
  }),
});

export const emergencyContactsSchema = yup.object().shape(
  {
    PrimaryFirstName: yup
      .string()
      .when(
        [
          'PrimaryLastName',
          'PrimaryPrimaryPhone',
          'PrimarySecondaryPhone',
          'PrimarystreetAddress',
          'Primarycity',
          'Primarystate',
          'PrimaryzipFive',
          'Primarycountry',
          'PrimaryExt1',
          'PrimaryExt2',
        ],
        {
          is: (
            PrimaryLastName,
            PrimaryPrimaryPhone,
            PrimarySecondaryPhone,
            PrimarystreetAddress,
            Primarycity,
            Primarystate,
            PrimaryzipFive,
            Primarycountry,
            PrimaryExt1,
            PrimaryExt2
          ) => {
            return (
              PrimaryLastName ||
              PrimaryPrimaryPhone ||
              PrimarySecondaryPhone ||
              PrimarystreetAddress ||
              Primarycity ||
              PrimaryzipFive ||
              Primarystate ||
              PrimaryExt1 ||
              PrimaryExt2 ||
              (Primarycountry && Primarycountry.code !== 'USA')
            );
          },
          then: yup.string().required(ERRORS.FIELDS.REQUIRED),
          otherwise: yup.string().notRequired(),
        }
      ),
    PrimaryLastName: yup
      .string()
      .when(
        [
          'PrimaryFirstName',
          'PrimaryPrimaryPhone',
          'PrimarySecondaryPhone',
          'PrimarystreetAddress',
          'Primarycity',
          'Primarystate',
          'PrimaryzipFive',
          'Primarycountry',
          'PrimaryExt1',
          'PrimaryExt2',
        ],
        {
          is: (
            PrimaryFirstName,
            PrimaryPrimaryPhone,
            PrimarySecondaryPhone,
            PrimarystreetAddress,
            Primarycity,
            Primarystate,
            PrimaryzipFive,
            Primarycountry,
            PrimaryExt1,
            PrimaryExt2
          ) => {
            return (
              PrimaryFirstName ||
              PrimaryPrimaryPhone ||
              PrimarySecondaryPhone ||
              PrimarystreetAddress ||
              Primarycity ||
              PrimaryzipFive ||
              Primarystate ||
              PrimaryExt1 ||
              PrimaryExt2 ||
              (Primarycountry && Primarycountry.code !== 'USA')
            );
          },
          then: yup.string().required(ERRORS.FIELDS.REQUIRED),
          otherwise: yup.string().notRequired(),
        }
      ),
    PrimaryPrimaryPhone: yup
      .string()
      .when(
        [
          'PrimaryFirstName',
          'PrimaryLastName',
          'PrimarySecondaryPhone',
          'PrimarystreetAddress',
          'Primarycity',
          'Primarystate',
          'PrimaryzipFive',
          'Primarycountry',
          'PrimaryExt1',
          'PrimaryExt2',
        ],
        {
          is: (
            PrimaryFirstName,
            PrimaryLastName,
            PrimarySecondaryPhone,
            PrimarystreetAddress,
            Primarycity,
            Primarystate,
            PrimaryzipFive,
            Primarycountry,
            PrimaryExt1,
            PrimaryExt2
          ) => {
            return (
              PrimaryFirstName ||
              PrimaryLastName ||
              PrimarySecondaryPhone ||
              PrimarystreetAddress ||
              Primarycity ||
              PrimaryzipFive ||
              Primarystate ||
              PrimaryExt1 ||
              PrimaryExt2 ||
              (Primarycountry && Primarycountry.code !== 'USA')
            );
          },
          then: commonRules.phone({
            required: true,
            excludeEmptyString: false,
          }),
          otherwise: yup.string().notRequired(),
        }
      ),
    PrimarySecondaryPhone: yup
      .string()
      .when(
        [
          'PrimaryFirstName',
          'PrimaryLastName',
          'PrimaryPrimaryPhone',
          'PrimarystreetAddress',
          'Primarycity',
          'Primarystate',
          'PrimaryzipFive',
          'Primarycountry',
          'PrimaryExt1',
          'PrimaryExt2',
        ],
        {
          is: (
            PrimaryFirstName,
            PrimaryLastName,
            PrimaryPrimaryPhone,
            PrimarystreetAddress,
            Primarycity,
            Primarystate,
            PrimaryzipFive,
            Primarycountry,
            PrimaryExt1,
            PrimaryExt2
          ) => {
            return (
              PrimaryFirstName ||
              PrimaryLastName ||
              PrimaryPrimaryPhone ||
              PrimarystreetAddress ||
              Primarycity ||
              PrimaryzipFive ||
              Primarystate ||
              PrimaryExt1 ||
              PrimaryExt2 ||
              (Primarycountry && Primarycountry.code !== 'USA')
            );
          },
          then: commonRules.phone({
            required: false,
            excludeEmptyString: false,
          }),
          otherwise: yup.string().notRequired(),
        }
      ),
    PrimaryzipFive: yup
      .string()
      .when(
        [
          'PrimaryFirstName',
          'PrimaryLastName',
          'PrimaryPrimaryPhone',
          'PrimarystreetAddress',
          'Primarycity',
          'Primarystate',
          'PrimarySecondaryPhone',
          'Primarycountry',
          'PrimaryExt1',
          'PrimaryExt2',
        ],
        {
          is: (
            PrimaryFirstName,
            PrimaryLastName,
            PrimaryPrimaryPhone,
            PrimarystreetAddress,
            Primarycity,
            Primarystate,
            PrimarySecondaryPhone,
            Primarycountry,
            PrimaryExt1,
            PrimaryExt2
          ) => {
            return (
              PrimaryFirstName ||
              PrimaryLastName ||
              PrimaryPrimaryPhone ||
              PrimarystreetAddress ||
              Primarycity ||
              PrimarySecondaryPhone ||
              Primarystate ||
              PrimaryExt1 ||
              PrimaryExt2 ||
              (Primarycountry && Primarycountry.code !== 'USA')
            );
          },
          then: commonRules.zipFive({
            required: false,
            excludeEmptyString: false,
          }),
          otherwise: yup.string().notRequired(),
        }
      ),
    Primarycountry: yup
      .object()
      .when(
        [
          'PrimaryFirstName',
          'PrimaryLastName',
          'PrimaryPrimaryPhone',
          'PrimarystreetAddress',
          'Primarycity',
          'PrimaryzipFive',
          'Primarystate',
          'PrimarySecondaryPhone',
          'PrimaryExt1',
          'PrimaryExt2',
        ],
        {
          is: (
            PrimaryFirstName,
            PrimaryLastName,
            PrimaryPrimaryPhone,
            PrimarystreetAddress,
            Primarycity,
            PrimaryzipFive,
            Primarystate,
            PrimarySecondaryPhone,
            PrimaryExt1,
            PrimaryExt2
          ) => {
            return (
              PrimaryFirstName ||
              PrimaryLastName ||
              PrimaryPrimaryPhone ||
              PrimarystreetAddress ||
              Primarycity ||
              PrimaryzipFive ||
              PrimarySecondaryPhone ||
              Primarystate ||
              PrimaryExt1 ||
              PrimaryExt2
            );
          },
          then: commonRules.country,
          otherwise: yup.object().shape({
            code: yup.string().notRequired(),
          }),
        }
      ),
  },
  [
    ['PrimaryFirstName', 'PrimaryPrimaryPhone'],
    ['PrimaryFirstName', 'PrimaryLastName'],
    ['PrimaryPrimaryPhone', 'PrimaryFirstName'],
    ['PrimaryPrimaryPhone', 'PrimaryLastName'],
    ['PrimarySecondaryPhone', 'PrimaryFirstName'],
    ['PrimarySecondaryPhone', 'PrimaryLastName'],
    ['PrimarySecondaryPhone', 'PrimaryPrimaryPhone'],
    ['PrimaryzipFive', 'PrimaryFirstName'],
    ['PrimaryzipFive', 'PrimaryLastName'],
    ['PrimaryzipFive', 'PrimaryPrimaryPhone'],
    ['PrimaryzipFive', 'PrimarySecondaryPhone'],
    ['Primarycountry', 'PrimaryFirstName'],
    ['Primarycountry', 'PrimaryLastName'],
    ['Primarycountry', 'PrimaryPrimaryPhone'],
    ['Primarycountry', 'PrimarySecondaryPhone'],
    ['Primarycountry', 'PrimaryzipFive'],
  ] //list of exhaustive field pairs
);

export const emergencySecContactsSchema = yup.object().shape(
  {
    PrimaryFirstName: yup
      .string()
      .when(
        [
          'PrimaryLastName',
          'PrimaryPrimaryPhone',
          'PrimarySecondaryPhone',
          'PrimarystreetAddress',
          'Primarycity',
          'Primarystate',
          'PrimaryzipFive',
          'Primarycountry',
          'PrimaryExt1',
          'PrimaryExt2',
        ],
        {
          is: (
            PrimaryLastName,
            PrimaryPrimaryPhone,
            PrimarySecondaryPhone,
            PrimarystreetAddress,
            Primarycity,
            Primarystate,
            PrimaryzipFive,
            Primarycountry,
            PrimaryExt1,
            PrimaryExt2
          ) => {
            return (
              PrimaryLastName ||
              PrimaryPrimaryPhone ||
              PrimarySecondaryPhone ||
              PrimarystreetAddress ||
              Primarycity ||
              PrimaryzipFive ||
              Primarystate ||
              PrimaryExt1 ||
              PrimaryExt2 ||
              (Primarycountry && Primarycountry.code !== 'USA')
            );
          },
          then: yup.string().required(ERRORS.FIELDS.REQUIRED),
          otherwise: yup.string().notRequired(),
        }
      ),
    PrimaryLastName: yup
      .string()
      .when(
        [
          'PrimaryFirstName',
          'PrimaryPrimaryPhone',
          'PrimarySecondaryPhone',
          'PrimarystreetAddress',
          'Primarycity',
          'Primarystate',
          'PrimaryzipFive',
          'Primarycountry',
          'PrimaryExt1',
          'PrimaryExt2',
        ],
        {
          is: (
            PrimaryFirstName,
            PrimaryPrimaryPhone,
            PrimarySecondaryPhone,
            PrimarystreetAddress,
            Primarycity,
            Primarystate,
            PrimaryzipFive,
            Primarycountry,
            PrimaryExt1,
            PrimaryExt2
          ) => {
            return (
              PrimaryFirstName ||
              PrimaryPrimaryPhone ||
              PrimarySecondaryPhone ||
              PrimarystreetAddress ||
              Primarycity ||
              PrimaryzipFive ||
              Primarystate ||
              PrimaryExt1 ||
              PrimaryExt2(Primarycountry && Primarycountry.code !== 'USA')
            );
          },
          then: yup.string().required(ERRORS.FIELDS.REQUIRED),
          otherwise: yup.string().notRequired(),
        }
      ),
    PrimaryPrimaryPhone: yup
      .string()
      .when(
        [
          'PrimaryFirstName',
          'PrimaryLastName',
          'PrimarySecondaryPhone',
          'PrimarystreetAddress',
          'Primarycity',
          'Primarystate',
          'PrimaryzipFive',
          'Primarycountry',
          'PrimaryExt1',
          'PrimaryExt2',
        ],
        {
          is: (
            PrimaryFirstName,
            PrimaryLastName,
            PrimarySecondaryPhone,
            PrimarystreetAddress,
            Primarycity,
            Primarystate,
            PrimaryzipFive,
            Primarycountry,
            PrimaryExt1,
            PrimaryExt2
          ) => {
            return (
              PrimaryFirstName ||
              PrimaryLastName ||
              PrimarySecondaryPhone ||
              PrimarystreetAddress ||
              Primarycity ||
              Primarystate ||
              PrimaryzipFive ||
              PrimaryExt1 ||
              PrimaryExt2 ||
              (Primarycountry && Primarycountry.code !== 'USA')
            );
          },
          then: commonRules.phone({
            required: true,
            excludeEmptyString: false,
          }),
          otherwise: yup.string().notRequired(),
        }
      ),
    PrimarySecondaryPhone: yup
      .string()
      .when(
        [
          'PrimaryFirstName',
          'PrimaryLastName',
          'PrimaryPrimaryPhone',
          'PrimarystreetAddress',
          'Primarycity',
          'Primarystate',
          'PrimaryzipFive',
          'Primarycountry',
          'PrimaryExt1',
          'PrimaryExt2',
        ],
        {
          is: (
            PrimaryFirstName,
            PrimaryLastName,
            PrimaryPrimaryPhone,
            PrimarystreetAddress,
            Primarycity,
            Primarystate,
            PrimaryzipFive,
            Primarycountry,
            PrimaryExt1,
            PrimaryExt2
          ) => {
            return (
              PrimaryFirstName ||
              PrimaryLastName ||
              PrimaryPrimaryPhone ||
              PrimarystreetAddress ||
              Primarycity ||
              PrimaryzipFive ||
              Primarystate ||
              PrimaryExt1 ||
              PrimaryExt2 ||
              (Primarycountry && Primarycountry.code !== 'USA')
            );
          },
          then: commonRules.phone({
            required: false,
            excludeEmptyString: false,
          }),
          otherwise: yup.string().notRequired(),
        }
      ),
    PrimaryzipFive: yup
      .string()
      .when(
        [
          'PrimaryFirstName',
          'PrimaryLastName',
          'PrimaryPrimaryPhone',
          'PrimarystreetAddress',
          'Primarycity',
          'Primarystate',
          'PrimarySecondaryPhone',
          'Primarycountry',
          'PrimaryExt1',
          'PrimaryExt2',
        ],
        {
          is: (
            PrimaryFirstName,
            PrimaryLastName,
            PrimaryPrimaryPhone,
            PrimarystreetAddress,
            Primarycity,
            Primarystate,
            PrimarySecondaryPhone,
            Primarycountry,
            PrimaryExt1,
            PrimaryExt2
          ) => {
            return (
              PrimaryFirstName ||
              PrimaryLastName ||
              PrimaryPrimaryPhone ||
              PrimarystreetAddress ||
              Primarycity ||
              PrimarySecondaryPhone ||
              Primarystate ||
              PrimaryExt1 ||
              PrimaryExt2 ||
              (Primarycountry && Primarycountry.code !== 'USA')
            );
          },
          then: commonRules.zipFive({
            required: false,
            excludeEmptyString: false,
          }),
          otherwise: yup.string().notRequired(),
        }
      ),
    Primarycountry: yup
      .object()
      .when(
        [
          'PrimaryFirstName',
          'PrimaryLastName',
          'PrimaryPrimaryPhone',
          'PrimarystreetAddress',
          'Primarycity',
          'PrimaryzipFive',
          'Primarystate',
          'PrimarySecondaryPhone',
          'PrimaryExt1',
          'PrimaryExt2',
        ],
        {
          is: (
            PrimaryFirstName,
            PrimaryLastName,
            PrimaryPrimaryPhone,
            PrimarystreetAddress,
            Primarycity,
            PrimaryzipFive,
            Primarystate,
            PrimarySecondaryPhone,
            PrimaryExt1,
            PrimaryExt2
          ) => {
            return (
              PrimaryFirstName ||
              PrimaryLastName ||
              PrimaryPrimaryPhone ||
              PrimarystreetAddress ||
              Primarycity ||
              PrimaryzipFive ||
              PrimarySecondaryPhone ||
              Primarystate ||
              PrimaryExt1 ||
              PrimaryExt2
            );
          },
          then: commonRules.country,
          otherwise: yup.object().shape({
            code: yup.string().notRequired(),
          }),
        }
      ),
    SecFirstName: yup
      .string()
      .when(
        [
          'SecLastName',
          'SecPrimaryPhone',
          'SecSecondaryPhone',
          'SecstreetAddress',
          'Seccity',
          'Secstate',
          'SeczipFive',
          'Seccountry',
          'SecExt1',
          'SecExt2',
        ],
        {
          is: (
            SecLastName,
            SecPrimaryPhone,
            SecSecondaryPhone,
            SecstreetAddress,
            Seccity,
            Secstate,
            SeczipFive,
            Seccountry,
            SecExt1,
            SecExt2
          ) => {
            return (
              SecLastName ||
              SecPrimaryPhone ||
              SecSecondaryPhone ||
              SecstreetAddress ||
              Seccity ||
              SeczipFive ||
              Secstate ||
              SecExt1 ||
              SecExt2 ||
              (Seccountry && Seccountry.code !== 'USA')
            );
          },
          then: yup.string().required(ERRORS.FIELDS.REQUIRED),
          otherwise: yup.string().notRequired(),
        }
      ),
    SecLastName: yup
      .string()
      .when(
        [
          'SecFirstName',
          'SecPrimaryPhone',
          'SecSecondaryPhone',
          'SecstreetAddress',
          'Seccity',
          'Secstate',
          'SeczipFive',
          'Seccountry',
          'SecExt1',
          'SecExt2',
        ],
        {
          is: (
            SecFirstName,
            SecPrimaryPhone,
            SecSecondaryPhone,
            SecstreetAddress,
            Seccity,
            Secstate,
            SeczipFive,
            Seccountry,
            SecExt1,
            SecExt2
          ) => {
            return (
              SecFirstName ||
              SecPrimaryPhone ||
              SecSecondaryPhone ||
              SecstreetAddress ||
              Seccity ||
              SeczipFive ||
              Secstate ||
              SecExt1 ||
              SecExt2 ||
              (Seccountry && Seccountry.code !== 'USA')
            );
          },
          then: yup.string().required(ERRORS.FIELDS.REQUIRED),
          otherwise: yup.string().notRequired(),
        }
      ),
    SecPrimaryPhone: yup
      .string()
      .when(
        [
          'SecFirstName',
          'SecLastName',
          'SecSecondaryPhone',
          'SecstreetAddress',
          'Seccity',
          'Secstate',
          'SeczipFive',
          'Seccountry',
          'SecExt1',
          'SecExt2',
        ],
        {
          is: (
            SecFirstName,
            SecLastName,
            SecSecondaryPhone,
            SecstreetAddress,
            Seccity,
            Secstate,
            SeczipFive,
            Seccountry,
            SecExt1,
            SecExt2
          ) => {
            return (
              SecFirstName ||
              SecLastName ||
              SecSecondaryPhone ||
              SecstreetAddress ||
              Seccity ||
              Secstate ||
              SeczipFive ||
              SecExt1 ||
              SecExt2 ||
              (Seccountry && Seccountry.code !== 'USA')
            );
          },
          then: commonRules.phone({
            required: true,
            excludeEmptyString: false,
          }),
          otherwise: yup.string().notRequired(),
        }
      ),
    SecSecondaryPhone: yup
      .string()
      .when(
        [
          'SecFirstName',
          'SecLastName',
          'SecPrimaryPhone',
          'SecName',
          'SecstreetAddress',
          'Seccity',
          'Secstate',
          'SeczipFive',
          'Seccountry',
          'SecExt1',
          'SecExt2',
        ],
        {
          is: (
            SecFirstName,
            SecLastName,
            SecPrimaryPhone,
            SecstreetAddress,
            Seccity,
            Secstate,
            SeczipFive,
            Seccountry,
            SecExt1,
            SecExt2
          ) => {
            return (
              SecFirstName ||
              SecLastName ||
              SecPrimaryPhone ||
              SecstreetAddress ||
              Seccity ||
              Secstate ||
              SeczipFive ||
              SecExt1 ||
              SecExt2 ||
              (Seccountry && Seccountry.code !== 'USA')
            );
          },
          then: commonRules.phone({
            required: false,
            excludeEmptyString: false,
          }),
          otherwise: yup.string().notRequired(),
        }
      ),
    SeczipFive: yup
      .string()
      .when(
        [
          'SecPrimaryPhone',
          'SecFirstName',
          'SecLastName',
          'SecstreetAddress',
          'Seccity',
          'Secstate',
          'SecSecondaryPhone',
          'Seccountry',
          'SecExt1',
          'SecExt2',
        ],
        {
          is: (
            SecFirstName,
            SecLastName,
            SecPrimaryPhone,
            SecstreetAddress,
            Seccity,
            Secstate,
            SecSecondaryPhone,
            Seccountry,
            SecExt1,
            SecExt2
          ) => {
            return (
              SecFirstName ||
              SecLastName ||
              SecPrimaryPhone ||
              SecstreetAddress ||
              Seccity ||
              Secstate ||
              SecSecondaryPhone ||
              SecExt1 ||
              SecExt2 ||
              (Seccountry && Seccountry.code !== 'USA')
            );
          },
          then: commonRules.zipFive({
            required: false,
            excludeEmptyString: false,
          }),
          otherwise: yup.string().notRequired(),
        }
      ),
    Seccountry: yup
      .object()
      .when(
        [
          'SecFirstName',
          'SecLastName',
          'SecPrimaryPhone',
          'SecstreetAddress',
          'Seccity',
          'Secstate',
          'SecSecondaryPhone',
          'SecExt1',
          'SecExt2',
          'SeczipFive',
        ],
        {
          is: (
            SecFirstName,
            SecLastName,
            SecPrimaryPhone,
            SecstreetAddress,
            Seccity,
            Secstate,
            SecSecondaryPhone,
            SecExt1,
            SecExt2,
            SeczipFive
          ) => {
            return (
              SecFirstName ||
              SecLastName ||
              SecPrimaryPhone ||
              SecstreetAddress ||
              Seccity ||
              Secstate ||
              SecSecondaryPhone ||
              SecExt1 ||
              SecExt2 ||
              SeczipFive
            );
          },
          then: commonRules.country,
          otherwise: yup.object().shape({
            code: yup.string().notRequired(),
          }),
        }
      ),
  },
  [
    ['PrimaryFirstName', 'PrimaryPrimaryPhone'],
    ['PrimaryLastName', 'PrimaryPrimaryPhone'],
    ['PrimaryFirstName', 'PrimaryLastName'],
    ['PrimarySecondaryPhone', 'PrimaryFirstName'],
    ['PrimarySecondaryPhone', 'PrimaryLastName'],
    ['PrimarySecondaryPhone', 'PrimaryPrimaryPhone'],
    ['PrimaryzipFive', 'PrimaryFirstName'],
    ['PrimaryzipFive', 'PrimaryLastName'],
    ['PrimaryzipFive', 'PrimaryPrimaryPhone'],
    ['PrimaryzipFive', 'PrimarySecondaryPhone'],
    ['Primarycountry', 'PrimaryFirstName'],
    ['Primarycountry', 'PrimaryLastName'],
    ['Primarycountry', 'PrimaryPrimaryPhone'],
    ['Primarycountry', 'PrimarySecondaryPhone'],
    ['Primarycountry', 'PrimaryzipFive'],
    ['SecFirstName', 'SecPrimaryPhone'],
    ['SecLastName', 'SecPrimaryPhone'],
    ['SecFirstName', 'SecPrimaryPhone'],
    ['SecFirstName', 'SecSecondaryPhone'],
    ['SecLastName', 'SecSecondaryPhone'],
    ['SecSecondaryPhone', 'SecPrimaryPhone'],
    ['SecFirstName', 'SeczipFive'],
    ['SecLastName', 'SeczipFive'],
    ['SecFirstName', 'SecLastName'],
    ['SeczipFive', 'SecPrimaryPhone'],
    ['SeczipFive', 'SecSecondaryPhone'],
    ['SecFirstName', 'Seccountry'],
    ['SecLastName', 'Seccountry'],
    ['Seccountry', 'SecPrimaryPhone'],
    ['Seccountry', 'SecSecondaryPhone'],
    ['Seccountry', 'SeczipFive'],
  ] //list of exshaustive field pairs
);

export const addressValidationSchemaForHome = yup.object().shape({
  addressType: yup.string(),
  streetAddress: commonRules.streetAddressNotPostBox,
  addressLine2: commonRules.addressLine2,
  city: commonRules.city,
  state: commonRules.state,
  zipFive: commonRules.zipFive(),
  county: commonRules.county,
  country: commonRules.country,
});

export const addressValidationSchemaForMail = yup.object().shape({
  addressType: yup.string(),
  streetAddress: commonRules.streetAddress,
  city: commonRules.city,
  state: commonRules.state,
  zipFive: commonRules.zipFive(),
  // county: commonRules.county,
  country: commonRules.country,
});
