// Vendors
import React, { useRef } from 'react';

// Components
import { Modal } from '../../..';
import Trans from '../../../../Trans/Trans';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { Icon } from 'texkit/dist/components/Icon/Icon';
// Styles
import '../dlr-FAQ-modal.scss';

const DLR_REPLACEMENT_FAQModal = () => {
  const modal = useRef();
  return (
    <Modal name="DLR_REPLACEMENT_FAQModal">
      <h2 className="h1 modal-title">
        <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_modal_title" />
      </h2>
      <p className="modal-description">
        <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_modal_desc" />
      </p>
      <div ref={modal} className="modal-inner-body">
        <div className="modal-content">
          <p>
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_1" />
          </p>
        </div>
        <div>
          <p className="modal-content-desc">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_1_Desc" />
          </p>
        </div>
        <div className="modal-content">
          <p>
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_2" />
          </p>
        </div>
        <div>
          <p className="modal-content-desc">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_2_Desc" />
          </p>
        </div>
        <div className="modal-content">
          <p>
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_3" />
          </p>
        </div>
        <div>
          <p className="modal-content-desc after">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_3_Desc" />
          </p>
        </div>
        <div className="modal-content">
          <p>
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_4" />
          </p>
        </div>
        <div>
          <p className="modal-content-desc">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_4_Desc" />
          </p>
        </div>
        <div className="modal-content">
          <p>
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_5" />
          </p>
        </div>
        <div>
          <p className="modal-content-desc">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_5_Desc" />
          </p>
        </div>
        <div className="modal-content">
          <p>
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_6" />
          </p>
        </div>
        <div>
          <p className="modal-content-desc">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_6_Desc" />
          </p>
        </div>
        <div className="modal-content">
          <p>
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_7" />
          </p>
        </div>
        <div>
          <p className="modal-content-desc">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_7_Desc" />
          </p>
        </div>
        <div>
          <p className="modal-content">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_8" />
          </p>
        </div>
        <div>
          <p className="modal-content-desc">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_8_Desc" />
          </p>
        </div>
        <div>
          <p className="modal-content">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_9" />
          </p>
        </div>
        <div>
          <p className="modal-content-desc">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_9_Desc" />
          </p>
        </div>
        <div>
          <p className="modal-content">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_10" />
          </p>
        </div>
        <div>
          <p className="modal-content-desc">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_10_Desc" />
          </p>
        </div>
        <div>
          <p className="modal-content">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_11" />
          </p>
        </div>
        <div>
          <p className="modal-content-desc">
            <Trans file="dlr" id="dlr_REPLACEMENT_FAQ_11_Desc" />
          </p>
        </div>
      </div>
      <ButtonPrimary
        className="modal-button initial"
        onClick={() => {
          if (modal && modal.current) modal.current.scrollTop = 0;
        }}
        icon="more"
      >
        <Icon name="chevron-up" />
        <Trans file="Labels" id="BackToTop" />
      </ButtonPrimary>
    </Modal>
  );
};

export default DLR_REPLACEMENT_FAQModal;
