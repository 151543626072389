// Vendors
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import { Trans } from '../../../';
import { TextButton } from 'texkit/dist/components/Buttons';
// Actions
import { openModal } from '../../../../reducers/modal/modalActions';

// Selectors
import { selectAgencyByRouteParam } from '../../../../reducers/agency/agencySelectors';

const DLRFooter = ({
  agency: { agencySlug = '' },
  updateEmergencyFlag,
  renewalFlag,
  renewAddressChangeFlag,
}) => {
  const dispatch = useDispatch();

  const getType = () => {
    if (updateEmergencyFlag) return 'EMERGENCY';
    else if (renewalFlag) return 'RENEWAL';
    else if (renewAddressChangeFlag) return 'REPLACEMENT';
    else if (agencySlug === 'dlr') return 'DLR';
  };

  const modal = {
    DLR: 'DLRFAQModal',
    EMERGENCY: 'DLR_EMERGENCY_FAQModal',
    RENEWAL: 'DLR_Renewal_FAQModal',
    REPLACEMENT: 'DLR_REPLACEMENT_FAQModal',
  };
  const id = {
    DLR: 'FAQ',
    EMERGENCY: 'FAQ_EMERGENCY',
    RENEWAL: 'FAQ_DRIVER_LICENSE_ID',
    REPLACEMENT: 'FAQ_DRIVER_LICENSE_ID',
  };

  return updateEmergencyFlag ||
    agencySlug === 'dlr' ||
    renewalFlag ||
    renewAddressChangeFlag ? (
    <li className="footer-nav-list-item">
      <TextButton as="a" onClick={() => dispatch(openModal(modal[getType()]))}>
        <Trans file="Common" id={id[getType()]} />
      </TextButton>
    </li>
  ) : null;
};

const mapStateToProps = (state, props) => ({
  agency: selectAgencyByRouteParam(state, props),
  updateEmergencyFlag: state.dlr.updateEmergencyFlag,
  renewalFlag: state.dlr.renewalFlag,
  renewAddressChangeFlag: state.dlr.renewAddressChangeFlag,
});

DLRFooter.propTypes = {
  agency: PropTypes.object,
  agencySlug: PropTypes.string,
  updateEmergencyFlag: PropTypes.bool.isRequired,
  renewAddressChangeFlag: PropTypes.bool.isRequired,
  renewalFlag: PropTypes.bool.isRequired,
};

export default memo(withRouter(connect(mapStateToProps, null)(DLRFooter)));
