export const tdlr = {
  APP_ERRORS: {
    tdlrlr: {
      linkService: {
        licenseNumber: 'params.licenseNumber',
        dob: 'params.dob',
        lastName: 'params.lastName',
      },
    },
  },
};
