// Vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

// Styles
import './pagination-controls.scss';

import { Trans } from '../../../components';

/**
 * @visibleName Default
 */
const PaginationControls = ({
  pageCount,
  currentPage,
  handlePageClick,
  previousLabel = 'Previous',
  nextLabel = 'Next',
  includeFirstLastOption = false,
  initialPage = 0,
  topScrollOffset = 0,
}) => {
  const [forcePage, setForcePage] = useState(initialPage);

  const handleClick = page => {
    handlePageClick({ selected: page.selected });
    currentPage = page.selected;
    setForcePage(page.selected);
    window.scrollTo(0, topScrollOffset);
  };

  return (
    <div className="pagination-controls-container">
      <div className="controls">
        {includeFirstLastOption && (
          <a
            href="#list"
            className={`control-option first ${
              currentPage === 0 ? 'disabled' : ''
            }`}
            onClick={() => handleClick({ selected: 0 })}
          >
            <Trans file="Labels" id="First">
              First
            </Trans>
          </a>
        )}
        <ReactPaginate
          previousLabel={
            <Trans file="Labels" id="Previous">
              previousLabel
            </Trans>
          }
          previousClassName={'control-option previous'}
          nextLabel={
            <Trans file="Labels" id="Next">
              nextLabel
            </Trans>
          }
          nextClassName={'control-option next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handleClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
          forcePage={forcePage}
        />
        {includeFirstLastOption && (
          <a
            href="#list"
            className={`control-option last ${
              currentPage + 1 === pageCount ? 'disabled' : ''
            }`}
            onClick={() => handleClick({ selected: pageCount - 1 })}
          >
            <Trans file="Labels" id="Last">
              Last
            </Trans>
          </a>
        )}
      </div>
    </div>
  );
};

PaginationControls.propTypes = {
  pageCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  handlePageClick: PropTypes.func.isRequired,
  previousLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  includeFirstLastOption: PropTypes.bool,
  initialPage: PropTypes.number,
  topScrollOffset: PropTypes.number,
};

export default PaginationControls;
