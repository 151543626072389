// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles
import './loading-dots.scss';

/**
 * @visibleName Dots
 */
const LoadingDots = ({ className, ...props }) => (
  <div {...props} className={classnames('loading-dots-container', className)}>
    <div className="loading-dots">
      <span />
      <span />
      <span />
      <span />
    </div>
  </div>
);

LoadingDots.propTypes = {
  className: PropTypes.string,
};

export default LoadingDots;
