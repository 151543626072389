// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Label = ({ id, name, label, className, children }) => (
  <label className={classNames('input-label', className)} htmlFor={id || name}>
    {label}
    {children}
  </label>
);

Label.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  children: PropTypes.node,
};

Label.defaultProps = {
  name: '',
  label: '',
};

export default Label;
