// @flow

import { stripDashes } from './util';

export function formatPhoneNumber(phoneNumber: string) {
  if (phoneNumber.length < 1 || phoneNumber.includes('*')) return phoneNumber;
  phoneNumber = stripDashes(phoneNumber).replace(/\D[^.]/g, '');
  phoneNumber = `${phoneNumber.slice(0, 3)}-${+phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
  return phoneNumber;
}
