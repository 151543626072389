//@flow

// Vendors
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Components

import DMVLinkServiceConfirm from '../../../agencies/DMV/link-service/LinkServiceConfirm';
import DLRLinkServiceConfirm from '../../../agencies/DLR/link-service/LinkServiceConfirm';
import TDLRLinkServiceConfirm from '../../../agencies/TDLR/link-service/LinkServiceConfirm';

// Helpers
import { selectAgencyLink } from '../../../reducers/agency/agencySelectors';
import useRedirect from '../../../hooks/useRedirect';
import { clearAlertInfo } from '../../../reducers/alert/alertActions';

const LinkServiceConfirm = () => {
  const link = useSelector(selectAgencyLink);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(clearAlertInfo());
  }, []);

  useRedirect(!link || !link.linkData, '/link');
  useRedirect(!!link && !!link.linkData.userKey, '/link/already-linked');

  const {
    licenses,
    expirationDate,
    licenseClass,
    licenseType,
    personalInformation: {
      firstName,
      middleName,
      lastName: dlrLastName,
      suffix,
    } = {},
    vin,
    vehicleMake,
    vehicleModelYear,
    registrationExpiry,
    renewalEligibility,
    duplicateEligibility,
  } = link.linkData || {};
  const { agency } = link;

  switch (agency.agencySlug) {
    case 'tdlr':
      return <TDLRLinkServiceConfirm licenses={licenses} />;
    case 'dmv':
      return (
        <DMVLinkServiceConfirm
          {...{
            vin,
            vehicleMake,
            vehicleModelYear,
            registrationExpiry,
          }}
        />
      );
    case 'dlr':
      return (
        <DLRLinkServiceConfirm
          {...{
            firstName,
            middleName,
            dlrLastName,
            suffix,
            licenseType,
            licenseClass,
            expirationDate,
            renewalEligibility,
            duplicateEligibility,
          }}
        />
      );
    default:
      return <p>There is an error.</p>;
  }
};

export default LinkServiceConfirm;
