export function selectLicense(state) {
  return state.tdlr.selected;
}

export function selectAppProfile({ tdlr: { appProfile } }) {
  return appProfile;
}

// export function selectAppFullProfile({ tdlr: { fullProfile } }) {
//   return fullProfile;
// }
