// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

// Components

import { ButtonPrimary } from 'texkit/dist/components/Buttons';
import { FormActions, FormActionsItem, Trans } from '../../../components';

// Types
import { AppParamsPropTypes } from '../../../components/common/Forms/LinkServiceLookupForm/LinkServiceLookupForm';

const LinkServiceSuccess = ({ link }) => {
  if (!link || !link.agency || !link.app || !link.success)
    return <Redirect to="/link" />;

  const { agency, app, relinkData } = link;

  const relinkLabel = relinkData ? '_Relink' : '';

  return (
    <>
      <h1 className="h2">
        <Trans
          file={agency.agencySlug}
          id={`${app.appSlug}_LinkService_SuccessTitle${relinkLabel}`}
        />
      </h1>

      <p>
        <Trans file="dlr" id={`dlrlr_LinkService_SuccessSubTitle`} />
      </p>
      <FormActions>
        <FormActionsItem>
          <ButtonPrimary as="Link" to={`/agency/${agency.agencySlug}`}>
            <Trans file="Labels" id="dlrlr_ViewMyLicense" />
          </ButtonPrimary>
        </FormActionsItem>
      </FormActions>
    </>
  );
};

LinkServiceSuccess.propTypes = {
  link: PropTypes.shape({
    params: PropTypes.arrayOf(AppParamsPropTypes),
    agency: PropTypes.object,
    app: PropTypes.object,
    linkData: PropTypes.object,
    success: PropTypes.object,
    relinkData: PropTypes.string,
  }).isRequired,
};

export default LinkServiceSuccess;
