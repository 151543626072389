export const tdlrlr = {
  agencySlug: 'tdlr',
  appCode: 'TDLR_LR',
  slug: 'tdlrlr',
  linkService: {
    linkType: 'license',
    form: {
      labels: {
        licenseNumber: { id: 'LicenseNumber' },
        dob: { id: 'DOB' },
        lastName: { id: 'LastName' },
      },
    },
  },
};
