// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Route, withRouter } from 'react-router-dom';

// Components
import { StepperWithRoutesStep } from '.';

// Styles
import '../stepper.scss';

const StepperWithRoutes = ({
  steps,
  defaultStepId,
  transition,
  onStepChange,
  onStepChanged,
  showDefaultURLPath,
  redirectDefaultToStep,
  className,
  match,
  children,
}) => {
  if (!steps.length) return null;

  const parentRoute = match.url;
  defaultStepId = defaultStepId || steps[0].id;

  return (
    <div className={classnames('stepper', className)}>
      <Route
        path={`${parentRoute}/:stepId?`}
        render={({
          match: {
            params: { stepId },
          },
        }) => (
          <>
            {children && children({ activeStepId: stepId || defaultStepId })}
            <StepperWithRoutesStep
              steps={steps}
              stepId={stepId}
              defaultStepId={defaultStepId}
              parentRoute={parentRoute}
              transition={transition}
              onStepChange={onStepChange}
              onStepChanged={onStepChanged}
              showDefaultURLPath={showDefaultURLPath}
              redirectDefaultToStep={redirectDefaultToStep}
            />
          </>
        )}
      />
    </div>
  );
};

StepperWithRoutes.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultStepId: PropTypes.string.isRequired,
  transition: PropTypes.string,
  onStepChange: PropTypes.func,
  onStepChanged: PropTypes.func,
  showDefaultURLPath: PropTypes.bool,
  redirectDefaultToStep: PropTypes.bool,
  className: PropTypes.string,
  match: PropTypes.object.isRequired,
  children: PropTypes.func,
};

export default withRouter(StepperWithRoutes);
