//@flow

import { useEffect } from 'react';

const useAsyncEffect = (
  effect: () => Promise<any>,
  destroy?: Function,
  inputs: Array<any> = []
) => {
  let stillAround = true;
  let result;

  useEffect(() => {
    if (stillAround) effect().then(value => (result = value));

    return () => {
      stillAround = false;
      if (typeof destroy === 'function') destroy(result);
    };
  }, inputs);
};

export default useAsyncEffect;
