// Vendors
import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

// Components
import { Trans } from '..';
import { DateFormatter } from '../common';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { renewTDLRLicense } from '../../reducers/agency/tdlr/tdlrActions';
import { renewDLRLicense } from '../../reducers/agency/dlr/dlrActions';

// Types
import todoTypeMap from './todoTypeMap';

const TodoItem = ({ data: { date, data, type, expired } }) => {
  const { action, actionWord, licensingEntity } = todoTypeMap[type];

  const dispatch = useDispatch();

  // Handle renew click
  function handleRenewClick() {
    switch (licensingEntity) {
      case 'tdlr':
        dispatch(renewTDLRLicense(data));
        break;
      case 'dlr':
        dispatch(renewDLRLicense(data));
        break;
      default:
        dispatch(push(action));
    }
  }

  return (
    <div className="todo-list-item">
      <div className="todo-list-item-content">
        <div className="todo-list-item-content-primary">
          <Trans
            file="Todo"
            id={`TodoListAction_${actionWord}`}
            fallback="Unknown Action"
          />{' '}
          {type === 'CYBER_ATTESTATION' ? (
            <Trans id={`TodoList_Attestation_Primary`} file="Cybersecurity" />
          ) : (
            data.licenseType
          )}{' '}
          {data && data.fiscalYear ? (
            <>
              (
              <Trans
                file="Cybersecurity"
                id="FiscalYear"
                fallback="fiscal year"
              />
              &nbsp;{data.fiscalYear})
            </>
          ) : (
            ''
          )}
        </div>
        <div className="todo-list-item-content-secondary">
          <Trans
            file="Todo"
            id={`TodoList_LicensingEntity_${licensingEntity}`}
            fallback="Unknown Licensing Entity"
          />
        </div>
      </div>

      <div>
        <div className="todo-list-item-due">
          <span className="todo-list-item-due-title">
            <Trans
              file="Todo"
              id={expired ? 'TodoListState_Expired' : 'TodoListState_Todo'}
              fallback="Expiration unavailable"
            />
            &nbsp;
          </span>
          <span className="todo-list-item-due-date">
            {<DateFormatter date={date} />}
          </span>
        </div>
      </div>

      <div className="todo-list-item-action">
        <ButtonPrimary onClick={() => handleRenewClick()}>
          <Trans
            file="Todo"
            id={`TodoListAction_${actionWord}`}
            fallback="Unknown Action"
          />
        </ButtonPrimary>
      </div>
    </div>
  );
};

//data.licenseType is used in this component but does not appear
//to be passed in from elsewhere
TodoItem.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    expired: PropTypes.bool,
    licenseType: PropTypes.string,
    data: PropTypes.shape({ fiscalYear: PropTypes.string.isRequired }),
  }),
};

export default TodoItem;
