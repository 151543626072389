export default [
  { code: 'USA', label: 'UNITED STATES' },
  { code: 'AF', label: 'AFGHANISTAN' },
  { code: 'AX', label: 'ALAND ISLANDS' },
  { code: 'AL', label: 'ALBANIA' },
  { code: 'DZ', label: 'ALGERIA' },
  { code: 'AS', label: 'AMERICAN SAMOA' },
  { code: 'AD', label: 'ANDORRA' },
  { code: 'AO', label: 'ANGOLA' },
  { code: 'AI', label: 'ANGUILLA' },
  { code: 'AQ', label: 'ANTARCTICA' },
  { code: 'AG', label: 'ANTIGUA AND BARBUDA' },
  { code: 'AR', label: 'ARGENTINA' },
  { code: 'AM', label: 'ARMENIA' },
  { code: 'AW', label: 'ARUBA' },
  { code: 'AU', label: 'AUSTRALIA' },
  { code: 'AT', label: 'AUSTRIA' },
  { code: 'AZ', label: 'AZERBAIJAN' },
  { code: 'BS', label: 'BAHAMAS' },
  { code: 'BH', label: 'BAHRAIN' },
  { code: 'BD', label: 'BANGLADESH' },
  { code: 'BB', label: 'BARBADOS' },
  { code: 'BY', label: 'BELARUS' },
  { code: 'BE', label: 'BELGIUM' },
  { code: 'BZ', label: 'BELIZE' },
  { code: 'BJ', label: 'BENIN' },
  { code: 'BM', label: 'BERMUDA' },
  { code: 'BT', label: 'BHUTAN' },
  { code: 'BO', label: 'BOLIVIA' },
  { code: 'BA', label: 'BOSNIA AND HERZEGOVINA' },
  { code: 'BW', label: 'BOTSWANA' },
  { code: 'BV', label: 'BOUVET ISLAND' },
  { code: 'BR', label: 'BRAZIL' },
  { code: 'IO', label: 'BRITISH INDIAN OCEAN TERRITORY' },
  { code: 'BN', label: 'BRUNEI DARUSSALAM' },
  { code: 'BG', label: 'BULGARIA' },
  { code: 'BF', label: 'BURKINA FASO' },
  { code: 'B2', label: 'BURMA' },
  { code: 'BI', label: 'BURUNDI' },
  { code: 'KH', label: 'CAMBODIA' },
  { code: 'CM', label: 'CAMEROON' },
  { code: 'CA', label: 'CANADA' },
  { code: 'CV', label: 'CAPE VERDE' },
  { code: 'KY', label: 'CAYMAN ISLANDS' },
  { code: 'CF', label: 'CENTRAL AFRICAN REPUBLIC' },
  { code: 'TD', label: 'CHAD' },
  { code: 'CL', label: 'CHILE' },
  { code: 'CN', label: 'CHINA' },
  { code: 'CX', label: 'CHRISTMAS ISLAND' },
  { code: 'CC', label: 'COCOS (KEELING) ISLANDS' },
  { code: 'CO', label: 'COLOMBIA' },
  { code: 'KM', label: 'COMOROS' },
  { code: 'CG', label: 'CONGO' },
  { code: 'CD', label: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE' },
  { code: 'CK', label: 'COOK ISLANDS' },
  { code: 'CR', label: 'COSTA RICA' },
  { code: 'CI', label: 'COTE D`IVOIRE' },
  { code: 'HR', label: 'CROATIA' },
  { code: 'CU', label: 'CUBA' },
  { code: 'CY', label: 'CYPRUS' },
  { code: 'CZ', label: 'CZECH REPUBLIC' },
  { code: 'DK', label: 'DENMARK' },
  { code: 'DJ', label: 'DJIBOUTI' },
  { code: 'DM', label: 'DOMINICA' },
  { code: 'DO', label: 'DOMINICAN REPUBLIC' },
  { code: 'TP', label: 'East Timor' },
  { code: 'EC', label: 'ECUADOR' },
  { code: 'EG', label: 'EGYPT' },
  { code: 'SV', label: 'EL SALVADOR' },
  { code: 'GQ', label: 'EQUATORIAL GUINEA' },
  { code: 'ER', label: 'ERITREA' },
  { code: 'EE', label: 'ESTONIA' },
  { code: 'ET', label: 'ETHIOPIA' },
  { code: 'FK', label: 'FALKLAND ISLANDS (MALVINAS)' },
  { code: 'FO', label: 'FAROE ISLANDS' },
  { code: 'FJ', label: 'FIJI' },
  { code: 'FI', label: 'FINLAND' },
  { code: 'FR', label: 'FRANCE' },
  { code: 'GF', label: 'FRENCH GUIANA' },
  { code: 'FX', label: 'FRANCE METRIPOLITAN' },
  { code: 'PF', label: 'FRENCH POLYNESIA' },
  { code: 'TF', label: 'FRENCH SOUTHERN TERRITORIES' },
  { code: 'GA', label: 'GABON' },
  { code: 'GM', label: 'GAMBIA' },
  { code: 'GE', label: 'GEORGIA' },
  { code: 'DE', label: 'GERMANY' },
  { code: 'GH', label: 'GHANA' },
  { code: 'GI', label: 'GIBRALTAR' },
  { code: 'GR', label: 'GREECE' },
  { code: 'GL', label: 'GREENLAND' },
  { code: 'GD', label: 'GRENADA' },
  { code: 'GP', label: 'GUADELOUPE' },
  { code: 'GU', label: 'GUAM' },
  { code: 'GT', label: 'GUATEMALA' },
  { code: 'GN', label: 'GUINEA' },
  { code: 'GW', label: 'GUINEA-BISSAU' },
  { code: 'GY', label: 'GUYANA' },
  { code: 'HT', label: 'HAITI' },
  { code: 'HM', label: 'HEARD ISLAND AND MCDONALD ISLANDS' },
  { code: 'VA', label: 'HOLY SEE (VATICAN CITY STATE)' },
  { code: 'HN', label: 'HONDURAS' },
  { code: 'HK', label: 'HONG KONG' },
  { code: 'HU', label: 'HUNGARY' },
  { code: 'IS', label: 'ICELAND' },
  { code: 'IN', label: 'INDIA' },
  { code: 'ID', label: 'INDONESIA' },
  { code: 'IR', label: 'IRAN, ISLAMIC REPUBLIC OF' },
  { code: 'IQ', label: 'IRAQ' },
  { code: 'IE', label: 'IRELAND' },
  { code: 'IL', label: 'ISRAEL' },
  { code: 'IT', label: 'ITALY' },
  { code: 'JM', label: 'JAMAICA' },
  { code: 'JP', label: 'JAPAN' },
  { code: 'JO', label: 'JORDAN' },
  { code: 'KZ', label: 'KAZAKHSTAN' },
  { code: 'KE', label: 'KENYA' },
  { code: 'KI', label: 'KIRIBATI' },
  { code: 'KP', label: 'KOREA, DEMOCRATIC PEOPLE`S REPUBLIC OF' },
  { code: 'KR', label: 'KOREA, REPUBLIC OF' },
  { code: 'KW', label: 'KUWAIT' },
  { code: 'KG', label: 'KYRGYZSTAN' },
  { code: 'LA', label: 'LAO PEOPLE`S DEMOCRATIC REPUBLIC' },
  { code: 'LV', label: 'LATVIA' },
  { code: 'LB', label: 'LEBANON' },
  { code: 'LS', label: 'LESOTHO' },
  { code: 'LR', label: 'LIBERIA' },
  { code: 'LY', label: 'LIBYAN ARAB JAMAHIRIYA' },
  { code: 'LI', label: 'LIECHTENSTEIN' },
  { code: 'LT', label: 'LITHUANIA' },
  { code: 'LU', label: 'LUXEMBOURG' },
  { code: 'MO', label: 'MACAO' },
  { code: 'MK', label: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF' },
  { code: 'MG', label: 'MADAGASCAR' },
  { code: 'MW', label: 'MALAWI' },
  { code: 'MY', label: 'MALAYSIA' },
  { code: 'MV', label: 'MALDIVES' },
  { code: 'ML', label: 'MALI' },
  { code: 'MT', label: 'MALTA' },
  { code: 'MH', label: 'MARSHALL ISLANDS' },
  { code: 'MQ', label: 'MARTINIQUE' },
  { code: 'MR', label: 'MAURITANIA' },
  { code: 'MU', label: 'MAURITIUS' },
  { code: 'YT', label: 'MAYOTTE' },
  { code: 'MX', label: 'MEXICO' },
  { code: 'FM', label: 'MICRONESIA, FEDERATED STATES OF' },
  { code: 'MD', label: 'MOLDOVA, REPUBLIC OF' },
  { code: 'MC', label: 'MONACO' },
  { code: 'MN', label: 'MONGOLIA' },
  { code: 'MS', label: 'MONTSERRAT' },
  { code: 'MA', label: 'MOROCCO' },
  { code: 'MZ', label: 'MOZAMBIQUE' },
  { code: 'MM', label: 'MYANMAR' },
  { code: 'NA', label: 'NAMIBIA' },
  { code: 'NR', label: 'NAURU' },
  { code: 'NP', label: 'NEPAL' },
  { code: 'NL', label: 'NETHERLANDS' },
  { code: 'AN', label: 'NETHERLANDS ANTILLES' },
  { code: 'NC', label: 'NEW CALEDONIA' },
  { code: 'NZ', label: 'NEW ZEALAND' },
  { code: 'NI', label: 'NICARAGUA' },
  { code: 'NE', label: 'NIGER' },
  { code: 'NG', label: 'NIGERIA' },
  { code: 'NU', label: 'NIUE' },
  { code: 'NF', label: 'NORFOLK ISLAND' },
  { code: 'MP', label: 'NORTHERN MARIANA ISLANDS' },
  { code: 'NO', label: 'NORWAY' },
  { code: 'OM', label: 'OMAN' },
  { code: 'PK', label: 'PAKISTAN' },
  { code: 'PW', label: 'PALAU' },
  { code: 'PS', label: 'PALESTINIAN TERRITORY, OCCUPIED' },
  { code: 'PA', label: 'PANAMA' },
  { code: 'PG', label: 'PAPUA NEW GUINEA' },
  { code: 'PY', label: 'PARAGUAY' },
  { code: 'PE', label: 'PERU' },
  { code: 'PH', label: 'PHILIPPINES' },
  { code: 'PN', label: 'PITCAIRN' },
  { code: 'PL', label: 'POLAND' },
  { code: 'PT', label: 'PORTUGAL' },
  { code: 'PR', label: 'PUERTO RICO' },
  { code: 'QA', label: 'QATAR' },
  { code: 'RE', label: 'REUNION' },
  { code: 'RO', label: 'ROMANIA' },
  { code: 'RU', label: 'RUSSIAN FEDERATION' },
  { code: 'RW', label: 'RWANDA' },
  { code: 'SH', label: 'SAINT HELENA' },
  { code: 'KN', label: 'SAINT KITTS AND NEVIS' },
  { code: 'LC', label: 'SAINT LUCIA' },
  { code: 'PM', label: 'SAINT PIERRE AND MIQUELON' },
  { code: 'VC', label: 'SAINT VINCENT AND THE GRENADINES' },
  { code: 'WS', label: 'SAMOA' },
  { code: 'SM', label: 'SAN MARINO' },
  { code: 'ST', label: 'SAO TOME AND PRINCIPE' },
  { code: 'SA', label: 'SAUDI ARABIA' },
  { code: 'SN', label: 'SENEGAL' },
  { code: 'CS', label: 'SERBIA AND MONTENEGRO' },
  { code: 'SC', label: 'SEYCHELLES' },
  { code: 'SL', label: 'SIERRA LEONE' },
  { code: 'SG', label: 'SINGAPORE' },
  { code: 'SK', label: 'SLOVAKIA' },
  { code: 'SI', label: 'SLOVENIA' },
  { code: 'SB', label: 'SOLOMON ISLANDS' },
  { code: 'SO', label: 'SOMALIA' },
  { code: 'ZA', label: 'SOUTH AFRICA' },
  { code: 'GS', label: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS' },
  { code: 'ES', label: 'SPAIN' },
  { code: 'LK', label: 'SRI LANKA' },
  { code: 'SD', label: 'SUDAN' },
  { code: 'SR', label: 'SURINAME' },
  { code: 'SJ', label: 'SVALBARD AND JAN MAYEN' },
  { code: 'SZ', label: 'SWAZILAND' },
  { code: 'SE', label: 'SWEDEN' },
  { code: 'CH', label: 'SWITZERLAND' },
  { code: 'SY', label: 'SYRIAN ARAB REPUBLIC' },
  { code: 'TW', label: 'TAIWAN, PROV CHINA' },
  { code: 'TJ', label: 'TAJIKISTAN' },
  { code: 'TZ', label: 'TANZANIA, UNITED REPUBLIC OF' },
  { code: 'TH', label: 'THAILAND' },
  { code: 'TL', label: 'TIMOR-LESTE' },
  { code: 'TG', label: 'TOGO' },
  { code: 'TK', label: 'TOKELAU' },
  { code: 'TO', label: 'TONGA' },
  { code: 'TT', label: 'TRINIDAD AND TOBAGO' },
  { code: 'TN', label: 'TUNISIA' },
  { code: 'TR', label: 'TURKEY' },
  { code: 'TM', label: 'TURKMENISTAN' },
  { code: 'TC', label: 'TURKS AND CAICOS ISLANDS' },
  { code: 'TV', label: 'TUVALU' },
  { code: 'UG', label: 'UGANDA' },
  { code: 'UA', label: 'UKRAINE' },
  { code: 'AE', label: 'UNITED ARAB EMIRATES' },
  { code: 'GB', label: 'UNITED KINGDOM' },
  { code: 'UM', label: 'UNITED STATES MINOR OUTLYING ISLANDS' },
  { code: 'UY', label: 'URUGUAY' },
  { code: 'UZ', label: 'UZBEKISTAN' },
  { code: 'VU', label: 'VANUATU' },
  { code: 'VE', label: 'VENEZUELA' },
  { code: 'VN', label: 'VIET NAM' },
  { code: 'VG', label: 'VIRGIN ISLANDS, BRITISH' },
  { code: 'VI', label: 'VIRGIN ISLANDS, U.S.' },
  { code: 'WF', label: 'WALLIS AND FUTUNA' },
  { code: 'EH', label: 'WESTERN SAHARA' },
  { code: 'YE', label: 'YEMEN' },
  { code: 'YU', label: 'YUGOSLAVIA' },
  { code: 'ZM', label: 'ZAMBIA' },
  { code: 'ZR', label: 'ZAIRE' },
  { code: 'ZW', label: 'ZIMBABWE' },
];
