// Vendors
import React from 'react';
import PropTypes from 'prop-types';

// Components
import TodoItem from './TodoItem';

// Actions

// Helpers

// Styles
import './todo-list.scss';

const TodoList = ({ todoItems }) => (
  <div className="todo-list">
    {todoItems.map((todoItem, i) => (
      <TodoItem key={`${todoItem.type}_${i}`} data={todoItem} />
    ))}
  </div>
);

TodoList.propTypes = {
  todoItems: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      expired: PropTypes.bool,
      data: PropTypes.shape({ fiscalYear: PropTypes.string.isRequired }),
    })
  ),
};

export default TodoList;
