// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { resendOTP } from '../../../../../../reducers/OTP/OTPActions';

// Components
import { Trans } from '../../../..';
import { Icon } from 'texkit/dist/components/Icon/Icon';
import { TextButton } from 'texkit/dist/components/Buttons';

// Types
import { formikInjectedPropsTypes } from '../../../../../../lib/validation/propTypes/formikPropTypes';

class InputGroupCodeResendCode extends Component {
  state = {
    resendCodeStatus: 'default',
  };

  setResendCodeStatus(resendCodeStatus = 'default') {
    this.setState({ resendCodeStatus });
  }

  handleResendCodeClick() {
    const {
      resendOTP,
      resendCodeActionCreator,
      onResendCodeSuccess,
      OTP,
      formikProps,
    } = this.props;
    formikProps.resetForm();

    resendOTP({
      OTP,
      resendCodeActionCreator,
      onResendCodeSuccess,
      setResendCodeStatus: this.setResendCodeStatus.bind(this),
      formikProps,
    });
  }

  render() {
    const { resendCodeStatus } = this.state;
    const { formikProps } = this.props;

    if (resendCodeStatus === 'sending') {
      const codeInput = document.getElementById('code');
      if (codeInput) codeInput.focus();
    }

    const component = {
      default: (
        <TextButton
          type="button"
          onClick={this.handleResendCodeClick.bind(this)}
          disabled={formikProps.isSubmitting}
        >
          <Trans file="MFA" id="CodeResend" fallback="Resend code" />
        </TextButton>
      ),
      // TODO: Consider putting this into a button, so it can be a loading.
      sending: <Trans file="MFA" id="CodeResending" fallback="Sending..." />,
      success: (
        <>
          <Trans file="MFA" id="CodeResent" fallback="Sent!" />{' '}
          <Icon name="check" />
        </>
      ),
      error: (
        <>
          <Trans
            file="MFA"
            id="CodeResendError"
            fallback="Code not sent. See error below"
          />{' '}
          <Icon name="exclamation" />
        </>
      ),
    };

    return (
      <div
        className={`input-group-code-resend-code input-group-code-resend-code-${resendCodeStatus}`}
      >
        {component[resendCodeStatus]}
      </div>
    );
  }
}

InputGroupCodeResendCode.propTypes = {
  resendCodeStatus: PropTypes.string.isRequired,
  resendOTP: PropTypes.func.isRequired,
  OTP: PropTypes.object.isRequired,
  resendCodeActionCreator: PropTypes.func,
  onResendCodeSuccess: PropTypes.func,
  formikProps: formikInjectedPropsTypes,
};

const mapStateToProps = ({ OTP }) => ({ OTP });
const mapDispatchToProps = dispatch =>
  bindActionCreators({ resendOTP }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputGroupCodeResendCode);
