// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import InputWrapper from '../InputWrapper';

// Texkit Components
import { Icon } from 'texkit/dist/components/Icon/Icon';

// Styles
import './input-checkbox.scss';

/**
 * @visibleName Checkbox
 */
const InputCheckbox = ({ className, ...props }) => {
  const InputIcons = (
    <span className="input-checkbox-check">
      <Icon name="check" />
    </span>
  );

  return (
    <InputWrapper
      {...props}
      className={classnames('input-checkbox', className)}
      type="checkbox"
      icons={InputIcons}
    />
  );
};

InputCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default InputCheckbox;
