import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { goBack, push } from 'connected-react-router';
import { Trans, FormActions, FormActionsItem } from '../../../components';
import {
  ButtonPrimary,
  ButtonOutline,
  TextButton,
} from 'texkit/dist/components/Buttons';
import SummaryCard from 'texkit/dist/components/Cards/SummaryCard/SummaryCard';

// Helpers
import { capitalize } from '../../../lib/helpers';

const TDLRAlreadyLinkedSummary = ({ licenses = [] }) => {
  const dispatch = useDispatch();
  return (
    <>
      <h1 className="h2">
        <Trans file="tdlr" id="tdlrlr_LinkService_ConfirmTitleLinked" />
      </h1>
      <p>
        <Trans file="tdlr" id="tdlrlr_LinkService_ConfirmDescriptionLinked" />
      </p>
      {/* TLDR - one licesnnse */}
      {licenses.length === 1 && (
        <>
          <SummaryCard>
            <SummaryCard.List
              items={[
                {
                  className: 'word-break',
                  label: <Trans file="Labels" id="Name" fallback="Name" />,
                  value: licenses[0].name,
                },
                {
                  label: (
                    <Trans
                      file="Labels"
                      id="LicenseNumber"
                      fallback="License number"
                    />
                  ),
                  value: licenses[0].licenseNumber,
                },
                {
                  label: (
                    <Trans
                      file="Labels"
                      id="LicenseName"
                      fallback="License Name"
                    />
                  ),
                  value: capitalize(licenses[0].licenseType),
                },
              ]}
            />
          </SummaryCard>
          <TextButton
            className="link-service-lookup-different"
            onClick={() => dispatch(goBack())}
          >
            <Trans file="Labels" id="LookUpADifferentLicense" />
          </TextButton>
        </>
      )}
      {/* TLDR - > 1 license */}
      {licenses.length > 1 &&
        licenses.map((license, index) => (
          <React.Fragment key={license.licenseType}>
            <SummaryCard>
              <SummaryCard.List
                items={[
                  {
                    className: 'word-break',
                    label: <Trans file="Labels" id="Name" fallback="Name" />,
                    value: license.name,
                  },
                  {
                    label: (
                      <Trans
                        file="Labels"
                        id="LicenseNumber"
                        fallback="License number"
                      />
                    ),
                    value: license.licenseNumber,
                  },
                  {
                    label: (
                      <Trans
                        file="Labels"
                        id="LicenseName"
                        fallback="License Name"
                      />
                    ),
                    value: capitalize(license.licenseType),
                  },
                ]}
              />
            </SummaryCard>
            {index === 0 && (
              <p className="link-service-additional-linked">
                <Trans
                  file="LinkService"
                  id="MultipleLicensesConfirmLink"
                  fallback="All supported licenses/agencies, including those below, will be linked at the same time."
                />
              </p>
            )}
          </React.Fragment>
        ))}
      <FormActions>
        <FormActionsItem>
          <ButtonPrimary onClick={() => dispatch(push('/dashboard'))}>
            <Trans file="Labels" id="tdlrlr_ViewMyLicense" />
          </ButtonPrimary>
        </FormActionsItem>
        <FormActionsItem>
          <ButtonOutline
            onClick={() => dispatch(push('/link/prelookup/tdlrlr'))}
          >
            <Trans file="Labels" id="tdlrlr_LinkALicenseOrRegistration" />
          </ButtonOutline>
        </FormActionsItem>
      </FormActions>
    </>
  );
};

TDLRAlreadyLinkedSummary.propTypes = {
  licenses: PropTypes.array.isRequired,
};

export default TDLRAlreadyLinkedSummary;
