// Vendors
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';

// Actions

// Helpers

// Components
import { Trans } from '../../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Helpers

// Styles
import './payment-error.scss';

const PaymentError = () => {
  const dispatch = useDispatch();
  const paymentResponse = useSelector(state => state.payment.paymentResponse);

  return (
    <>
      <h3 className="payment-error-title">
        <Trans
          file="Payment"
          id="ErrorTitle"
          fallback="An error occurred with your payment."
        />
      </h3>
      <h4 className="payment-error-info">
        <Trans
          file="Payment"
          id="ErrorInfo"
          fallback="Your card was not processed. If you continue to experience this issue, please call the Help Desk at {{SITE_DATA.HELP_DESK_PHONE}}. Error Reference: {{1}}"
          args={[paymentResponse.errors.REF[0]]}
        />
      </h4>

      <ButtonPrimary
        onClick={() => {
          dispatch(replace('/dashboard'));
        }}
      >
        <Trans
          file="Labels"
          id="ViewMyDashboard"
          fallback="View My Dashboard"
        />
      </ButtonPrimary>
    </>
  );
};

export default PaymentError;
