export const dlrlr = {
  agencySlug: 'dlr',
  appCode: 'DLR',
  slug: 'dlrlr',
  linkService: {
    linkType: 'license',
    form: {
      labels: {
        dlIDNumber: { id: 'DLNumber' },
        dob: { id: 'DOB_MDY' },
        last4ssn: { id: 'SSN' },
      },
    },
  },
};
