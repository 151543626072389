import { REQUEST_TYPES } from '.';

export const getAllAgenciesRequest = () => ({
  type: `[API] Get All Agencies`,
  url: '/agency/list',
  APIMethod: REQUEST_TYPES.GET,
});

export const getAllAppsRequest = () => ({
  type: `[API] Get All Applications`,
  url: '/agency/app/all',
  APIMethod: REQUEST_TYPES.GET,
});

export const getMyAppsRequest = () => ({
  type: `[API] Get My Apps`,
  url: '/agency/app/my',
  APIMethod: REQUEST_TYPES.GET,
});

export const lookupAppLinkRequest = body => ({
  type: `[API] Lookup App Link`,
  url: '/agency/app/lookup',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const preLookupAppLinkRequest = body => ({
  type: `[API] Pre Lookup App Link`,
  url: '/agency/app/preLookup',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const verifyAppLinkRequest = body => ({
  type: `[API] Verify App Link`,
  url: '/agency/app/verify',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const donationOptionsRequest = body => ({
  type: `[API] Donation Options`,
  url: '/agency/dlr/donation/option',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const unlinkAppLinkRequest = body => ({
  type: `[API] Unlink App Link`,
  url: '/agency/app/unlink',
  APIMethod: REQUEST_TYPES.POST,
  body,
});

export const getAppParamsRequest = appId => ({
  type: `[API] Get App Params`,
  url: `/agency/app/param/${appId}`,
  APIMethod: REQUEST_TYPES.GET,
});

export const linkAppLinkRequest = body => ({
  type: `[API] Link App Link`,
  url: '/agency/app/link',
  APIMethod: REQUEST_TYPES.POST,
  body,
});
