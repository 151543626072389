// Vendors
import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

// Components
import { Loading } from '../../components';

const PrivateRoute = ({ component: RouteComponent, auth, ...props }) =>
  auth.isAuthenticated ? (
    <Route
      render={routeProps => <RouteComponent {...routeProps} {...props} />}
    />
  ) : (
    <Loading />
  );

const mapStateToProps = ({ auth }) => ({ auth });

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  auth: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(PrivateRoute);
