//@flow

// Vendors
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Components
import { Modal, Trans } from '../../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Styles
import './dont-have-id-modal.scss';

type Props = {
  closeModal: Function,
};

const DontHaveIdModal = ({ closeModal }: Props) => {
  const modalName = 'DontHaveIdModal';

  return (
    <Modal
      name={modalName}
      disableOnClickOutside="true"
      className="dont-have-id-modal"
    >
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="DontHaveId" fallback="" />
      </h2>
      <div className="modal-content">
        <p className="lead">
          <Trans file="Modals" id="CallHelpDesk" fallback="" />
        </p>
      </div>
      <div className="modal-actions">
        <ButtonPrimary
          onClick={() => {
            closeModal(modalName);
          }}
        >
          <Trans file="Modals" id="Ok" fallback="" />
        </ButtonPrimary>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch: Object) =>
  bindActionCreators({ closeModal }, dispatch);

export default connect(null, mapDispatchToProps)(DontHaveIdModal);
