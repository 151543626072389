// Helpers
import { to } from '../../../lib/helpers';
import {
  checkRequestFailure,
  handleRequestFailure,
  ERRORS_MAP,
} from '../../../lib/validation';
import { bodyHasError } from '../../../lib/validation/serverErrors';

// Requests
import {
  getAppParamsRequest,
  verifyAppLinkRequest,
  linkAppLinkRequest,
  preLookupAppLinkRequest,
} from '../../../backend/requests';

// Actions
import { push } from 'connected-react-router';
import { storeAlertInfo } from '../../alert/alertActions';
import { APP_TRANSACTION_IDS } from '../../app/appActions';
import { storeLink } from '../agencyActions';

// Steps
import { LINK_SERVICES_STEPS } from '../../../pages/LinkServicePage/LinkServiceSteps';

// Consts
export const AGENCY_LINK_STORE_DATA = '[AGENCY] Link - Store Data';
export const AGENCY_LINK_CLEAR_DATA = '[AGENCY] Link - Clear Data';
export const AGENCY_RELINK_STORE_DATA = '[AGENCY] ReLink - Store Data';

export const selectAgencyToLink = agency => async (dispatch, getState) => {
  // TODO: What happens when an agency has multiple applications to link to?
  if (agency.appIds.length > 1) return false;

  const {
    agency: { apps },
  } = getState();

  const app = apps[agency.appIds[0]];
  dispatch(storeLinkServiceData({ agency, app }));
  return dispatch(
    push(`/link/${LINK_SERVICES_STEPS.PRELOOKUP}/${app.appSlug}`)
  );
};

export const getPreLookupForLink = appId => async dispatch => {
  const [data, err] = await to(
    dispatch(preLookupAppLinkRequest({ appId, transactionId: 'PROGRAMS' }))
  );
  if (!data.body || err) return Promise.reject(err);
  if (!data.body.status || data.body.status !== 'NOT_APPLICABLE')
    dispatch(storeLinkServiceData({ preLookup: data.body.data }));
  return Promise.resolve([data, err]);
};

export const getAppParamsForLink = ({ app, agency }) => async dispatch => {
  const [{ body }] = await to(dispatch(getAppParamsRequest(app.appId)));
  if (!body) return;
  if (body.data)
    return dispatch(storeLinkServiceData({ app, agency, params: body.data }));
};

export const verifyAppLink = (values, formikActions) => async (
  dispatch,
  getState
) => {
  const {
    link: { app, agency, params, selected },
  } = getState().agency;

  const paramsWithValues = params.map(params => ({
    ...params,
    value: values[params.name].trim(),
  }));
  if (selected)
    paramsWithValues.push({
      name: 'licenseCode',
      value: selected.license.licenseCode,
    });

  const requestBody = {
    appId: app.appId,
    userKey: '1', // has to be a string/number
    transactionId: APP_TRANSACTION_IDS.BASIC_INFO,
    params: paramsWithValues,
  };

  const [{ response, body }, err] = await to(
    dispatch(verifyAppLinkRequest(requestBody))
  );

  if (err) return Promise.reject(err);

  if (checkRequestFailure({ response, body })) {
    if (bodyHasError(body, { errorCodes: ['MGMW_EX_3011'] })) {
      dispatch(push('/link/inegible'));
      return Promise.reject();
    }

    if (bodyHasError(body, { errorCodes: ['MGMW_EX_3503'] })) {
      dispatch(push('/link/can-not-renew-online'));
      return Promise.reject();
    }
    handleRequestFailure(
      body,
      formikActions,
      ERRORS_MAP.AGENCY_ERRORS[agency.agencySlug].APP_ERRORS[app.appSlug]
        .linkService
    );

    return Promise.reject(response);
  }

  dispatch(storeLinkServiceData({ paramsWithValues, linkData: body.data }));

  if (body.message && body.message === 'MGMW_EX_3009') {
    dispatch(push('/link/already-linked'));
    return Promise.reject();
  }

  return Promise.resolve(body.data);
};

export const getAppParamsForLinkOnBoarding = ({
  app = {
    agencyId: '10',
    appCode: 'DLR',
    appId: '9',
    appName: 'Driver License and ID Services',
    appSlug: 'dlrlr',
    linkUserKeys: [0],
    links: [0],
  },
  agency = {
    agencyCode: '405',
    agencyId: '10',
    agencyName: 'Texas Department of Public Safety',
    agencySlug: 'dlr',
    appIds: '9',
    apps: [0],
    linkUserKeys: [0],
    links: [0],
  },
}) => async dispatch => {
  const [{ body }] = await to(dispatch(getAppParamsRequest('9')));
  if (!body) return;

  if (body.data) {
    dispatch(storeLinkServiceData({ app, agency, params: body.data }));
    return body.data;
  }
};

export const verifyAppLinkOnBoarding = (
  values,
  formikActions
) => async dispatch => {
  const app = {
    agencyId: '10',
    appCode: 'DLR',
    appId: '9',
    appName: 'Driver License and ID Services',
    appSlug: 'dlrlr',
    linkUserKeys: [0],
    links: [0],
  };
  const agency = {
    agencyCode: '405',
    agencyId: '10',
    agencyName: 'Texas Department of Public Safety',
    agencySlug: 'dlr',
    appIds: '9',
    apps: [0],
    linkUserKeys: [0],
    links: [0],
  };

  const paramsOnBoarding = await to(
    dispatch(getAppParamsForLinkOnBoarding({ app, agency }))
  );

  const paramsWithValues = paramsOnBoarding[0].map(params => ({
    ...params,
    value: values[params.name].trim(),
  }));

  const requestBody = {
    appId: '9',
    userKey: '1', // has to be a string/number
    transactionId: APP_TRANSACTION_IDS.BASIC_INFO,
    params: paramsWithValues,
  };

  const [{ response, body }, err] = await to(
    dispatch(verifyAppLinkRequest(requestBody))
  );

  if (err) return Promise.reject(err);

  if (checkRequestFailure({ response, body })) {
    if (bodyHasError(body, { errorCodes: ['MGMW_EX_3011'] })) {
      dispatch(push('/link/inegible'));
      return Promise.reject();
    }

    handleRequestFailure(
      body,
      formikActions,
      ERRORS_MAP.AGENCY_ERRORS['dlr'].APP_ERRORS['dlr'].linkService
    );

    return Promise.reject(response);
  }

  dispatch(storeLinkServiceData({ paramsWithValues, linkData: body.data }));

  if (body.message && body.message === 'MGMW_EX_3009') {
    dispatch(push('/link/already-linked'));
    return Promise.reject();
  }

  return Promise.resolve(body.data);
};

export const linkAppLink = () => async (dispatch, getState) => {
  const {
    link: {
      app: { ...app },
      paramsWithValues,
    },
  } = getState().agency;

  const requestBody = {
    appId: app.appId,
    params: paramsWithValues,
  };

  const [{ response, body }, err] = await to(
    dispatch(linkAppLinkRequest(requestBody))
  );

  if (err || response.status !== 200) {
    if (
      bodyHasError(body, {
        exactError: 'MGMW_EX_3009|||Account already exists',
      })
    ) {
      dispatch(push('/link/already-linked'));
      return Promise.reject();
    }

    if (
      bodyHasError(body, {
        exactError: 'MGMW_EX_3301|||Entity Id is linked to different account',
      })
    )
      dispatch(
        storeAlertInfo({
          type: 'danger',
          title: { file: 'Alerts', id: 'SorryTitle' },
          message: { file: 'Alerts', id: 'LinkedAnotherAccount' },
          closeable: true,
        })
      );

    return Promise.reject(err || response);
  }

  const link = { ...app, ...body.data };

  dispatch(storeLink(link));

  return Promise.resolve(link);
};

export const linkServiceSuccess = () => dispatch => {
  dispatch(storeLinkServiceData({ success: true }));
  return dispatch(push(`/link/${LINK_SERVICES_STEPS.SUCCESS}`));
};

export const storeLinkServiceData = data => dispatch =>
  dispatch({ type: AGENCY_LINK_STORE_DATA, payload: data });

export const storeReLinkServiceData = data => dispatch =>
  dispatch({ type: AGENCY_RELINK_STORE_DATA, payload: data });

export const clearLinkServiceData = () => dispatch =>
  dispatch({ type: AGENCY_LINK_CLEAR_DATA });
