// Vendors
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import { TextButton } from 'texkit/dist/components/Buttons';

// Styles
import './toggle-group.scss';

// Texkit Components
import { Icon } from 'texkit/dist/components/Icon/Icon';

const ToggleGroupSwitch = ({ isEmpty, ...props }) => {
  return (
    <TextButton className="toggle-group-switch" {...props}>
      {isEmpty ? (
        <Icon className="toggle-group-switch-add-icon" name="plus-circle" />
      ) : (
        <Icon className="toggle-group-switch-edit-icon" name="edit" />
      )}
    </TextButton>
  );
};

ToggleGroupSwitch.propTypes = {
  switchButtonText: PropTypes.string.isRequired,
};

/**
 * @visibleName Default
 */
const ToggleGroup = ({ className, closed, open, isEmpty, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const groupIsOpen = props.isOpen || isOpen;
  const setToggle = () => setIsOpen(!isOpen);

  return (
    <div className={classnames('toggle-group', className)}>
      {!groupIsOpen && (
        <ToggleGroupSwitch onClick={setToggle} isEmpty={isEmpty} />
      )}
      {groupIsOpen && open ? open({ setToggle }) : closed({ setToggle })}
    </div>
  );
};

ToggleGroup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  open: (() => PropTypes.node).isRequired,
  closed: (() => PropTypes.node).isRequired,
  isEmpty: PropTypes.bool,
  className: PropTypes.string,
};

export default ToggleGroup;
