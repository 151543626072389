// Vendors
import * as yup from 'yup';

export const dmv = {
  APP_RULES: {
    dmvrr: {
      linkService: {
        lastFourOfVIN: yup
          .string()
          .trim()
          .required({ id: 'Field_VinRequired' }),
        plateNumber: yup
          .string()
          .trim()
          .required({ id: 'Field_LicensePlateRequired' }),
      },
    },
  },
};
