// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import InputSelect from '../InputSelect/InputSelect';
import Trans from '../../../Trans/Trans';

// Data
import { counties } from '../../../../../lib/data';

// Types
import { formikInjectedPropsTypes } from '../../../../../lib/validation/propTypes/formikPropTypes';

/**
 * @visibleName Select County
 */
const InputCounty = ({ className, ...props }) => (
  <InputSelect
    {...props}
    optionLabelKey="label"
    optionValueKey="value"
    emptyOptionLabel={<Trans file="Labels" id="SelectACounty" />} //"Select a county..."
    isSearchable={true}
    className={classnames('input-county', className)}
  />
);

InputCounty.propTypes = {
  className: PropTypes.string,
  formikProps: formikInjectedPropsTypes.isRequired,
  name: PropTypes.string,
};

InputCounty.defaultProps = {
  label: 'County',
  name: 'county',
  options: counties,
};

export default InputCounty;
