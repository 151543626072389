import getDeviceDetails from './getDeviceDetails';

// Test for IDToken inputs
// ForgeRock may change the integer used to reference the IDToken
const idTokenMatch = /^IDToken[0-9]$/;

export const getChannelTypeFromValue = value => {
  if (!value) return;
  if (value.includes('@')) return 'SMTP';
  if (value.includes(' ')) return 'SMS';
  return 'UNKNOWN';
};

export const requiresDeviceDetails = ({ callbacks }) =>
  callbacks &&
  callbacks.find(callback => callback.type === 'HiddenValueCallback');

export const requiresMFAOption = ({ callbacks }) =>
  callbacks && callbacks.find(callback => callback.type === 'ChoiceCallback');

export const requiresMFACode = ({ callbacks }) =>
  callbacks && callbacks.find(callback => callback.type === 'PasswordCallback');

export const requiresRememberThisDevice = ({ callbacks }) =>
  callbacks &&
  callbacks[2] &&
  callbacks[2].output &&
  callbacks[2].output[2].value === 'Do you want to save?';

export const updateWithDeviceDetails = callbacks => {
  callbacks
    .find(callback => callback.type === 'HiddenValueCallback')
    .input.find(input =>
      idTokenMatch.test(input.name)
    ).value = getDeviceDetails();
  return callbacks;
};

export const updateWithRememberThisDevice = ({
  callbacks,
  rememberThisDevice,
}) => {
  callbacks
    .find(callback => callback.type === 'ChoiceCallback')
    .input.find(input =>
      idTokenMatch.test(input.name)
    ).value = rememberThisDevice ? 1 : 0;
  return callbacks;
};

export const getMFAOptionsFromCallbacks = callbacks => {
  const choiceCallback = callbacks.find(
    callback => callback.type === 'ChoiceCallback'
  );
  return choiceCallback
    ? choiceCallback.output
        .find(output => output.name === 'choices')
        .value.map(option => ({
          type: getChannelTypeFromValue(option),
          value: option,
        }))
    : null;
};

export const callbacksWithSelectedMFAIndex = ({
  selectedMFAOption,
  callbacks,
}) => {
  const choiceCallbackIndex = callbacks.findIndex(
    callback => callback.type === 'ChoiceCallback'
  );
  const choices = callbacks[choiceCallbackIndex].output.find(
    output => output.name === 'choices'
  ).value;
  const MFAInputIndex = callbacks[choiceCallbackIndex].input.findIndex(input =>
    idTokenMatch.test(input.name)
  );
  const selectedMFAIndex = choices.indexOf(selectedMFAOption);
  callbacks[choiceCallbackIndex].input[MFAInputIndex].value = selectedMFAIndex;
  return callbacks;
};

export const callbacksWithMFACode = ({
  MFACode,
  rememberThisDevice,
  callbacks,
  resendCode = 'false',
}) => {
  //NameCallBack
  const resendCallbackIndex = callbacks.findIndex(
    callback => callback.type === 'NameCallback'
  );
  const resendInputIndex = callbacks[
    resendCallbackIndex
  ].input.findIndex(input => idTokenMatch.test(input.name));

  callbacks[resendCallbackIndex].input[resendInputIndex].value = resendCode;

  //PasswordCallBack
  const passwordCallbackIndex = callbacks.findIndex(
    callback => callback.type === 'PasswordCallback'
  );

  const MFAInputIndex = callbacks[
    passwordCallbackIndex
  ].input.findIndex(input => idTokenMatch.test(input.name));

  if (resendCode === 'true') {
    callbacks[passwordCallbackIndex].input[MFAInputIndex].value = 'NOT_SET';
  } else {
    callbacks[passwordCallbackIndex].input[MFAInputIndex].value = MFACode;
  }

  //ChoiceCallBack
  const choiceCallBackIndex = callbacks.findIndex(
    callback => callback.type === 'ChoiceCallback'
  );

  const ChoiceInputIndex = callbacks[
    choiceCallBackIndex
  ].input.findIndex(input => idTokenMatch.test(input.name));

  callbacks[choiceCallBackIndex].input[
    ChoiceInputIndex
  ].value = rememberThisDevice ? 1 : 0;

  return callbacks;
};

//DEPRECATED
export const callbacksWithMFACodeResend = ({ callbacks }) => {
  const resendCallbackIndex = callbacks.findIndex(
    callback => callback.type === 'NameCallback'
  );
  const resendInputIndex = callbacks[
    resendCallbackIndex
  ].input.findIndex(input => idTokenMatch.test(input.name));
  callbacks[resendCallbackIndex].input[resendInputIndex].value = 'true';
  const passwordCallbackIndex = callbacks.findIndex(
    callback => callback.type === 'PasswordCallback'
  );
  const MFAInputIndex = callbacks[
    passwordCallbackIndex
  ].input.findIndex(input => idTokenMatch.test(input.name));
  callbacks[passwordCallbackIndex].input[MFAInputIndex].value = 'NOT_SET';
  return callbacks;
};
